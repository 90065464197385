import CloseIcon from '@mui/icons-material/Close';
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';

import { useDeleteNotification } from '../../api/notifications';
import { GlobalAppContext } from '../../store/AppStore';
import { colours } from '../../theme/colors';
import { FERALink } from '../Link/FERALink';
import { ClearAllNotificationsWarningModal } from '../Modals/ClearAllNotificationsWarningModal';
import useStyles from './styles';

const NotificationTrayItem = ({ notification, isLast, onClear, hideNotificationTray }) => {
  let date = new Date(notification.createdAt + '+00:00');
  const formattedDate = date
    .toLocaleString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'Australia/Melbourne'
    })
    .replace(',', '');
  const classes = useStyles();
  const history = useHistory();
  const truncateString = (str, limit = 100) =>
    str.length > limit ? str.substring(0, limit) + '...' : str;

  return (
    <div style={{ marginBottom: isLast ? 0 : 18, position: 'relative', width: 300 }}>
      {notification.title === 'Comment' ? (
        <CommentRoundedIcon className={classes.notificationInfoIcon} />
      ) : (
        <InfoOutlinedIcon className={classes.notificationInfoIcon} />
      )}
      <CloseIcon onClick={onClear} className={classes.notificationCloseIcon} />
      <div style={{ marginLeft: 48, marginTop: 8, marginRight: 28 }}>
        <Typography
          className={classes.notificationText}
          style={{
            color: colours.black,
            fontSize: 14
          }}>
          {notification.title === 'Comment' &&
            notification.format.reasonCode &&
            truncateString(notification.format.reasonCode)}
          {notification.title === 'Comment' && notification.format.reasonCode && <br />}
          {notification.title === 'Comment'
            ? `"${truncateString(notification.format.message)}"`
            : truncateString(notification.format.message)}
          <br />
          {notification.format.callToAction && (
            <FERALink
              className={classes.toastNotificationAction}
              onClick={() => {
                if (typeof notification.format.callToAction.bulkAction !== 'undefined') {
                  history.push(notification.format.callToAction.bulkAction);
                } else {
                  notification.format.callToAction.action();
                }
                hideNotificationTray();
              }}>
              {notification.format.callToAction.message}
            </FERALink>
          )}
        </Typography>
        <Typography sx={{ fontSize: 13, paddingTop: 1, color: colours.primaryGrey }}>
          {formattedDate}
        </Typography>
      </div>
    </div>
  );
};

NotificationTrayItem.propTypes = {
  notification: PropTypes.object.isRequired,
  isLast: PropTypes.bool,
  onClear: PropTypes.func.isRequired,
  hideNotificationTray: PropTypes.func.isRequired
};

export const NotificationTray = ({ hideNotificationTray }) => {
  const classes = useStyles();

  const {
    notifications: [notifications, setNotifications],
    serverNotifyState: [serverNotifyState]
  } = useContext(GlobalAppContext);

  const { mutate: deleteNotification } = useDeleteNotification(false);
  const { mutate: deleteAllNotifications } = useDeleteNotification(true);

  const forCodes = [...new Set(notifications.map((n) => n.forCode))];
  const mappedNotifications = {};
  notifications.forEach((n) => {
    if (typeof mappedNotifications[n.forCode] === 'undefined') {
      mappedNotifications[n.forCode] = [];
    }
    mappedNotifications[n.forCode].push(n);
  });

  const [expanded, setExpanded] = React.useState(forCodes[0]);
  const [clearAllWarningVisible, setClearAllWarningVisible] = useState(false);
  const { closeSnackbar } = useSnackbar();

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const onClearById = (id) => {
    deleteNotification(id, {
      onSuccess: () => {
        const delIndex = notifications.map((n) => n.id).indexOf(id);
        if (delIndex !== -1) {
          const list = [...notifications];
          const deleted = list.splice(delIndex, 1);
          closeSnackbar(deleted[0].id);
          setNotifications(list);
        }
      }
    });
  };

  const onClearAll = () => {
    deleteAllNotifications(serverNotifyState.lastId, {
      onSuccess: () => {
        notifications.forEach((n) => {
          closeSnackbar(n.id);
        });
        setNotifications([]);
        setClearAllWarningVisible(false);
      }
    });
  };

  return (
    <div className={classes.notificationTray}>
      {forCodes.map((code, i) => {
        return (
          <Accordion
            className={classes.accordion}
            square
            disableGutters
            key={i}
            expanded={expanded === code}
            onChange={handleChangeAccordion(code)}>
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={<ExpandMoreIcon />}
              className={classes.notificationSummaryTitle}>
              <Typography>{`${code} - ${mappedNotifications[code][0].forName}`}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ maxHeight: 300, overflowY: 'auto', overflowX: 'hidden' }}>
              {expanded === code &&
                mappedNotifications[code].map((notification, ii) => {
                  return (
                    <NotificationTrayItem
                      key={ii}
                      isLast={ii === mappedNotifications[code].length - 1}
                      onClear={() => {
                        onClearById(notification.id);
                      }}
                      notification={notification}
                      hideNotificationTray={hideNotificationTray}
                    />
                  );
                })}
            </AccordionDetails>
          </Accordion>
        );
      })}
      <Box mt={1.5} mb={1.5} className={classes.notificationsClearAll}>
        {Object.keys(mappedNotifications).length > 0 && (
          <FERALink
            sx={{ color: colours.black }}
            onClick={() => {
              setClearAllWarningVisible(true);
            }}>
            Clear all
          </FERALink>
        )}
      </Box>
      {clearAllWarningVisible && (
        <ClearAllNotificationsWarningModal
          onSubmit={onClearAll}
          onClose={() => {
            setClearAllWarningVisible(false);
          }}
        />
      )}
    </div>
  );
};
NotificationTray.propTypes = {
  hideNotificationTray: PropTypes.func.isRequired
};
