import makeStyles from '@mui/styles/makeStyles';

import { colours } from '../../theme/colors';
import { navigationConstant } from '../../theme/navigationStyles';
import { zIndexes } from '../../theme/zIndexes';

const useStyles = makeStyles(() => ({
  badgeIcon: {
    '& .MuiBadge-badge': {
      backgroundColor: colours.primaryRed,
      color: colours.white
    },
    marginRight: 9
  },
  notificationText: {
    color: colours.black
  },
  notificationsClearAll: {
    margin: 12,
    marginLeft: 16
  },
  notificationsContainer: {
    position: 'relative'
  },
  notificationInfoIcon: { fontSize: 24, position: 'absolute', color: colours.primaryRed },
  CommentRoundedIcon: { fontSize: 24, position: 'absolute', color: colours.primaryRed },
  notificationCloseIcon: {
    fontSize: 20,
    position: 'absolute',
    right: 4,
    cursor: 'pointer',
    color: colours.primaryGrey
  },
  notificationTray: {
    backgroundColor: colours.white,
    position: 'absolute',
    top: navigationConstant.topBarHeight,
    width: 340,
    right: 10,
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
  },
  accordion: {
    boxShadow: 'unset'
  },
  notificationSummaryTitle: {
    backgroundColor: colours.secondaryGrey,
    color: colours.black
  },
  avatar: {
    backgroundColor: colours.secondaryGrey,
    color: colours.white
  },
  title: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  customFill: {
    color: colours.primaryGrey
  },
  bellIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: colours.textPrimary
    }
  },
  divider: {
    width: 32,
    borderBottom: `1px solid ${colours.purpleGrey}`,
    transform: 'rotate(90deg)',
    marginRight: 8,
    marginLeft: 12
  },
  logo: {
    height: 25,
    paddingLeft: 25,
    paddingRight: 137.343
  },
  topBar: {
    width: '100%',
    zIndex: zIndexes.topBar,
    height: navigationConstant.topBarHeight,
    position: 'fixed',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: colours.white,
    boxShadow:
      '0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)'
  },
  topBarRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%'
  },
  username: {
    paddingLeft: 15,
    paddingRight: 39,
    zIndex: zIndexes.topBarDropDown
  },
  usernameBtn: {
    '&:hover': {
      backgroundColor: 'inherit'
    },
    '&.MuiButton-root': {
      height: '100%',
      textTransform: 'none'
    }
  },
  popperList: {
    '& .MuiList-padding': {
      marginTop: 0
    },
    '& .MuiList-root': {
      padding: 0
    }
  },
  popperListItem: {
    '&:hover': {
      backgroundColor: colours.grey,
      cursor: 'pointer'
    },
    '&.MuiListItem-root': {
      height: 48,
      width: 222
    },
    '& .MuiListItem-root': {
      backgroundColor: colours.white
    }
  },
  toastNotification: {
    width: 273,
    minHeight: 93,
    backgroundColor: colours.lightGrey,
    color: colours.textPrimary,
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)'
  },
  toastNotificationIcon: { color: colours.primaryRed },
  toastNotificationAction: {
    marginTop: 5,
    color: colours.black
  }
}));

export default useStyles;
