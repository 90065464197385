/* eslint react/prop-types: 0 */
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { AdminView } from '../views/AdminView';
import { DataSummaryView } from '../views/DataSummaryView';
import { LoginView } from '../views/LoginView';
import { LogoutView } from '../views/LogoutView';
import { NotFoundView } from '../views/NotFoundView';
import { OutputsView } from '../views/OutputsView';
import { WorkspacesView } from '../views/WorkspacesView';

const CustomRoute = (props) => {
  useEffect(() => {
    try {
      document.title = `FERA App - ${props.path.charAt(1).toUpperCase() + props.path.substring(2)}`;
    } catch {
      console.error('page not found');
    }
  }, [props.path]);
  return <Route {...props}>{props.children}</Route>;
};

export const Routes = () => {
  return (
    <Router>
      <Switch>
        <CustomRoute exact path="/">
          <Redirect to="/login" />
        </CustomRoute>
        <CustomRoute path="/login" component={LoginView} />
        <CustomRoute path="/logout" component={LogoutView} />
        <CustomRoute path="/admin" component={AdminView} />
        <CustomRoute path="/summary" component={DataSummaryView} />
        <CustomRoute path="/error" component={NotFoundView} />
        <CustomRoute path="/outputs" component={OutputsView} />
        <CustomRoute path="/workspace" component={WorkspacesView} />
        <CustomRoute component={NotFoundView} />
      </Switch>
    </Router>
  );
};
