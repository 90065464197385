import makeStyles from '@mui/styles/makeStyles';

import { colours } from '../../../theme/colors';

const useStyles = makeStyles(() => ({
  breadCrumb: {
    color: colours.textPrimary,
    paddingBottom: 52
  },
  headerTitle: {
    fontFamily: 'inherit',
    fontSize: 22,
    minHeight: 36,
    alignItems: 'center'
  },
  headerTitlePrimary: {
    fontFamily: 'inherit',
    fontSize: 20,
    minHeight: 36,
    alignItems: 'center',
    color: colours.primaryBlue
  },
  inputField: {
    width: 348
  },

  FoRSwitch: { marginTop: 10 },
  switch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: colours.primaryBlue,
      '&:hover': {
        backgroundColor: colours.primaryBlueAlpha
      }
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: colours.primaryBlue
    }
  },
  checkbox: {
    color: colours.primaryBlue,
    '&.Mui-checked': {
      color: colours.primaryBlue
    }
  },
  radio: {
    color: colours.primaryBlue,
    '&.Mui-checked': {
      color: colours.primaryBlue
    }
  }
}));

export default useStyles;
