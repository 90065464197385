import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SaveIcon from '@mui/icons-material/Save';
import { DialogActions, DialogContentText, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { FERAButton } from '../../Button/FERAButton';
import GenericDialog from '../../Dialog';

const FormDialog = ({ title, onSubmit, onClose }) => {
  const [submitting, setSubmitting] = useState(false);
  function handleConfirmedSubmit() {
    setSubmitting(true);
    onSubmit();
  }

  return (
    <GenericDialog title={title} onClose={onClose}>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <InfoOutlinedIcon sx={{ fontSize: 67, mt: 2.5 }} />
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ pb: 0.5 }}>
            Please confirm
          </Typography>
        </Grid>
        <Grid item>
          <DialogContentText sx={{ maxWidth: 265, textAlign: 'center', pb: 3, m: 0 }}>
            Are you happy with these user changes?
          </DialogContentText>
        </Grid>
        <Grid item>
          <DialogActions>
            <FERAButton
              onClick={handleConfirmedSubmit}
              variant="contained"
              color="red"
              aria-label="Confirm submit changes to user"
              loading={submitting}
              loadingPosition="start"
              startIcon={<SaveIcon />}>
              Confirm & Submit
            </FERAButton>
            <FERAButton onClick={onClose} variant="outlined" color="red" aria-label="Cancel">
              Cancel
            </FERAButton>
          </DialogActions>
        </Grid>
      </Grid>
    </GenericDialog>
  );
};

FormDialog.propTypes = {
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default FormDialog;
