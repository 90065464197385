import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { usePostApproveAllocation } from '../../api/outputs';
import { GlobalAppContext } from '../../store/AppStore';
import { FERAButton } from '../Button/FERAButton';
import GenericDialog from '../Dialog';
import useStyles from './styles';

export const ApproveAllocationModal = ({ isDisabled, assumeRole, outputIds }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { mutate: approveAllocation } = usePostApproveAllocation();

  const {
    globalAlert: [, setAlert]
  } = useContext(GlobalAppContext);

  const handleOpen = () => setOpen(true);

  const handleReset = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setIsSubmitting(true);

    approveAllocation(
      {
        assumeRole: assumeRole,
        outputIds: outputIds
      },
      {
        onSuccess: (data) => {
          if (data.data.failedIds.length !== 0) {
            setAlert({
              open: true,
              message: 'There has been an error occurred',
              severity: 'error'
            });
          } else {
            setAlert({
              open: true,
              message: 'Output has been successfully updated',
              severity: 'success'
            });
          }
          handleReset();
          setIsSubmitting(false);
        },
        onError: () => {
          handleReset();
          setIsSubmitting(false);
          setAlert({
            open: true,
            message: 'There has been an error occurred',
            severity: 'error'
          });
        }
      }
    );
  };

  return (
    <div data-testid="approve-allocation-component">
      <FERAButton
        variant="contained"
        color="purple"
        onClick={handleOpen}
        disabled={isDisabled}
        data-testid="approve-allocation-open-modal-button">
        Approve Allocation
      </FERAButton>
      {open && (
        <GenericDialog title="Approve Allocation" onClose={handleReset}>
          <Box className={classes.modalContent} data-testid="approve-allocation-modal">
            <InfoOutlinedIcon
              sx={{ fontSize: 67 }}
              className={clsx(classes.center, classes.mainBlue)}
            />
            <Typography variant="h1" className={classes.approveHeader}>
              Please confirm
            </Typography>
            <Typography className={classes.approveInfor} data-testid="modal-modal-description">
              Please confirm that you approve the use of this code
              <br /> for this output. <br /> The primary owner will be notified
            </Typography>
            <Box className={classes.center}>
              <FERAButton
                onClick={onSubmit}
                data-testid="modal-modal-approve-button"
                variant="contained"
                color="red"
                aria-label="Confirm and approve FoR allocation code change"
                loading={isSubmitting}
                loadingPosition="start"
                sx={{ marginRight: 2 }}
                startIcon={<SaveIcon />}>
                Confirm & Submit
              </FERAButton>
              <FERAButton
                variant="outlined"
                color="red"
                onClick={handleReset}
                data-testid="modal-modal-cancel-button"
                aria-label="Close approve dialog">
                Cancel
              </FERAButton>
            </Box>
          </Box>
        </GenericDialog>
      )}
    </div>
  );
};
ApproveAllocationModal.propTypes = {
  isDisabled: PropTypes.bool,
  assumeRole: PropTypes.number.isRequired,
  outputIds: PropTypes.array
};
