import axios from 'axios';

const client = axios.create({ baseURL: process.env.REACT_APP_API_ENDPOINT });
export const request = (options) => {
  client.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('AuthToken')}`;

  const onSuccess = (response) => {
    return response;
  };
  const onError = (error) => {
    throw error.response;
  };

  return client({ ...options })
    .then(onSuccess)
    .catch(onError);
};
