import SaveIcon from '@mui/icons-material/Save';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { useUpdateUser } from '../../api/user';
import { GlobalAppContext } from '../../store/AppStore';
import { AuthContext } from '../../store/AuthStore';
import Preferences from '../AdminPage/EditUser/Preferences';
import { FERAButton } from '../Button/FERAButton';
import GenericDialog from '../Dialog';

export const UpdatePreferencesDialog = ({ onClose }) => {
  const { authUser } = useContext(AuthContext);
  const {
    globalAlert: [, setAlert]
  } = useContext(GlobalAppContext);

  const { citation, qualityIndicator } = authUser.userInformation;

  const [qualityIndicatorPreference, setQualityIndicatorPreference] = useState(qualityIndicator);
  const [citationPreference, setCitationPreference] = useState(citation);
  const [submitting, setSubmitting] = useState(false);

  const { mutate: updateUser } = useUpdateUser(authUser.userInformation.id);

  const onSubmit = () => {
    setSubmitting(true);
    var newData = {
      ...authUser.userInformation,
      qualityIndicator: qualityIndicatorPreference,
      citation: citationPreference,
      notes: ''
    };
    delete newData.preferredCitation;
    delete newData.preferredQualityIndicator;

    updateUser(newData, {
      onSuccess:
        /* istanbul ignore next */ /* Mocking the function as it is using the context API */ () => {
          setAlert({
            message: 'Successfully updated your preferences',
            open: true,
            severity: 'success'
          });
          onClose();
        },
      onError:
        /* istanbul ignore next */ /* Mocking the function as it is using the context API */ () => {
          setAlert({ message: 'Unable to update preferences', open: true, severity: 'error' });
          setSubmitting(false);
        }
    });
  };

  return (
    <GenericDialog title="Edit Preferences" onClose={onClose} sx={{ mt: 4, pl: 6 }}>
      <Preferences
        qualityIndicatorPreference={qualityIndicatorPreference}
        setQualityIndicatorPreference={setQualityIndicatorPreference}
        citationPreference={citationPreference}
        setCitationPreference={setCitationPreference}
      />
      <FERAButton
        data-testid="update-current-user-preferences-button"
        onClick={onSubmit}
        color="red"
        variant="contained"
        aria-label="Submit changes to preferences"
        loading={submitting}
        loadingPosition="start"
        startIcon={<SaveIcon />}
        style={{
          float: 'right',
          marginTop: 26,
          marginRight: 56
        }}>
        Save
      </FERAButton>
    </GenericDialog>
  );
};

UpdatePreferencesDialog.propTypes = { onClose: PropTypes.func.isRequired };
