export const colours = {
  e0: '#E0E0E0',
  primaryBlue: '#222160',
  lightBlue: '#7B7B9B',
  primaryRed: '#E61E2A',
  white: '#FFFFFF',
  primaryGrey: 'rgba(0, 0, 0, 0.54)',
  secondaryGrey: '#F5F5F5',
  lightGrey: '#FAFAFA',
  grey: '#EEEEEE',
  loadingBgGrey: '#E3E5E0',
  loadingTextGrey: '#616161',
  borderGrey: 'rgba(0, 0, 0, 0.23)',
  hoverGrey: 'rgba(0, 0, 0, 0.005)',
  textPrimary: '#191919',
  yellow: '#FAC800',
  warningYellowAlpha: 'rgba(255, 152, 0, 0.5)',
  warningYellow: '#FFB547',
  successGreen: '#306F33',
  errorRed: '#E31B0C',
  actionDisabled: 'rgba(0, 0, 0, 0.26)',
  actionDisabledBackground: '#E3E5E033',
  primaryBlueAlpha: 'rgba(34, 33, 96, 0.5)',
  primaryRedAlpha: 'rgba(227, 27, 12, 0.5)',
  primaryBlueDark: '#050446',
  primaryRedDark: '#A82227',
  black: '#000000',
  purpleGrey: 'rgba(34, 33, 96, 0.5)',
  disabledGrey: '#757575',
  primaryBlueRow: 'rgba(34, 33, 96, 0.1)',
  primaryBlueRowHover: 'rgba(34, 33, 96, 0.2)'
};
