import { parse } from 'query-string';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { LoadingWithNavBar } from '../../components/Loader/LoadingWithNavBar';
import { AuthContext } from '../../store/AuthStore';

export const Login = () => {
  const history = useHistory();
  const { onLogin } = useContext(AuthContext);
  const { id_token } = parse(window.location.hash);

  useEffect(() => {
    if (typeof id_token === 'string') {
      onLogin(id_token);
      history.push('/workspace');
    } else if (localStorage.getItem('AuthToken') !== null) {
      onLogin(localStorage.getItem('AuthToken'));
      history.push('/workspace');
    } else {
      const params = [
        'response_type=token',
        `client_id=${process.env.REACT_APP_PUBLIC_COGNITO_CLIENT_ID}`,
        `scope=${process.env.REACT_APP_PUBLIC_COGNITO_SCOPE}`,
        `redirect_uri=${process.env.REACT_APP_PUBLIC_CLIENT_URL}`
      ];

      const paramsForURL = params.join('&');

      window.location.href = `${process.env.REACT_APP_PUBLIC_COGNITO_LOGIN_URL}?${paramsForURL}`;
    }
  }, [history, id_token, onLogin]);

  return <LoadingWithNavBar />;
};
