import { useQuery } from 'react-query';

import { usersEndpoint } from './endpoints';
import { request } from './request';

export const buildRequestForGetUsers = (page, size, sorts) => {
  const params = {
    page: page,
    size: size
  };
  const sort = sorts[0];
  if (sort) {
    params.sort = `${sort.field},${sort.sort}`;
  } else {
    params.sort = 'updated_at,desc';
  }
  return { url: usersEndpoint, params: params };
};

export const useGetUsers = (page, size, sort) => {
  return useQuery(['users', page, size, sort], async () => {
    return request(buildRequestForGetUsers(page, size, sort)).then((response) => {
      return response.data;
    });
  });
};
