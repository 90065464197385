import React from 'react';

import { PageHeader } from '../../PageHeader/PageHeader';
import { WorkspaceCodeAndName } from '../../Workspaces/WorkspaceComponents/WorkspaceCodeAndName';
import useStyles from './styles';

export const OutputLists = () => {
  const classes = useStyles();

  return (
    <div data-testid="output-lists-component">
      <PageHeader location="All Outputs" title="View Outputs" data-testid="output-lists-header" />
      <div className={classes.searchAllOutputsContainer} data-testid="output-lists-content">
        <WorkspaceCodeAndName all={true} />
      </div>
    </div>
  );
};
