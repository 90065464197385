import makeStyles from '@mui/styles/makeStyles';

import { colours } from '../../../theme/colors';

const useStyles = makeStyles(() => ({
  FoRStatus: {
    cursor: 'pointer',
    borderRadius: '50%',
    fontSize: '1rem'
  },
  emailIcon: {
    color: colours.loadingTextGrey,
    margin: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  emailName: {
    marginLeft: 50,
    color: colours.loadingTextGrey
  },
  accordion: {
    backgroundColor: colours.secondaryGrey,
    '&.MuiAccordion-root': {
      '&.Mui-expanded': {
        marginTop: 0,
        marginBottom: 10
      }
    },
    marginBottom: 10
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  allocationWrapper: {
    borderLeftStyle: 'solid',
    borderLeftWidth: 6,
    borderLeftColor: colours.primaryRed,
    padding: '21px 17px',
    backgroundColor: colours.secondaryGrey
  },
  allocationHeader: {
    display: 'flex',
    flexDirection: 'row'
  },
  allocationBadge: {
    width: 'inherit',
    display: 'inherit',
    justifyContent: 'inherit',
    marginLeft: 15,
    alignItems: 'center'
  },
  forCodeCol: {
    display: 'table-cell',
    maxWidth: 250,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  approvalCol: {
    padding: '0px 5px'
  },
  allocationBtn: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 15,
    paddingTop: 32
  },
  citationDetails: {
    fontWeight: 400,
    fontSize: 14,
    padding: '17px 24px 0px 17px'
  },
  citrix: {
    overflowWrap: 'break-word',
    paddingTop: 61
  },
  linksWrapper: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 23,
    alignItems: 'center',
    paddingLeft: 18,
    paddingBottom: 26,
    paddingTop: 8
  },
  topInfo: {
    color: colours.textPrimary
  },
  title: {
    backgroundColor: colours.secondaryGrey,
    padding: '12px 20px',
    fontWeight: 700,
    fontSize: 18
  },
  commentIcon: {
    '& .MuiSvgIcon-root': {
      backgroundColor: colours.primaryRed,
      color: colours.white,
      borderRadius: '50%',
      padding: 2,
      height: 20,
      width: 20
    }
  },
  history: {
    fontWeight: 700,
    fontSize: 16,
    paddingLeft: 0,
    paddingBottom: 2
  },
  historyContainer: {
    paddingTop: 39,
    paddingLeft: 30
  },
  historyStepper: {
    paddingLeft: 24,
    paddingRight: 30,
    marginLeft: -22
  },
  loadMore: {
    margin: '50px 24px',
    left: -10
  },
  lazyLoader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  historyTextBox: {
    padding: 24
  },
  warningText: {
    backgroundColor: colours.warningYellowAlpha,
    color: colours.textPrimary,
    padding: '6px 16px'
  }
}));

export default useStyles;
