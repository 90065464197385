import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { usePostComment } from '../../api/outputs';
import { GlobalAppContext } from '../../store/AppStore';
import { FERAButton } from '../Button/FERAButton';
import GenericDialog from '../Dialog';
import { reasonCodes } from '../SearchBar/OptionsConfig';
import useStyles from './styles';

export const CommentModal = ({ bulk, onClose }) => {
  const classes = useStyles();
  const [comment, setComment] = useState('');
  const [selectedReasonCode, setSelectedReasonCode] = useState('');
  const [isReasonCodeSelected, setIsReasonCodeSelected] = useState(false);
  const [isError, setIsError] = useState(false);
  const {
    rightDrawer: [rightDrawerIsOpen],
    globalAlert: [, setAlert],
    pubPkSelection: [selectionModel, setSelectionModel]
  } = useContext(GlobalAppContext);

  const { mutate: addComment, isLoading } = usePostComment();

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = () => {
    addComment(
      {
        comment: comment,
        reasonCode: selectedReasonCode,
        outputIds: bulk ? selectionModel : [rightDrawerIsOpen?.pubPk]
      },
      {
        onSuccess: (data) => {
          if (!bulk) {
            setAlert({
              open: true,
              message: `Output has been successfully updated`,
              severity: 'success'
            });
          } else {
            if (data.failedIds.length === 0) {
              setAlert({
                open: true,
                message: `Output${selectionModel.length > 1 ? 's' : ''} ${
                  selectionModel.length > 1 ? 'have' : 'has'
                } been successfully updated`,
                severity: 'success'
              });
              setSelectionModel([]);
            } else {
              setSelectionModel(data.failedIds);
            }
          }
          onClose();
        }
      }
    );
  };

  return (
    <GenericDialog title="Send a Comment" onClose={onClose} data-testid="add-comment-modal">
      <Box className={classes.modalContent}>
        <Typography id="modal-modal-description" data-testid="modal-modal-description">
          {bulk && selectionModel.length > 1
            ? 'Make a comment on these outputs'
            : 'Leave a comment on this output'}
        </Typography>
        <TextField
          className={classes.textField}
          variant="outlined"
          multiline
          rows={5}
          placeholder="Add your comment here"
          onChange={handleChange}
          inputProps={{ minLength: 1, maxLength: 256 }}
          data-testid="modal-modal-textfield"
        />
        <Box className={classes.dropdown}>
          <FormControl fullWidth error={isError}>
            <InputLabel shrink={false}> {!isReasonCodeSelected && 'Comment Type'}</InputLabel>
            <Select
              fullWidth
              data-testid="modal-dropdown-select"
              value={selectedReasonCode}
              onChange={(event) => {
                setSelectedReasonCode(event.target.value);
                setIsReasonCodeSelected(event.target.value !== '');
                setIsError(event.target.value === '');
              }}
              MenuProps={{
                keepMounted: true,
                disablePortal: true
              }}>
              {reasonCodes.map((c, index) => (
                <MenuItem key={c} value={c} data-testid={`reasoncode-option-${index}`}>
                  {c}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Select the main purpose of your comment</FormHelperText>
          </FormControl>
        </Box>
        {bulk && selectionModel.length > 1 && (
          <Typography
            id="modal-modal-bulk-description"
            data-testid="modal-modal-bulk-description"
            style={{ marginTop: 30, width: 340 }}>
            Please note this comment will be written to all {selectionModel.length} selected outputs
          </Typography>
        )}
        <Box className={classes.buttons}>
          <FERAButton
            data-testid={
              isLoading ? 'modal-modal-loading-button' : 'modal-modal-add-comment-button'
            }
            onClick={isReasonCodeSelected ? handleSubmit : () => setIsError(true)}
            disabled={comment.length === 0}
            variant="contained"
            color="red"
            aria-label="Submit a comment"
            loading={isLoading}
            loadingPosition="start"
            sx={{ marginRight: 2 }}
            startIcon={<SaveIcon />}>
            Add Comment
          </FERAButton>
          <FERAButton
            variant="outlined"
            color="red"
            onClick={onClose}
            data-testid="modal-modal-cancel-button">
            Cancel
          </FERAButton>
        </Box>
      </Box>
    </GenericDialog>
  );
};
CommentModal.propTypes = {
  bulk: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

export const CommentModalButton = () => {
  const [open, setOpen] = useState(false);

  return (
    <div data-testid="add-comment-component">
      <FERAButton
        variant="outlined"
        color="purple"
        onClick={() => {
          setOpen(true);
        }}
        data-testid="add-comment-open-modal-button">
        Add Comment
      </FERAButton>
      {open && (
        <CommentModal
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
    </div>
  );
};
