import { useQuery } from 'react-query';

import { dataQualityEndpoint } from './endpoints';
import { request } from './request';

export const useGetDataRefresh = () => {
  return useQuery(['data_refresh'], async () => {
    return request({ url: `${dataQualityEndpoint}/refresh` }).then((response) => {
      const latest = response.data?.lastUpdatedTimestamp || null;
      let date = new Date(latest);

      if (latest === null) {
        console.warning('No data refresh available');
        return 'No data refresh available';
      } else {
        return date.toLocaleString('en-GB', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          timeZone: 'Australia/Melbourne'
        });
      }
    });
  });
};
