import { useQuery } from 'react-query';

import { assignedForCodesEndpoint, FoRCodeEndpoint } from './endpoints';
import { request } from './request';

export const useGetAssignedForCodes = () => {
  return useQuery(['assignedForCodes'], async () => {
    return request({ url: assignedForCodesEndpoint }).then((response) => {
      return response.data.data.sort((a, b) => a.forCode.localeCompare(b.forCode));
    });
  });
};

export const useGetFoRCodes = (digits) => {
  const params = {
    twoDigit: digits.includes(2),
    fourDigit: digits.includes(4),
    sixDigit: digits.includes(6)
  };
  return useQuery(
    ['FoRCodes', params],
    async () => {
      return request({ url: FoRCodeEndpoint, params: params }).then((response) => {
        const data = response.data.data.filter(
          (item, index, data) => data.findIndex((i) => i.forCode === item.forCode) === index
        );
        return { data };
      });
    },
    { enabled: digits.length > 0 }
  );
};
