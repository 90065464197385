import { Checkbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import { colours } from '../../theme/colors';
import { zIndexes } from '../../theme/zIndexes';

export const StyledCheckbox = withStyles({
  root: {
    '&$checked': {
      color: colours.primaryBlue,
      '&:hover': {
        backgroundColor: colours.hoverGrey
      }
    },
    '&:hover': {
      backgroundColor: colours.hoverGrey
    }
  },
  checked: {}
})(Checkbox);

const useStyles = makeStyles(() => ({
  accordion: {
    '&.MuiAccordion-root': {
      '&.Mui-expanded': {
        margin: 0
      }
    }
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0
  },
  accordionHeader: {
    backgroundColor: colours.secondaryGrey,
    color: colours.primaryBlue
  },
  accordionItem: {
    '&.MuiListItem-root': {
      width: 294
    }
  },
  badgeIcon: {
    '& .MuiBadge-badge': {
      backgroundColor: colours.primaryRed,
      color: colours.white
    },
    marginRight: 9
  },
  buttonTitle: {
    padding: '3px 18px'
  },
  buttonText: {
    '&:hover': {
      backgroundColor: 'inherit'
    },
    '&.MuiButton-root': {
      height: 56,
      textTransform: 'none'
    },
    color: '#000000',
    '& .MuiTypography-body1': {
      fontWeight: 700,
      fontSize: 14
    }
  },
  dropdownWrapper: {
    zIndex: zIndexes.dropdown
  },
  form: {
    width: 220,
    height: 56
  },
  styledText: {
    color: colours.primaryBlue
  },
  popperList: {
    '& .MuiList-padding': {
      marginTop: 0
    },
    '& .MuiList-root': {
      padding: 0
    }
  },
  popperListItem: {
    width: 260,
    '&:hover': {
      backgroundColor: colours.grey,
      cursor: 'pointer'
    },
    '&.MuiListItem-root': {
      height: 48
    },
    '& .MuiListItem-root': {
      backgroundColor: colours.white
    }
  },
  menuItem: {
    '&.MuiListItem-root': {
      '&.Mui-selected': {
        backgroundColor: colours.white,
        '&:focus': {
          backgroundColor: colours.grey
        }
      },
      '&:hover': {
        backgroundColor: colours.grey,
        cursor: 'pointer'
      }
    }
  }
}));

export default useStyles;
