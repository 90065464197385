import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { useGetUserSession } from '../api/session';

export const AuthContext = React.createContext(undefined);

export const AuthStore = ({ children }) => {
  const [authUser, setAuthUser] = useState();
  const { data, isLoading, refetch } = useGetUserSession(localStorage.getItem('AuthToken'));

  const onLogin = useCallback(
    (token) => {
      localStorage.setItem('AuthToken', token);
      refetch();
    },
    [refetch]
  );

  const onLogout = () => {
    localStorage.removeItem('AuthToken');
  };

  useEffect(() => {
    const authToken = localStorage.getItem('AuthToken');

    if (authToken) {
      onLogin(authToken);
    }
  }, [onLogin]);

  useEffect(() => {
    if (isLoading) return;
    if (!data) {
      setAuthUser({ isAuthenticated: false, userInformation: null });
    } else {
      const userRoles = new Set(data.userRoles.map((role) => role.roleName.toLowerCase()));
      const adminRoles = new Set(['administrator', 'university_lead']);
      const isUserAdmin = new Set([...userRoles].filter((x) => adminRoles.has(x))).size > 0;
      setAuthUser({ isAuthenticated: true, userInformation: data, userIsAdmin: isUserAdmin });
    }
  }, [data, isLoading]);

  const state = {
    authUser,
    onLogin,
    onLogout
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

AuthStore.propTypes = {
  children: PropTypes.any
};
