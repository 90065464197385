import SearchIcon from '@mui/icons-material/Search';
import { Grid, MenuItem, Select } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useGetOutputs } from '../../api/outputs';
import useDebounce from '../../helpers/useDebounce';
import { DropdownAccordion } from '../Dropdown/DropdownAccordion/DropdownAccordion';
import { DropdownActionButton } from '../Dropdown/DropdownActionButton/DropdownActionButton';
import useStyles, { StyledTextField } from './styles';

export const SearchBar = ({
  forAction,
  location,
  categories,
  filters,
  actions,
  onQuery,
  autocompleteForCode,
  value
}) => {
  const classes = useStyles();
  const [selectedCategories, setSelectedCategories] = useState(value.categories);
  const [searchText, setSearchText] = useState(value.text);
  const [selectedFilters, setSelectedFilters] = useState(value.filters);
  const debouncedSearch = useDebounce(searchText.trim(), 300);

  useEffect(() => {
    setSelectedCategories(value.categories);
    setSearchText(value.text);
    setSelectedFilters(value.filters);
  }, [value]);

  const { data } = useGetOutputs(
    0,
    10,
    selectedCategories[0] !== 'Title' ? null : autocompleteForCode,
    [],
    selectedCategories,
    debouncedSearch,
    selectedFilters,
    forAction
  );
  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleKeydown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onQuery({ categories: selectedCategories, text: searchText, filters: selectedFilters });
    }
  };

  useEffect(() => {
    onQuery({ categories: selectedCategories, text: searchText, filters: selectedFilters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters, selectedCategories]);

  return (
    <div data-testid={`search-bar-${location}`} className={classes.searchArea}>
      <Grid container>
        <Grid item xs={2}>
          <Select
            data-testid="search-bar-dropdown-select"
            value={selectedCategories[0]}
            onChange={(event) => {
              setSelectedCategories([event.target.value]);
            }}
            sx={{ width: 220 }}>
            {categories.map((c) => (
              <MenuItem key={c} value={c}>
                {c}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            filterOptions={createFilterOptions({
              limit: 20,
              trim: true
            })}
            ListboxProps={{
              style: {
                maxHeight: '400px'
              }
            }}
            disablePortal
            data-testid="searchbar-autocomplete"
            freeSolo
            options={
              debouncedSearch === '' || selectedCategories[0] !== 'Title'
                ? []
                : (data?.data || []).map((d) => d.title)
            }
            value={searchText}
            onChange={(_, newValue, reason) => {
              if (reason === 'createOption' || reason === 'selectOption') {
                setSearchText(newValue);
                onQuery({
                  categories: selectedCategories,
                  text: newValue,
                  filters: selectedFilters
                });
              }
              if (reason === 'clear') {
                setSearchText('');
                onQuery({ categories: selectedCategories, text: '', filters: selectedFilters });
              }
            }}
            clearOnBlur
            className={classes.searchBar}
            renderOption={(props, option) => (
              /* eslint-disable react/prop-types */
              <li
                {...props}
                onClick={(event) => {
                  props.onClick(event);
                  setSearchText(props.key);
                  onQuery({
                    categories: selectedCategories,
                    text: props.key,
                    filters: selectedFilters
                  });
                }}>
                {option}
              </li>
              /* eslint-enable react/prop-types */
            )}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                variant="outlined"
                onChange={handleChange}
                onKeyDown={handleKeydown}
                placeholder="Search..."
                data-testid={`search-bar-text-field-${location}`}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      <SearchIcon
                        onClick={() => {
                          onQuery({
                            categories: selectedCategories,
                            text: searchText,
                            filters: selectedFilters
                          });
                        }}
                        className={classes.searchIcon}
                      />
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid container item xs={2} justifyContent="flex-end">
          {filters && (
            <DropdownAccordion
              lists={filters}
              buttonName="filters"
              buttonLocation={location}
              selected={selectedFilters}
              setSelected={setSelectedFilters}
              className={classes.accordion}
            />
          )}
        </Grid>
        <Grid container item xs={2} justifyContent="flex-end">
          {actions && actions.length > 0 && (
            <DropdownActionButton
              lists={actions}
              buttonName="Actions"
              buttonLocation={location}
              className={classes.dropdownBtn}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

SearchBar.propTypes = {
  location: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  filters: PropTypes.array,
  actions: PropTypes.array,
  onQuery: PropTypes.func.isRequired,
  autocompleteForCode: PropTypes.number.isRequired,
  forAction: PropTypes.bool.isRequired,
  value: PropTypes.object.isRequired
};
