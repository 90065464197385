import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';

import { colours } from '../../theme/colors';
import { zIndexes } from '../../theme/zIndexes';

const FormDialogTitle = ({ children, onClose, ...other }) => {
  return (
    <MuiDialogTitle
      sx={{
        m: 0,
        pt: 2,
        pb: 2,
        pr: 2,
        pl: 6,
        backgroundColor: colours.primaryBlue,
        color: colours.white
      }}
      {...other}>
      <Typography variant="h6" component={'span'}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          data-testid="modal-modal-close-icon"
          aria-label="close"
          sx={{ position: 'absolute', top: 4, right: 0, color: colours.white }}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

FormDialogTitle.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func.isRequired
};

const GenericDialog = ({ title, onClose, children, sx, height }) => {
  return (
    <Dialog
      maxWidth="false"
      open={true}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      style={{
        zIndex: zIndexes.modal
      }}>
      <div style={{ width: 650, height: height }}>
        <FormDialogTitle id="form-dialog-title" onClose={onClose}>
          {title}
        </FormDialogTitle>
        <DialogContent sx={{ mb: 41 / 8, ...sx }}>{children}</DialogContent>
      </div>
    </Dialog>
  );
};

GenericDialog.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  sx: PropTypes.object,
  height: PropTypes.number
};

export default GenericDialog;
