import { FormControlLabel, Grid, Switch } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { FERAButton } from '../../Button/FERAButton';
import SearchDropDown from '../../SearchDropDown';
import useStyles from './styles';

const FoRField = ({
  assignedCode,
  options,
  getOptionDisabled,
  onRemove,
  onOwnerChange,
  onCodeChange
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={9} data-testid="for-field-row">
      <Grid container spacing={0}>
        <Grid item xs={5}>
          <SearchDropDown
            placeholder="Select a code"
            getOptionLabel={(o) =>
              o.forCode ? `${o.forCode} - ${o.forName ? o.forName : 'Unknown'}` : ''
            }
            options={options}
            value={assignedCode}
            onChange={onCodeChange}
            getOptionDisabled={getOptionDisabled}
            data-testid="for-field-row-dropdown"
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Switch
                checked={assignedCode.isPrimaryOwner}
                className={classes.switch}
                onChange={(event) => {
                  onOwnerChange(event.target.checked);
                }}
                data-testid="for-field-row-switch"
              />
            }
            className={classes.FoRSwitch}
            label="Primary owner"
          />
        </Grid>
        <Grid item xs={1}>
          <FERAButton
            variant="outlined"
            color="purple"
            aria-label="Remove FoR code"
            onClick={onRemove}
            style={{ marginTop: 5 }}
            data-testid="for-field-row-remove-btn">
            Remove
          </FERAButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const FoRFieldShape = PropTypes.shape({
  forCode: PropTypes.string.isRequired,
  forName: PropTypes.string,
  isPrimaryOwner: PropTypes.bool.isRequired
});

export const FoRFieldShapeOption = PropTypes.shape({
  forCode: PropTypes.string.isRequired,
  forName: PropTypes.string,
  isPrimaryOwner: PropTypes.bool
});

FoRField.propTypes = {
  options: PropTypes.arrayOf(FoRFieldShapeOption).isRequired,
  assignedCode: FoRFieldShape.isRequired,
  getOptionDisabled: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onOwnerChange: PropTypes.func.isRequired,
  onCodeChange: PropTypes.func.isRequired
};

export default FoRField;
