import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import {
  getSingleOutput,
  useGetOutputs,
  usePostApproveAllocation,
  usePostCommit
} from '../../../api/outputs';
import { GlobalAppContext } from '../../../store/AppStore';
import { AuthContext } from '../../../store/AuthStore';
import { Loader } from '../../Loader/Loader';
import { GenericBulkConfirmModal } from '../../Modals/BulkApproveConfirmModal';
import { BulkConfirmationModal } from '../../Modals/BulkConfirmationModal';
import { CommentModal } from '../../Modals/CommentModal';
import { SubmitForReviewBulkModal } from '../../Modals/SubmitForReviewBulkModal';
import { getAssumedRole } from '../../Modals/SubmitReviewModal';
import { NoResults } from '../../NoResults/NoResults';
import { getFilters, workspaceCategories } from '../../SearchBar/OptionsConfig';
import { SearchBar } from '../../SearchBar/SearchBar';
import { DataTable } from '../../Table/DataTable';
import { getOutputTableColumns } from '../../Table/DataTableConfig';
import useStyles from './styles';

export const WorkspaceCodeAndName = ({ forAction = false, all = false }) => {
  const { authUser } = useContext(AuthContext);
  const preferredCitation = authUser.userInformation.citation;
  const preferredQualityIndicator = authUser.userInformation.qualityIndicator;

  const {
    rightDrawer: [, setRightDrawerIsOpen],
    forCodeSelected: [forCode],
    outputSort: [forSort],
    pageNum: [page, setPage],
    pageSize: [size],
    pubPkSelection: [selectionModel, setSelectionModel],
    globalAlert: [, setAlert]
  } = useContext(GlobalAppContext);

  const assumedRole = getAssumedRole(authUser, forCode);

  const classes = useStyles();
  const [bulkApprove, setBulkApprove] = useState(false);
  const [bulkCommit, setBulkCommit] = useState(false);
  const [submitForReviewModalVisible, setSubmitForReviewModalVisible] = useState(false);
  const [approveCommitConfirmationModalInfo, setApproveCommitConfirmationModalInfo] = useState({
    failed: [],
    success: []
  });
  const [commentModalVisible, setCommentModalVisible] = useState(false);

  const defaultSearch = { categories: ['Title'], text: '', filters: [] };
  const [search, setSearch] = useState(defaultSearch);

  useEffect(() => {
    setSearch(defaultSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forCode.FoRCode]);

  const { data, isLoading, isError, isSuccess, isFetching } = useGetOutputs(
    page,
    size,
    all ? -1 : forCode.FoRCode,
    JSON.parse(forSort),
    search.categories,
    search.text.trim(),
    search.filters,
    forAction,
    preferredQualityIndicator
  );

  const { mutate: approveAllocation } = usePostApproveAllocation();
  const { mutate: commitAllocation } = usePostCommit();

  const handleClick = (pubPk) => {
    getSingleOutput(pubPk, all ? -1 : forCode.FoRCode, setRightDrawerIsOpen, setAlert);
  };

  const columns = getOutputTableColumns(
    handleClick,
    preferredCitation,
    preferredQualityIndicator,
    forAction
  );

  const actionsAreDisabled = selectionModel === null || selectionModel.length === 0;

  const getUserFoRCodeInfo = () => {
    const roleName = forCode.FoRCode.length === 2 ? 'TWO_DIGITS_ADVISOR' : 'FOUR_DIGITS_ADVISOR';
    const currentRole = authUser.userInformation.userRoles.filter(
      (r) => r.roleName === roleName
    )[0];
    return currentRole.userRoleForCodes.filter((c) => c.forCode === forCode.FoRCode)[0];
  };

  const bulkApproveDetails =
    'These outputs will be submitted for final approval.\nThis confirmation asserts you approve the use of your\ncode for theseoutputs';

  const bulkApproveOrCommitAllocation = (action) => {
    const bulkAction = action === 'approve' ? approveAllocation : commitAllocation;
    const setActionModalVisible = action === 'approve' ? setBulkApprove : setBulkCommit;

    return () => {
      bulkAction(
        {
          assumeRole: assumedRole,
          outputIds: selectionModel
        },
        {
          onSuccess: (data) => {
            setActionModalVisible(false);
            if (data.data.failedIds.length === 0) {
              setAlert({
                open: true,
                message: `Output${selectionModel.length > 1 ? 's' : ''} ${
                  selectionModel.length > 1 ? 'have' : 'has'
                } been successfully updated`,
                severity: 'success'
              });
              setSelectionModel([]);
              return;
            }
            setSelectionModel(data.data.failedIds);
            setApproveCommitConfirmationModalInfo({
              failed: data.data.failedIds,
              succeeded: data.data.successfulIds
            });
          },
          onError: (error) => {
            if (error?.status === 403) {
              setAlert({
                open: true,
                message: `You do not have permission to ${action} these outputs`,
                severity: 'error'
              });
            } else {
              setAlert({
                open: true,
                message:
                  'There has been an error retrieving data from the database. Please contact the ERA Administrator',
                severity: 'error'
              });
            }
          }
        }
      );
    };
  };

  const bulkCommitDetails = `You are submitting ${selectionModel.length} outputs to ERA.\nThe submission certifies that you approve the current outputs FoR Allocation`;

  const workspaceActions = [
    {
      name: 'Submit for Review',
      onClick: () => {
        setSubmitForReviewModalVisible(true);
      },
      disabled:
        actionsAreDisabled ||
        window.location.pathname === '/outputs' ||
        forAction ||
        !getUserFoRCodeInfo().isPrimaryOwner
    },
    {
      name: 'Approve',
      onClick: () => {
        setBulkApprove(true);
      },
      disabled:
        actionsAreDisabled ||
        window.location.pathname === '/outputs' ||
        !getUserFoRCodeInfo().isPrimaryOwner
    },
    {
      name: 'Commit',
      onClick: () => {
        setBulkCommit(true);
      },
      disabled:
        actionsAreDisabled ||
        window.location.pathname === '/outputs' ||
        forCode.FoRCode.length !== 2 ||
        !getUserFoRCodeInfo().isPrimaryOwner // Need to be 2 digit + primary
    },
    {
      name: 'Comment',
      onClick: () => setCommentModalVisible(true),
      disabled: actionsAreDisabled
    }
  ];

  const UIfilters = getFilters(forAction, preferredQualityIndicator, forCode.FoRCode);

  return (
    <div
      data-testid={
        forAction ? 'workspace-for-action-container' : 'workspace-code-and-name-container'
      }
      className={classes.singleTab}>
      <SearchBar
        data-testid="workspace-code-and-name-search-bar"
        location="workspace-tab-code-and-name"
        categories={workspaceCategories}
        filters={UIfilters}
        forAction={forAction}
        actions={workspaceActions}
        autocompleteForCode={all ? -1 : forCode.FoRCode}
        value={search}
        onQuery={(query) => {
          setPage(0);
          setSearch(query);
        }}
      />

      {isLoading || isFetching ? (
        <Loader data-testid="workspace-code-and-name-loader" />
      ) : isError ? (
        <NoResults
          message="Data cannot be displayed"
          data-testid="workspace-code-and-name-error"
          isError={true}
        />
      ) : isSuccess && data && data.data && data.data.length > 0 ? (
        <DataTable
          data-testid="workspace-code-and-name-output-lists-data-table"
          rows={data.data}
          columns={columns}
          totalCount={data.totalResults}
        />
      ) : (
        <NoResults
          message="Your search returned no results"
          data-testid="workspace-code-and-name-no-results"
        />
      )}

      {/* BULK SUBMIT */}
      {submitForReviewModalVisible && (
        <SubmitForReviewBulkModal
          onClose={() => {
            setSubmitForReviewModalVisible(false);
          }}
        />
      )}
      {/* BULK Approve verify */}
      {bulkApprove && (
        <GenericBulkConfirmModal
          title="Approve Allocation"
          onSubmit={bulkApproveOrCommitAllocation('approve')}
          reset={() => setBulkApprove(false)}
          details={bulkApproveDetails}
        />
      )}
      {/* BULK Commit verify */}
      {bulkCommit && (
        <GenericBulkConfirmModal
          title="Commit these Outputs"
          onSubmit={bulkApproveOrCommitAllocation('commit')}
          reset={() => setBulkCommit(false)}
          details={bulkCommitDetails}
        />
      )}
      {/* BULK Approve or Commit confirmation */}
      {approveCommitConfirmationModalInfo.failed.length > 0 && (
        <BulkConfirmationModal
          title="Output Approval"
          onClose={() => {
            setApproveCommitConfirmationModalInfo({ failed: [], succeeded: [] });
          }}
          successMessage={`We were able to submit ${approveCommitConfirmationModalInfo.succeeded.length} of the requested output for approval. The remaining
    outputs were unable to be submitted for approval`}
        />
      )}
      {commentModalVisible && (
        <CommentModal
          bulk
          onClose={() => {
            setCommentModalVisible(false);
          }}
        />
      )}
    </div>
  );
};

WorkspaceCodeAndName.propTypes = {
  forAction: PropTypes.bool,
  all: PropTypes.bool
};
