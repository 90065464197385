import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { getFilters } from '../components/SearchBar/OptionsConfig';
import { GlobalAppContext } from '../store/AppStore';
import { outputEndpoint, outputsEndpoint, searchOutputsEndpoint } from './endpoints';
import { request } from './request';

export const DBErrorAlert = {
  open: true,
  message:
    'There has been an error retrieving data from the database. Please contact the ERA Administrator',
  severity: 'error'
};

export const MissingFoRAlert = {
  open: true,
  message: 'Please select a workspace first to use export feature',
  severity: 'error'
};

export const useGetOutputAdvisors = (outputId) => {
  return useQuery(
    ['output_advisors', outputId],
    async () => {
      return request({ url: `${outputEndpoint}/${outputId}/advisors` }).then((response) => {
        return response.data;
      });
    },
    { enabled: typeof outputId !== 'undefined' }
  );
};

export const getSingleOutput = (outputId, workspaceForCode, setRightDrawerIsOpen, setAlert) => {
  const params = {
    page: 0,
    size: 1,
    searchCategory: 'outputid',
    searchCriteria: outputId,
    status: '',
    outputType: '',
    forAction: false
  };
  if (workspaceForCode !== -1) {
    params.workspaceForCode = workspaceForCode;
  }
  return request({ url: searchOutputsEndpoint, params: params })
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      try {
        setRightDrawerIsOpen(data.data[0]);
      } catch {
        throw 'Failed to find outputid on backend';
      }
    })
    .catch(() => {
      setAlert({
        open: true,
        message:
          'There has been an error retrieving data from the database. Please contact the ERA Administrator',
        severity: 'error'
      });
    });
};

export const useGetOutputs = (
  page,
  size,
  forCode,
  sorts,
  searchCategory,
  searchCriteria,
  filters,
  forAction,
  preferredQualityIndicator,
  workspaceForCode
) => {
  const {
    globalAlert: [, setAlert]
  } = useContext(GlobalAppContext);

  const UIfilters =
    typeof preferredQualityIndicator !== 'undefined'
      ? getFilters(forAction, preferredQualityIndicator, workspaceForCode)
      : [];

  return useQuery(
    // NOTE: using JSON.stringify to help eliminate multiple api calls, come back to this when undated to @Mui version 5
    [
      'outputs',
      page,
      size,
      forCode,
      JSON.stringify(sorts),
      searchCategory,
      searchCriteria,
      filters,
      forAction,
      preferredQualityIndicator
    ],
    async () => {
      const newFilters = {
        status: filters.filter((f) =>
          (UIfilters.filter((w) => w.groupname === 'Status')[0]?.options || []).includes(f)
        ),
        outputType: filters.filter((f) =>
          (UIfilters.filter((w) => w.groupname === 'Output type')[0]?.options || []).includes(f)
        ),
        forReviewStatus: filters.filter((f) =>
          (UIfilters.filter((w) => w.groupname === 'For Action')[0]?.options || []).includes(f)
        ),
        qualityIndicator: filters.filter((f) =>
          (
            UIfilters.filter((w) => w.groupname === 'Quality Indicators')[0]?.options || []
          ).includes(f)
        )
      };
      const params = {
        page,
        size,
        searchCategory: searchCategory.join(', '),
        searchCriteria,
        status: newFilters.status?.join(', '),
        outputType: newFilters.outputType?.join(', '),
        qualityIndicator: newFilters.qualityIndicator?.join(', '),
        forReviewStatus: newFilters.forReviewStatus?.join(', '),
        forAction
      };
      if (forCode !== -1) {
        params.workspaceForCode = forCode;
      }

      const sort = sorts[0];
      if (sort) {
        params.sort = `${sort.field},${sort.sort}`;
      } else {
        params.sort = 'pubPk,desc';
      }
      return request({ url: searchOutputsEndpoint, params: params }).then((response) => {
        return response.data;
      });
    },
    {
      enabled: !!forCode,
      onError: () => {
        setAlert(DBErrorAlert);
      }
    }
  );
};

export const useGetOutputComments = (size, id) => {
  const defaultSort = 'created_at,desc';
  const params = {
    page: 0,
    size: size,
    sort: defaultSort
  };
  const {
    globalAlert: [, setAlert]
  } = useContext(GlobalAppContext);

  return useQuery(
    ['comments', id, size],
    async () => {
      return request({ url: `${outputEndpoint}/${id}/comments`, params: params }).then(
        (response) => {
          return response.data;
        }
      );
    },
    {
      enabled: !!id,
      onError: () => {
        setAlert(DBErrorAlert);
      }
    }
  );
};

export const usePostComment = () => {
  const queryClient = useQueryClient();

  const {
    globalAlert: [, setAlert]
  } = useContext(GlobalAppContext);

  return useMutation(
    async ({ outputIds, comment, reasonCode }) => {
      return request({
        url: `${outputsEndpoint}/comments`,
        data: { outputIds, comment, reasonCode },
        method: 'POST'
      }).then((response) => {
        return response.data;
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('comments');
      },
      onError: () => {
        setAlert(DBErrorAlert);
      }
    }
  );
};

const updateFoRAllocation = async (data) => {
  const body = {
    roleId: data.roleId,
    comment: data.comment,
    allocation: data.allocation.map((c) => {
      return {
        outputId: data.id,
        forCode: c.forCode,
        percentage: c.percentage,
        eligible: c.eligible,
        nominate: c.nominate
      };
    })
  };

  const r = {
    url: `${outputEndpoint}/${data.id}/allocation`,
    data: body,
    method: 'PUT'
  };

  const response = await request(r);
  return response;
};

const updateRightPanelIfRequired = (
  rightDrawIsOpen,
  workspaceForCode,
  setRightDrawerIsOpen,
  setAlert
) => {
  if (rightDrawIsOpen === null || typeof rightDrawIsOpen.pubPk === 'undefined') return;
  getSingleOutput(rightDrawIsOpen.pubPk, workspaceForCode, setRightDrawerIsOpen, setAlert);
};

export const useUpdateFoRAllocation = () => {
  const queryClient = useQueryClient();

  const {
    rightDrawer: [rightDrawIsOpen, setRightDrawerIsOpen],
    forCodeSelected: [forCode],
    globalAlert: [, setAlert]
  } = useContext(GlobalAppContext);

  return useMutation(updateFoRAllocation, {
    onSuccess: () => {
      updateRightPanelIfRequired(
        rightDrawIsOpen,
        forCode.ForCode || -1,
        setRightDrawerIsOpen,
        setAlert
      );
      queryClient.invalidateQueries('comments');
      queryClient.invalidateQueries('outputs');
      queryClient.invalidateQueries('assignedForCodes');
    }
  });
};

const submitForReview = async (data) => {
  const body = {
    roleId: data.assumeRole,
    comment: data.comment,
    outputIds: data.outputIds
  };

  const r = {
    url: `${outputsEndpoint}/submit`,
    data: body,
    method: 'POST'
  };

  const response = await request(r);
  return response;
};

export const useSubmitForReview = () => {
  const queryClient = useQueryClient();

  const {
    rightDrawer: [rightDrawIsOpen, setRightDrawerIsOpen],
    forCodeSelected: [forCode],
    globalAlert: [, setAlert]
  } = useContext(GlobalAppContext);

  return useMutation(submitForReview, {
    onSuccess: () => {
      updateRightPanelIfRequired(
        rightDrawIsOpen,
        forCode.ForCode || -1,
        setRightDrawerIsOpen,
        setAlert
      );
      queryClient.invalidateQueries('comments');
      queryClient.invalidateQueries('outputs');
      queryClient.invalidateQueries('assignedForCodes');
    }
  });
};

const approveAllocation = async (data) => {
  const body = {
    roleId: data.assumeRole,
    outputIds: data.outputIds
  };

  const r = {
    url: `${outputsEndpoint}/assent`,
    data: body,
    method: 'POST'
  };

  const response = await request(r);
  return response;
};

export const usePostApproveAllocation = () => {
  const queryClient = useQueryClient();

  const {
    rightDrawer: [rightDrawIsOpen, setRightDrawerIsOpen],
    forCodeSelected: [forCode],
    globalAlert: [, setAlert]
  } = useContext(GlobalAppContext);

  return useMutation(approveAllocation, {
    onSuccess: () => {
      updateRightPanelIfRequired(
        rightDrawIsOpen,
        forCode.ForCode || -1,
        setRightDrawerIsOpen,
        setAlert
      );
      queryClient.invalidateQueries('comments');
      queryClient.invalidateQueries('outputs');
      queryClient.invalidateQueries('assignedForCodes');
    }
  });
};

export const getWorkspaceExport = (forCode) => {
  return request({
    url: `${outputsEndpoint}/export`,
    params: { workspaceForCode: forCode },
    responseType: 'blob'
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));

    let fileName = `${forCode}_${new Date().toJSON().slice(0, 10)}_protected.csv`;

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  });
};

const commit = async (data) => {
  const body = {
    roleId: data.assumeRole,
    outputIds: data.outputIds
  };

  const r = {
    url: `${outputsEndpoint}/commit`,
    data: body,
    method: 'POST'
  };

  const response = await request(r);
  return response;
};

export const usePostCommit = () => {
  const queryClient = useQueryClient();

  const {
    rightDrawer: [rightDrawIsOpen, setRightDrawerIsOpen],
    forCodeSelected: [forCode],
    globalAlert: [, setAlert]
  } = useContext(GlobalAppContext);

  return useMutation(commit, {
    onSuccess: () => {
      updateRightPanelIfRequired(
        rightDrawIsOpen,
        forCode.ForCode || -1,
        setRightDrawerIsOpen,
        setAlert
      );
      queryClient.invalidateQueries('comments');
      queryClient.invalidateQueries('outputs');
      queryClient.invalidateQueries('assignedForCodes');
    }
  });
};

// cached single output retrieval for repetitive calling e.g. comments table
export const useGetCachedSingleOutput = (
  outputId,
  workspaceForCode,
  setAlert,
  staleTime = 3000
) => {
  const params = {
    page: 0,
    size: 1,
    searchCategory: 'outputid',
    searchCriteria: outputId,
    status: '',
    outputType: '',
    forAction: false
  };
  if (workspaceForCode !== -1) {
    params.workspaceForCode = workspaceForCode;
  }

  return useQuery(
    ['single-output', outputId],
    async () => {
      return request({ url: searchOutputsEndpoint, params: params }).then((response) => {
        return response.data;
      });
    },
    {
      staleTime,
      onError: () => {
        setAlert(DBErrorAlert);
      }
    }
  );
};
