import React from 'react';

import Logo from '../../assets/logoNevy.svg';
import { Loader } from './Loader';
import useStyles from './styles';

export const LoadingWithNavBar = () => {
  const classes = useStyles();
  return (
    <div data-testid="loading-with-nav-bar-component">
      <nav className={classes.topBar} data-testid="top-bar">
        <img
          src={Logo}
          width="72"
          height="25"
          alt="RMIT LOGO"
          className={classes.logo}
          data-testid="top-bar-rmit-logo"
        />
        <div data-testid="top-bar-header" className={classes.title}>
          <h4>FoRs for ERA (FERA)</h4>
        </div>
      </nav>
      <div>
        <div className={classes.body}>
          <Loader />
        </div>
      </div>
    </div>
  );
};
