import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { useMutation, useQuery } from 'react-query';

import { GlobalAppContext } from '../store/AppStore';
import { notificationEndpoint, notificationsEndpoint } from './endpoints';
import { getSingleOutput } from './outputs';
import { request } from './request';

const formatLiveNotification = (notification, triggerRightPanel) => {
  switch (notification.title.toLowerCase()) {
    case 'new output':
      return {
        callToAction: {
          action: triggerRightPanel,
          message: 'View Output'
        },
        message: notification.message,
        title: notification.title
      };
    case 'comment':
      return {
        callToAction: {
          action: triggerRightPanel,
          message: 'View Comment'
        },
        message: notification.message,
        title: notification.title,
        reasonCode: notification.reasonCode
      };
    case 'for action':
      return {
        callToAction: {
          action: triggerRightPanel,
          message: 'Review and Approve'
        },
        message: notification.message,
        title: notification.title
      };
    case 'committed':
      return {
        callToAction: {
          action: triggerRightPanel,
          message: 'View Output'
        },
        message: notification.message,
        title: notification.title
      };
    case 'approved':
      return {
        callToAction: {
          action: triggerRightPanel,
          message: 'View Approval'
        },
        message: notification.message,
        title: notification.title
      };
    case 'bulk action':
      return {
        message: notification.message,
        title: notification.title
      };

    default:
      throw new Error('Unhandled notification event type');
  }
};

const deleteNotification = async (id) => {
  const r = {
    url: `${notificationEndpoint}/${id}/ack`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' }
  };
  const response = await request(r);
  return response;
};

const deleteAllNotifications = async (latestId) => {
  const r = {
    url: `${notificationsEndpoint}/ack`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    params: { toNotificationId: latestId }
  };
  const response = await request(r);
  return response;
};

export const useDeleteNotification = (all) => {
  return useMutation(all ? deleteAllNotifications : deleteNotification);
};

export const useNotifications = () => {
  const {
    notifications: [notifications, setNotifications],
    serverNotifyState: [serverNotifyState, setServerNotifyState],
    rightDrawer: [, setRightDrawerIsOpen],
    globalAlert: [, setAlert]
  } = useContext(GlobalAppContext);

  const { enqueueSnackbar } = useSnackbar();

  const params = {
    fromNotificationId: serverNotifyState.lastId === null ? 0 : serverNotifyState.lastId
  };

  return useQuery(
    ['notifications'],
    async () => {
      return request({ url: notificationsEndpoint, params: params }).then((response) => {
        return response.data?.data || [];
      });
    },
    {
      retry: true,
      onSuccess: (data) => {
        // Push to global notifications context
        if (serverNotifyState.lastId === null) {
          // Full reload if pulling from start
          setNotifications(
            data.map((n) => {
              return {
                ...n,
                live: serverNotifyState.live,
                format: formatLiveNotification(
                  n,
                  () => {
                    getSingleOutput(n.outputId, n.forCode, setRightDrawerIsOpen, setAlert);
                  },
                  history
                )
              };
            })
          );
        } else {
          // Append but deduplicate if coming from live
          setNotifications(
            data.reduce((acc, current) => {
              const x = acc.find((item) => item.id === current.id);
              if (!x) {
                const notification = {
                  ...current,
                  live: true,
                  format: formatLiveNotification(
                    current,
                    () => {
                      getSingleOutput(
                        current.outputId,
                        current.forCode,
                        setRightDrawerIsOpen,
                        setAlert
                      );
                    },
                    history
                  )
                };
                enqueueSnackbar(notification, {
                  persist: true,
                  preventDuplicate: true,
                  key: current.id
                });

                return [notification].concat(acc);
              } else {
                return acc;
              }
            }, notifications)
          );
        }

        // if no new notificaiton, don't call the Math.max as it would return -Infinity with empty list otherwise
        if (data.length !== 0) {
          const latestId = Math.max.apply(
            Math,
            data.map(function (o) {
              return o.id;
            })
          );
          setServerNotifyState({ live: true, lastId: latestId });
        } else if (serverNotifyState.lastId === null) {
          // we have got no notification and the initial load is complete. lastId with null represents first time after login.
          setServerNotifyState({ live: true, lastId: 0 });
        }
      }
      //   onError: ()=>{
      //      NOTE: Need to discuss what handling errors for this could look like? Trigger a modal?
      //   }
    }
  );
};
