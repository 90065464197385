import React from 'react';

import { ApproveAllocationModal } from '../components/Modals/ApproveAllocationModal';
import { CommentModalButton } from '../components/Modals/CommentModal';
import { CommitModal } from '../components/Modals/CommitModal';
import { EditFoRModalButton } from '../components/Modals/EditFoRModal';
import { getAssumedRole, SubmitReviewModalButton } from '../components/Modals/SubmitReviewModal';

export const getUserRolePermission = (
  mainFor,
  twoDigitMainFor,
  otherForCodes,
  userRoles,
  forCodeViewing,
  pubPk,
  authUser,
  forCode
) => {
  //respond
  let res = {
    digit: null,
    forCode: null,
    assumeRole: getAssumedRole(authUser, forCode),
    isPrimaryOwner: false,
    outputIds: [pubPk]
  };

  const location = window.location.pathname;

  if (location === '/workspace') {
    return checkForCodeUserRoles(
      mainFor,
      twoDigitMainFor,
      otherForCodes,
      userRoles,
      forCodeViewing,
      pubPk,
      res.assumeRole
    );
  }

  // if user is admin or uni lead
  for (
    let i = 0;
    res.digit === null && userRoles && userRoles.length > 0 && i < userRoles.length;
    i++
  ) {
    if (userRoles[i].roleName === 'ADMINISTRATOR' || userRoles[i].roleName === 'UNIVERSITY_LEAD') {
      res.digit = 'ADMINISTRATOR_or_UNIVERSITY_LEAD';
      res.isPrimaryOwner = true;
      res.assumeRole = userRoles[i].id;
    }
  }

  // Not in workspace
  if (res.digit === null) {
    return checkForCodeUserRoles(mainFor, twoDigitMainFor, otherForCodes, userRoles, null, pubPk);
  }

  return res;
};

export const checkForCodeUserRoles = (
  mainFor,
  twoDigitMainFor,
  otherForCodes,
  userRoles,
  forCodeViewing,
  pubPk,
  assumedRole
) => {
  let res = {
    digit: null,
    forCode: null,
    assumeRole: assumedRole,
    isPrimaryOwner: false,
    outputIds: [pubPk]
  };

  //const mainFor2Digit = mainFor?.substr(0, 2);
  const mainFor2Digit = twoDigitMainFor;
  const array = otherForCodes?.split(', ');
  const other2Digit = [];
  const other4Digit = [];
  // get all non-primary owner 2 and 4 digit codes
  for (let i = 0; array && i < array.length; i++) {
    // for 2-digit
    other2Digit.push(array[i].substr(0, 2));
    // for 4-digit
    other4Digit.push(array[i]);
  }

  const codesUserOwn2Digit = [];
  const codesUserOwn4Digit = [];

  //if Given forCodeViewing, check if user is currently viewing their primary owner workspace
  for (
    let i = 0;
    forCodeViewing !== null && userRoles && userRoles.length > 0 && i < userRoles.length;
    i++
  ) {
    for (
      let j = 0;
      res.digit === null &&
      userRoles[i].rolename !== 'ADMINISTRATOR' &&
      userRoles[i].rolename !== 'UNIVERSITY_LEAD' &&
      j < userRoles[i].userRoleForCodes.length;
      j++
    ) {
      if (userRoles[i].userRoleForCodes[j].isPrimaryOwner) {
        let currentForCode = userRoles[i].userRoleForCodes[j].forCode;
        if (currentForCode === forCodeViewing && currentForCode.length === 2) {
          res.digit = 'TWO_DIGITS_ADVISOR';
          res.forCode = currentForCode;
          res.isPrimaryOwner = currentForCode === mainFor2Digit;
          return res;
        }
        if (currentForCode === forCodeViewing && currentForCode.length === 4) {
          res.digit = 'FOUR_DIGITS_ADVISOR';
          res.forCode = currentForCode;
          res.isPrimaryOwner = currentForCode === mainFor;
          return res;
        }
      }
    }
  }

  // If doesn't have mainFor code and currently viewing from primary owner view
  // This is to get all the codes that current user is a primary owner of and check 2-digit permission
  for (let i = 0; userRoles && userRoles.length > 0 && i < userRoles.length; i++) {
    for (
      let j = 0;
      userRoles[i].rolename !== 'ADMINISTRATOR' &&
      userRoles[i].rolename !== 'UNIVERSITY_LEAD' &&
      j < userRoles[i].userRoleForCodes.length;
      j++
    ) {
      if (userRoles[i].userRoleForCodes[j].isPrimaryOwner) {
        let currentForCode = userRoles[i].userRoleForCodes[j].forCode;
        // Not in workspace
        if (currentForCode === mainFor2Digit && forCodeViewing === null) {
          res.digit = 'TWO_DIGITS_ADVISOR';
          res.forCode = mainFor2Digit;
          res.isPrimaryOwner = true;
          res.assumeRole = userRoles[i].id;
        } else if (currentForCode.length === 2) {
          codesUserOwn2Digit.push(currentForCode);
        } else if (currentForCode.length === 4 && currentForCode !== mainFor) {
          codesUserOwn4Digit.push(currentForCode);
        }
      }
    }
  }

  // Not in workspace
  // This is to check permission if 4-digit primary owner
  for (
    let i = 0;
    forCodeViewing === null && userRoles && userRoles.length > 0 && i < userRoles.length;
    i++
  ) {
    for (
      let j = 0;
      res.digit === null &&
      userRoles[i].rolename !== 'ADMINISTRATOR' &&
      userRoles[i].rolename !== 'UNIVERSITY_LEAD' &&
      j < userRoles[i].userRoleForCodes.length;
      j++
    ) {
      if (userRoles[i].userRoleForCodes[j].isPrimaryOwner) {
        let currentForCode = userRoles[i].userRoleForCodes[j].forCode;
        if (currentForCode === mainFor) {
          res.digit = 'FOUR_DIGITS_ADVISOR';
          res.forCode = mainFor;
          res.isPrimaryOwner = true;
          res.assumeRole = userRoles[i].id;
        }
      }
    }
  }

  // No codes under primary owner lists at all
  if (res.digit === null && codesUserOwn2Digit.length === 0 && codesUserOwn4Digit.length === 0) {
    return res;
  }

  // If doesn't have mainFor code and currently viewing from non-primary owner view
  // check if otherForCodes is in the codesUserOwn2Digit
  for (let i = 0; res.digit === null && res.digit === null && i < codesUserOwn2Digit.length; i++) {
    for (let j = 0; res.digit === null && j < other2Digit.length; j++) {
      if (other2Digit[j] === codesUserOwn2Digit[i]) {
        // note: no need to set assumed role as non-primary owner won't be able to approve any allocation
        res.digit = 'TWO_DIGITS_ADVISOR';
        res.forCode = other2Digit[j];
        res.isPrimaryOwner = false;
      }
    }
  }

  // If doesn't have mainFor code and currently viewing from non-primary owner view
  // check if otherForCodes is in the codesUserOwn4Digit
  for (let i = 0; res.digit === null && res.digit === null && i < codesUserOwn4Digit.length; i++) {
    for (let j = 0; res.digit === null && j < other4Digit.length; j++) {
      if (other4Digit[j] === codesUserOwn4Digit[i]) {
        // note: no need to set assumed role as non-primary owner won't be able to approve any allocation
        res.digit = 'FOUR_DIGITS_ADVISOR';
        res.forCode = other4Digit[j];
        res.isPrimaryOwner = false;
      }
    }
  }

  return res;
};

export const isAllApproved = (outputAllocations, mainFor, twoDigitMainFor) => {
  let res = true;
  const forCode = [];
  //const mainFor2Digit = mainFor?.substr(0, 2);
  const mainFor2Digit = twoDigitMainFor;

  for (let i = 0; outputAllocations && i < outputAllocations.length; i++) {
    if (outputAllocations[i].twoDigitsApproval === false) {
      forCode.push(outputAllocations[i].forCode.substr(0, 2));
    }
    if (outputAllocations[i].fourDigitsApproval === false) {
      res = false;
    }
  }

  // check iff unapproved are mainFor 2-digit primary owner
  for (let i = 0; res && forCode && i < forCode.length; i++) {
    if (forCode[i] !== mainFor2Digit) {
      res = false;
    }
  }

  return res;
};

export const isApproved = (outputAllocations, permission) => {
  let res = false;
  for (let i = 0; outputAllocations && i < outputAllocations.length; i++) {
    if (
      permission.digit === 'TWO_DIGITS_ADVISOR' &&
      permission.forCode === outputAllocations[i].forCode.substr(0, 2)
    ) {
      res = outputAllocations[i].twoDigitsApproval;
      i = outputAllocations.length;
    }
    if (
      permission.digit === 'FOUR_DIGITS_ADVISOR' &&
      permission.forCode === outputAllocations[i].forCode.substr(0, 4)
    ) {
      res = outputAllocations[i].fourDigitsApproval;
      i = outputAllocations.length;
    }
  }
  return res;
};

export const getUserActionButtons = (status, userRolePermission, isApproved, isAllApproved) => {
  const isPrimaryOwner = userRolePermission.isPrimaryOwner;
  const digit = userRolePermission.digit;

  if (status === 'Committed') {
    return (
      <>
        <EditFoRModalButton
          data-testid="edit-for-modal"
          isDisabled={digit === 'ADMINISTRATOR_or_UNIVERSITY_LEAD' ? false : true}
        />
        <SubmitReviewModalButton data-testid="submit-for-review-modal" isDisabled={true} />
        <CommentModalButton data-testid="comment-modal" />
      </>
    );
  }

  // This is the default case when the user isn't related to assign FoR allocation section viewing output details
  if (digit === null) {
    return (
      <>
        <EditFoRModalButton data-testid="edit-for-modal" isDisabled={true} />
        <SubmitReviewModalButton data-testid="submit-for-review-modal" isDisabled={true} />
        <CommentModalButton data-testid="comment-modal" />
      </>
    );
  }

  if (status === 'Open' || status === 'Modified') {
    return (
      <>
        <EditFoRModalButton data-testid="edit-for-modal" isDisabled={!isPrimaryOwner} />
        <SubmitReviewModalButton
          data-testid="submit-for-review-modal"
          isDisabled={!isPrimaryOwner}
        />
        <CommentModalButton />
      </>
    );
  }

  // For Review but not approved by the current user
  if (status === 'For Review' && !isApproved) {
    return (
      <>
        <EditFoRModalButton data-testid="edit-for-modal" isDisabled={!isPrimaryOwner} />
        <ApproveAllocationModal
          data-testid="approve-allocation-modal"
          {...userRolePermission}
          isDisabled={digit === 'ADMINISTRATOR_or_UNIVERSITY_LEAD' ? true : isApproved}
        />
        <CommentModalButton />
      </>
    );
  }

  // For Review
  return (
    <>
      <EditFoRModalButton data-testid="edit-for-modal" isDisabled={!isPrimaryOwner} />
      {isPrimaryOwner && digit === 'TWO_DIGITS_ADVISOR' ? (
        <CommitModal
          data-testid="commit-modal"
          isDisabled={!isAllApproved}
          {...userRolePermission}
        />
      ) : (
        <ApproveAllocationModal
          data-testid="approve-allocation-modal"
          {...userRolePermission}
          isDisabled={digit === 'ADMINISTRATOR_or_UNIVERSITY_LEAD' ? true : isApproved}
        />
      )}
      <CommentModalButton data-testid="comment-modal" />
    </>
  );
};

export const getWarningText = (state, isApproved, isAllApproved, userRolePermission) => {
  const isPrimaryOwner = userRolePermission.isPrimaryOwner;
  const digit = userRolePermission.digit;
  if (state === 'Committed') {
    return 'This output is committed for ERA';
  }
  // This is the case when not related user view an output
  if (digit === null) {
    return "You don't have permission to edit codes. Make a comment to request a change.";
  }
  if (isPrimaryOwner && (state === 'Open' || state === 'Modified')) {
    return null;
  }
  if (!isPrimaryOwner && (state === 'Open' || state === 'Modified')) {
    return "You don't have permission to edit codes. Make a comment to request a change.";
  }
  if (state === 'For Review' && isPrimaryOwner && isAllApproved && digit === 'TWO_DIGITS_ADVISOR') {
    return 'This output is ready to be committed';
  }
  if (
    state === 'For Review' &&
    isPrimaryOwner &&
    !isAllApproved &&
    digit === 'TWO_DIGITS_ADVISOR'
  ) {
    return 'This output can be committed when the advisors for the codes above have approved';
  }
  if (state === 'For Review' && isPrimaryOwner && isApproved && digit === 'FOUR_DIGITS_ADVISOR') {
    return 'You have approved your code’s allocation to this output.';
  }
  if (state === 'For Review' && isPrimaryOwner && !isApproved && digit === 'FOUR_DIGITS_ADVISOR') {
    return 'This output has been submitted with your code allocated. Please approve below';
  }
  if (state === 'For Review' && !isPrimaryOwner && isApproved) {
    return 'You have approved your code’s allocation to this output.';
  }
  if (state === 'For Review' && !isPrimaryOwner && !isApproved) {
    return 'This output has been submitted with your code allocated. Please approve below';
  }

  return null;
};
