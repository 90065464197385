import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  DialogActions,
  DialogContentText,
  FormControlLabel,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { useSubmitForReview } from '../../api/outputs';
import { GlobalAppContext } from '../../store/AppStore';
import { AuthContext } from '../../store/AuthStore';
import { FERAButton } from '../Button/FERAButton';
import GenericDialog from '../Dialog';
import { StatusCell } from '../Table/DataTableHelpers';
import { EditFoRModal, padEnd } from './EditFoRModal';
import useStyles from './styles';

export const WarningModal = ({ onClose, hideWarning }) => {
  const [EditFoRModalVisible, setEditFoRModalVisible] = useState(false);
  if (EditFoRModalVisible)
    return (
      <EditFoRModal
        onClose={() => {
          setEditFoRModalVisible(false);
          hideWarning();
        }}
      />
    );
  return (
    <GenericDialog
      height={750}
      title="Allocate codes to this output"
      onClose={onClose}
      data-testid="edit-for-modal-warning">
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <InfoOutlinedIcon sx={{ fontSize: 67, mt: 2.5 }} />
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ pb: 0.5 }}>
            Codes do not total 100% allocation
          </Typography>
        </Grid>
        <Grid item>
          <DialogContentText sx={{ maxWidth: 265, textAlign: 'center', pb: 3, m: 0 }}>
            Please reallocate percentage
          </DialogContentText>
        </Grid>
        <Grid item>
          <DialogActions>
            <FERAButton
              onClick={() => {
                setEditFoRModalVisible(true);
              }}
              color="red"
              variant="contained"
              aria-label="Go back to FoR allocation dialog">
              Edit FoR Codes
            </FERAButton>
            <FERAButton
              variant="outlined"
              onClick={onClose}
              data-testid="modal-modal-cancel-button"
              color="red"
              aria-label="Close warning dialog">
              Cancel
            </FERAButton>
          </DialogActions>
        </Grid>
      </Grid>
    </GenericDialog>
  );
};

WarningModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  hideWarning: PropTypes.func.isRequired
};

export const ConfirmModal = ({ onSubmit, onClose, onBack }) => {
  const {
    rightDrawer: [rightDrawerIsOpen]
  } = useContext(GlobalAppContext);
  const forCodes = padEnd(rightDrawerIsOpen?.outputAllocations || [], 3, {}).filter(
    (c) => typeof c.forCode !== 'undefined'
  );

  return (
    <GenericDialog
      height={750}
      title="Submit for Review"
      onClose={onClose}
      data-testid="submit-for-review-modal-confirmation">
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <InfoOutlinedIcon sx={{ fontSize: 67, mt: 2.5 }} />
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ mb: 2.5, fontFamily: 'Museo' }}>
            Please Confirm
          </Typography>
        </Grid>
        <Grid item>
          <DialogContentText sx={{ maxWidth: 416, textAlign: 'center', pb: 1, m: 0 }}>
            This output will be submitted for final approval.
          </DialogContentText>
          <DialogContentText sx={{ maxWidth: 416, textAlign: 'center', pb: 3, m: 0 }}>
            The 2-digit and 4-digit advisors from the following codes will need to approve this
            allocation:
          </DialogContentText>
        </Grid>
        <Grid item>
          {forCodes.map((c, index) => {
            return (
              <DialogContentText
                key={index}
                sx={{ maxWidth: 416, textAlign: 'center', pb: 1, m: 0 }}>
                {`${c.forCode} - ${c.forName}`}
              </DialogContentText>
            );
          })}
        </Grid>
        <Grid item>
          <DialogContentText sx={{ maxWidth: 416, textAlign: 'center', pb: 3, pt: 3, m: 0 }}>
            You can view the status of this output at anytime.{' '}
          </DialogContentText>
        </Grid>
        <Grid item>
          <DialogActions>
            <FERAButton
              onClick={onSubmit}
              color="red"
              variant="contained"
              aria-label="Confirm and submit for output for review"
              loadingPosition="start"
              startIcon={<SaveIcon />}>
              Confirm & Submit
            </FERAButton>
            <FERAButton
              variant="outlined"
              onClick={onBack}
              data-testid="modal-modal-cancel-button"
              color="red"
              aria-label="Close confirmation dialog">
              Back
            </FERAButton>
          </DialogActions>
        </Grid>
      </Grid>
    </GenericDialog>
  );
};

ConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export const getAssumedRole = (authUser, forCode) => {
  const roleMap = {};
  const presidence = [
    'ADMINISTRATOR',
    'UNIVERSITY_LEAD',
    'TWO_DIGITS_ADVISOR',
    'FOUR_DIGITS_ADVISOR'
  ];
  authUser.userInformation.userRoles.forEach((role) => {
    roleMap[presidence.indexOf(role.roleName)] = role.id;
  });
  const workspaceForCode = window.location.pathname === '/outputs' ? '' : forCode?.FoRCode || '';
  const assumedRole =
    workspaceForCode === ''
      ? Object.values(roleMap)[0]
      : workspaceForCode.length === 2
      ? roleMap[2]
      : roleMap[3];
  return assumedRole;
};

export const SubmitReviewModal = ({ onClose }) => {
  const classes = useStyles();

  const { authUser } = useContext(AuthContext);
  const {
    forCodeSelected: [forCode],
    globalAlert: [, setAlert],
    rightDrawer: [rightDrawerIsOpen]
  } = useContext(GlobalAppContext);

  const forCodes = padEnd(rightDrawerIsOpen?.outputAllocations || [], 3, {});
  const status = rightDrawerIsOpen.status;
  const nominateFor30 = forCodes.filter((c) => c.nominate).length > 0;
  const isEligibleForERA = forCodes.filter((c) => c.eligible).length > 0;
  const totalAllocated = forCodes
    .filter((c) => typeof c.forCode !== 'undefined')
    .reduce((accum, obj) => accum + (obj.percentage || 0), 0);

  const [comment, setComment] = useState('Output submitted for Review');
  const [commentErrorVisible, setCommentErrorVisible] = useState(false);
  const [warningVisible, setWarningVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);

  const { mutate: submitForReview } = useSubmitForReview();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const assumedRole = getAssumedRole(authUser, forCode);

  const onSubmit = () => {
    setIsSubmitting(true);
    submitForReview(
      {
        assumeRole: assumedRole,
        comment: comment,
        outputIds: [rightDrawerIsOpen.pubPk]
      },
      {
        onSuccess: (data) => {
          if (data.data.failedIds.length !== 0) {
            setAlert({
              open: true,
              message: 'There has been an error occurred',
              severity: 'error'
            });
          } else {
            setAlert({
              open: true,
              message: 'Output has been successfully updated',
              severity: 'success'
            });
          }
          onClose();
          setIsSubmitting(false);
        },
        onError: () => {
          setAlert({
            open: true,
            message: `Failed to update FoR Allocation`,
            severity: 'error'
          });
          setIsSubmitting(false);
        }
      }
    );
  };

  if (warningVisible)
    return (
      <WarningModal
        onClose={onClose}
        hideWarning={() => {
          setWarningVisible(false);
        }}
      />
    );

  if (confirmVisible)
    return (
      <ConfirmModal
        onSubmit={() => {
          setConfirmVisible(false);
          onSubmit();
        }}
        onClose={onClose}
        onBack={() => {
          setConfirmVisible(false);
        }}
      />
    );

  return (
    <GenericDialog
      title="Submit for Review"
      onClose={onClose}
      data-testid="submit-for-review-modal">
      <Box className={classes.modalContent}>
        <div data-testid="for-allocation-header" className={classes.allocationHeader}>
          <Typography varaint="h6" style={{ fontWeight: 700 }}>
            FoR Allocation
          </Typography>
          <StatusCell
            status={status}
            className={classes.allocationBadge}
            data-testid="for-allocation-status"
          />
        </div>
        <div data-testid="for-allocation-percentage" className={classes.forStaticTable}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.forCodeCol}>
                  <Typography>FoR Code</Typography>
                </TableCell>
                <TableCell>%</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {forCodes.map((c, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell className={classes.forCodeCol}>
                      {typeof c.forCode === 'undefined' ? '-' : `${c.forCode} - ${c.forName}`}
                    </TableCell>
                    <TableCell>
                      {typeof c.percentage === 'undefined' ? '-' : c.percentage}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <div data-testid="switches" className={classes.submitReviewStaticSwitches}>
          <FormControlLabel
            control={
              <Switch
                disabled
                color="error"
                checked={isEligibleForERA}
                className={classes.switch}
                data-testid="is-eligible-for-era-switch"
              />
            }
            label="Eligible for ERA?"
          />
          <FormControlLabel
            control={
              <Switch
                disabled
                color="error"
                checked={nominateFor30}
                className={classes.switch}
                data-testid="nominate-for-30-percent-switch"
              />
            }
            label="Nominate for Top 30%"
          />
        </div>

        <Typography id="modal-modal-description" data-testid="modal-modal-description">
          Comments<span className={classes.requiredStar}>*</span>
        </Typography>
        <TextField
          className={classes.textField}
          variant="outlined"
          multiline
          rows={5}
          value={comment}
          placeholder="Add your comment here"
          onChange={(e) => {
            setComment(e.target.value);
            if (comment.length === 0) {
              setCommentErrorVisible(true);
            } else {
              setCommentErrorVisible(false);
            }
          }}
          error={commentErrorVisible}
          inputProps={{ minLength: 1, maxLength: 256 }}
          data-testid="modal-modal-comment"
          sx={{ marginBottom: 28 / 8 }}
        />

        <Box className={classes.buttons}>
          <FERAButton
            data-testid={isSubmitting ? 'modal-modal-loading-button' : 'modal-modal-submit-button'}
            onClick={() => {
              if (comment.length === 0) {
                setCommentErrorVisible(true);
                return;
              }
              if (totalAllocated !== 100) {
                setWarningVisible(true);
              } else {
                setConfirmVisible(true);
              }
            }}
            disabled={comment.length === 0}
            color="red"
            variant="contained"
            aria-label="Submit a comment"
            sx={{ marginRight: 2 }}
            loadingPosition="start"
            startIcon={<SaveIcon />}>
            Submit for Review
          </FERAButton>
          <FERAButton
            variant="outlined"
            onClick={onClose}
            data-testid="modal-modal-cancel-button"
            color="red">
            Cancel
          </FERAButton>
        </Box>
      </Box>
    </GenericDialog>
  );
};

SubmitReviewModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export const SubmitReviewModalButton = ({ isDisabled }) => {
  const [open, setOpen] = useState(false);

  return (
    <div data-testid="submit-for-review-component">
      <FERAButton
        variant="contained"
        onClick={() => setOpen(true)}
        color="purple"
        disabled={isDisabled}
        data-testid="submit-for-review-open-modal-button">
        Submit for Review
      </FERAButton>
      {open && (
        <SubmitReviewModal
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
    </div>
  );
};

SubmitReviewModalButton.propTypes = {
  isDisabled: PropTypes.bool
};
