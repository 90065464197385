import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { useGetCitations, useGetQualityIndicators } from '../../../api/preferences';
import useStyles from './styles';

const Preferences = ({
  qualityIndicatorPreference,
  setQualityIndicatorPreference,
  citationPreference,
  setCitationPreference,
  sx
}) => {
  const classes = useStyles();

  const { data: citationsPreferenceList, isSuccess: citationListIsLoaded } = useGetCitations();
  const { data: qualityIndicatorPreferenceList, isSuccess: qualityIndicatorListIsLoaded } =
    useGetQualityIndicators();

  return (
    citationListIsLoaded &&
    qualityIndicatorListIsLoaded && (
      <Grid container sx={sx}>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h1"
                className={classes.headerTitlePrimary}
                data-testid="page-header-title">
                Journal/Conference Ranking
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="Journal/Conference Ranking"
                  value={
                    qualityIndicatorPreference?.id || qualityIndicatorPreferenceList.data[0].id
                  }
                  name="radio-buttons-group"
                  onChange={(event) => {
                    setQualityIndicatorPreference({
                      id: parseInt(event.target.value),
                      name: qualityIndicatorPreferenceList.data.filter(
                        (pref) => pref.id === parseInt(event.target.value)
                      )[0].name
                    });
                  }}>
                  {qualityIndicatorPreferenceList.data.map((qi, index) => (
                    <FormControlLabel
                      key={index}
                      value={qi.id}
                      control={
                        <Radio className={classes.radio} data-testid="preference-qi-radio" />
                      }
                      label={qi.name}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h1"
                className={classes.headerTitlePrimary}
                data-testid="page-header-title">
                Output Quality Indicator
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="Preferred Citation"
                  value={citationPreference?.id || citationsPreferenceList.data[0].id}
                  name="radio-buttons-group"
                  onChange={(event) => {
                    setCitationPreference({
                      id: parseInt(event.target.value),
                      name: citationsPreferenceList.data.filter(
                        (pref) => pref.id === parseInt(event.target.value)
                      )[0].name
                    });
                  }}>
                  {citationsPreferenceList.data.map((cit, index) => (
                    <FormControlLabel
                      key={index}
                      value={cit.id}
                      control={
                        <Radio className={classes.radio} data-testid="preference-cit-radio" />
                      }
                      label={cit.name}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  );
};

const preferenceShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
});

Preferences.propTypes = {
  qualityIndicatorPreference: preferenceShape,
  setQualityIndicatorPreference: PropTypes.func.isRequired,
  citationPreference: preferenceShape,
  setCitationPreference: PropTypes.func.isRequired,
  sx: PropTypes.object
};

export default Preferences;
