import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SaveIcon from '@mui/icons-material/Save';
import { DialogActions, DialogContentText, Grid, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { useSubmitForReview } from '../../api/outputs';
import { GlobalAppContext } from '../../store/AppStore';
import { AuthContext } from '../../store/AuthStore';
import { FERAButton } from '../Button/FERAButton';
import GenericDialog from '../Dialog';
import { BulkConfirmationModal } from './BulkConfirmationModal';
import useStyles from './styles';
import { getAssumedRole } from './SubmitReviewModal';

export const SubmitForReviewBulkModal = ({ onClose }) => {
  const classes = useStyles();
  const {
    forCodeSelected: [forCode],
    pubPkSelection: [selectionModel, setSelectionModel],
    globalAlert: [, setAlert]
  } = useContext(GlobalAppContext);
  const { authUser } = useContext(AuthContext);

  const outputIds = selectionModel;
  const assumedRole = getAssumedRole(authUser, forCode);

  const [comment, setComment] = useState('Output submitted for Review');
  const [commentErrorVisible, setCommentErrorVisible] = useState(false);
  const [confirmationModalInfo, setConfirmationModalInfo] = useState({
    failed: [],
    succeeded: []
  });

  const { mutate: submitForReview } = useSubmitForReview();

  const onSubmit = () => {
    if (comment.length === 0) {
      setCommentErrorVisible(true);
      return;
    }

    submitForReview(
      { assumeRole: assumedRole, comment: comment, outputIds: outputIds },
      {
        onSuccess: (data) => {
          if (data.data.failedIds.length === 0) {
            setAlert({
              open: true,
              message: `Output${outputIds.length > 1 ? 's' : ''} ${
                outputIds.length > 1 ? 'have' : 'has'
              } been successfully updated`,
              severity: 'success'
            });
            onClose();
          }
          setSelectionModel(data.data.failedIds);
          setConfirmationModalInfo({
            failed: data.data.failedIds,
            succeeded: data.data.successfulIds
          });
        }
      }
    );
  };

  if (confirmationModalInfo.failed.length > 0)
    return (
      <BulkConfirmationModal
        title="Submit for Review"
        onClose={onClose}
        successMessage={`We were able to submit ${confirmationModalInfo.succeeded.length} of the requested output for review. The remaining
      outputs were unable to be submitted`}
      />
    );

  return (
    <GenericDialog
      height={750}
      title="Submit for Review"
      onClose={onClose}
      data-testid="submit-for-review-modal-confirmation">
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <InfoOutlinedIcon sx={{ fontSize: 67, mt: 2.5 }} />
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ mb: 2.5, fontFamily: 'Museo' }}>
            Please Confirm.
          </Typography>
        </Grid>
        <Grid item>
          <DialogContentText
            data-testid="submit-for-review-modal-summary"
            sx={{ maxWidth: 416, textAlign: 'center', pb: 1, m: 0 }}>
            You are submitting {outputIds.length} output{outputIds.length > 1 && 's'} for Final
            Review. All 2-digit and 4-digit advisors with codes associated with{' '}
            {outputIds.length > 1 ? 'these' : 'this'} output{outputIds.length > 1 && 's'} will be
            notified
          </DialogContentText>
        </Grid>
        <br />
        <br />
        <div>
          <Typography id="modal-modal-description" data-testid="modal-modal-description">
            {outputIds.length > 1 ? 'Comment applied to all outputs' : 'Comment'}
            <span className={classes.requiredStar}>*</span>
          </Typography>
          <TextField
            className={classes.textField}
            variant="outlined"
            multiline
            rows={5}
            value={comment}
            placeholder="Add your comment here"
            onChange={(e) => {
              setComment(e.target.value);
              if (comment.length === 0) {
                setCommentErrorVisible(true);
              } else {
                setCommentErrorVisible(false);
              }
            }}
            error={commentErrorVisible}
            inputProps={{ minLength: 1, maxLength: 256 }}
            data-testid="modal-modal-comment"
            sx={{ marginBottom: 28 / 8 }}
          />
        </div>

        <Grid item>
          <DialogActions>
            <FERAButton
              onClick={onSubmit}
              color="red"
              variant="contained"
              aria-label="Confirm and submit all outputs for review"
              loadingPosition="start"
              startIcon={<SaveIcon />}>
              Submit for Review
            </FERAButton>
            <FERAButton
              variant="outlined"
              onClick={onClose}
              data-testid="modal-modal-cancel-button"
              color="red"
              aria-label="Close confirmation dialog">
              Cancel
            </FERAButton>
          </DialogActions>
        </Grid>
      </Grid>
    </GenericDialog>
  );
};

SubmitForReviewBulkModal.propTypes = {
  onClose: PropTypes.func.isRequired
};
