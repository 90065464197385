import makeStyles from '@mui/styles/makeStyles';

import { colours } from '../../theme/colors';

const useStyles = makeStyles(() => ({
  dropdown: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'unset'
    }
  },
  mailtoText: {
    color: colours.black,
    letterSpacing: 0.15,
    fontSize: 14,
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: 'normal',
    display: 'inline'
  },
  container: {
    maxWidth: 1513,
    minWidth: 1248,
    margin: '0 auto',
    paddingTop: 31
  },
  dataGrid: {
    '& .MuiDataGrid-columnSeparator': {
      visibility: 'hidden'
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0
    },
    '&.MuiDataGrid-root': {
      border: 0
    },
    '& .MuiDataGrid-columnHeaders': {
      borderLeft: `5px solid transparent`
    },
    color: colours.textPrimary,
    backgroundColor: colours.white,
    overflowY: 'hidden',
    height: 800,
    '& .MuiDataGrid-row': {
      '&:hover': {
        backgroundColor: colours.grey,
        borderLeft: `5px solid ${colours.yellow}`
      },
      borderLeft: `5px solid ${colours.white}`
    },
    paddingBottom: 0
  }
}));

export default useStyles;
