import React from 'react';

import { NotFoundComponent } from '../components/NotFound/NotFoundComponent';
import { DefaultLayout } from '../layouts/DefaultLayout/DefaultLayout';
import { ViewWrapper } from './ViewWrapper';

export const NotFoundView = () => {
  return (
    <ViewWrapper>
      <DefaultLayout>
        <NotFoundComponent />
      </DefaultLayout>
    </ViewWrapper>
  );
};
