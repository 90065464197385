import { Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import { colours } from '../../../theme/colors';

export const StyledTooltip = withStyles({
  arrow: {
    color: colours.primaryBlue
  },
  tooltip: {
    backgroundColor: colours.primaryBlue,
    top: -10
  }
})(Tooltip);

const useStyles = makeStyles(() => ({
  editIcon: {
    visibility: 'hidden',
    marginTop: 20,
    cursor: 'pointer',
    '&:hover': {
      color: colours.primaryGrey
    },
    padding: 50
  },
  dataGrid: {
    '& .MuiDataGrid-columnSeparator': {
      visibility: 'hidden'
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0
    },
    '& .MuiDataGrid-columnsContainer': {
      padding: 0
    },
    '&.MuiDataGrid-root': {
      border: 0
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
      {
        outline: 'none'
      },
    color: colours.textPrimary,
    backgroundColor: colours.white,
    overflowY: 'hidden',
    height: 800,
    '& .MuiDataGrid-columnHeader': {
      borderLeft: `5px solid transparent`
    },
    '& .MuiDataGrid-row': {
      '&:hover': {
        '& .MuiDataGrid-cell--withRenderer .region svg': {
          visibility: 'visible'
        },
        backgroundColor: colours.grey,
        borderLeft: `5px solid ${colours.yellow}`
      },
      borderLeft: `5px solid transparent`
    }
  },
  badge: {
    '& .MuiBadge-badge': {
      position: 'inherit',
      fontFamily: 'Arial',
      fontSize: 12,
      fontWeight: 'bold',
      lineHeight: 12,
      letterSpacing: 0.14
    },
    '& .MuiBadge-anchorOriginTopRightRectangular': {
      transform: 'none'
    }
  },
  badgeContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'left'
  },
  badgeGreen: {
    '& .MuiBadge-colorPrimary': {
      color: colours.white,
      backgroundColor: colours.successGreen
    }
  },
  badgeGrey: {
    '& .MuiBadge-colorPrimary': {
      color: colours.primaryBlue,
      backgroundColor: colours.e0
    }
  }
}));

export default useStyles;
