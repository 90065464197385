import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DialogActions, DialogContentText, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { FERAButton } from '../Button/FERAButton';
import GenericDialog from '../Dialog';

export const BulkConfirmationModal = ({ title, successMessage, onClose }) => {
  return (
    <GenericDialog
      height={750}
      title={title}
      onClose={onClose}
      data-testid="bulk-confirmation-modal-confirmation">
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <InfoOutlinedIcon sx={{ fontSize: 67, mt: 2.5 }} />
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ mb: 2.5 }}>
            Confirmation
          </Typography>
        </Grid>
        <Grid item>
          <DialogContentText sx={{ maxWidth: 416, textAlign: 'center', pb: 1, m: 0 }}>
            {successMessage}
          </DialogContentText>
          <DialogContentText sx={{ maxWidth: 416, textAlign: 'center', pb: 3, m: 0 }}>
            The failed outputs are still selected for your attention
          </DialogContentText>
        </Grid>

        <Grid item>
          <DialogActions>
            <FERAButton
              variant="outlined"
              color="red"
              onClick={onClose}
              data-testid="modal-modal-cancel-button"
              aria-label="Close confirmation dialog">
              Close Window
            </FERAButton>
          </DialogActions>
        </Grid>
      </Grid>
    </GenericDialog>
  );
};

BulkConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  successMessage: PropTypes.string.isRequired
};
