import makeStyles from '@mui/styles/makeStyles';

import { colours } from '../../theme/colors';

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: colours.secondaryGrey,
    minHeight: 102,
    width: '100%'
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 1513,
    minWidth: 1248,
    margin: '0 auto'
  },
  headerText: {
    paddingTop: 36
  },
  headerLocation: {
    fontWeight: 400,
    fontSize: '12px',
    height: 23,
    letterSpacing: '1px',
    textTransform: 'uppercase',
    padding: '5px 0px',
    alignItems: 'center'
  },
  headerTitle: {
    fontFamily: 'inherit',
    fontSize: '22px',
    minHeight: 36,
    alignItems: 'center'
  },
  actionBtn: {
    marginTop: 47
  }
}));

export default useStyles;
