import { FormControl, FormHelperText, OutlinedInput, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { colours } from '../../theme/colors';

const TextInput = ({ label, errorMessage, error, required, ...other }) => {
  return (
    <FormControl error={error}>
      <label
        htmlFor="component-outlined"
        style={{ color: error ? colours.primaryRed : colours.textPrimary, paddingBottom: 10 }}>
        <Typography variant="body1">
          {label}
          {required && <span style={{ color: colours.primaryRed }}>*</span>}
        </Typography>
      </label>
      <OutlinedInput
        id="component-outlined"
        error={error}
        required={required}
        data-testid="text-input"
        {...other}
      />
      {error && <FormHelperText id="component-error-text">{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired
};

export default TextInput;
