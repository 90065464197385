import { Checkbox, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import { colours } from '../../theme/colors';

export const StyledCheckbox = withStyles({
  root: {
    MuiButtonBase: {
      disableRipple: true
    },
    '&$checked': {
      color: colours.primaryBlue,
      '&:hover': {
        backgroundColor: colours.hoverGrey
      }
    },
    '&:hover': {
      backgroundColor: colours.hoverGrey
    }
  },
  checked: {}
})(Checkbox);

export const StyledTooltip = withStyles({
  arrow: {
    color: colours.primaryBlue,
    top: '-25px'
  },
  tooltip: {
    backgroundColor: colours.primaryBlue,
    top: '-25px'
  }
})(Tooltip);

export const StyledTitleTooltip = withStyles({
  arrow: {
    color: colours.primaryBlue,
    right: '5px'
  },
  tooltip: {
    backgroundColor: colours.primaryBlue,
    right: '5px'
  }
})(Tooltip);

const useStyles = makeStyles(() => ({
  dataGrid: {
    '& .MuiDataGrid-columnSeparator': {
      visibility: 'hidden'
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0
    },
    '&.MuiDataGrid-root': {
      border: 0
    },
    '& .MuiDataGrid-columnHeaders': {
      borderLeft: `5px solid transparent`
    },
    color: colours.textPrimary,
    backgroundColor: colours.white,
    overflowY: 'hidden',
    height: 800,
    paddingBottom: 144,
    '& .commentRow': {
      '&:hover': {
        backgroundColor: colours.grey,
        borderLeft: `5px solid ${colours.yellow}`
      },
      borderLeft: `5px solid ${colours.white}`
    },
    '& .actionedCommentRow': {
      '&:hover': {
        backgroundColor: colours.primaryBlueRowHover,
        borderLeft: `5px solid ${colours.yellow}`
      },
      borderLeft: `5px solid ${colours.white}`
    }
  },
  badge: {
    '& .MuiBadge-badge': {
      position: 'inherit',
      fontFamily: 'Arial',
      fontSize: 12,
      fontWeight: 'bold',
      lineHeight: 12,
      letterSpacing: 0.14
    },
    '& .MuiBadge-anchorOriginTopRightRectangular': {
      transform: 'none'
    }
  },
  badgeContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  badgeGreen: {
    '& .MuiBadge-colorPrimary': {
      color: colours.white,
      backgroundColor: colours.successGreen
    }
  },
  badgePurple: {
    '& .MuiBadge-colorPrimary': {
      color: colours.white,
      backgroundColor: colours.primaryBlue
    }
  },
  badgeGrey: {
    '& .MuiBadge-colorPrimary': {
      color: colours.primaryBlue,
      backgroundColor: colours.e0
    }
  },
  badgeYellow: {
    '& .MuiBadge-colorPrimary': {
      color: colours.primaryBlue,
      backgroundColor: colours.warningYellow
    }
  },
  singleCell: {
    width: '100%',
    textAlign: 'left',
    textIndent: '15px'
  },
  forCodesCell: {
    width: '100%',
    textAlign: 'center'
  },
  tooltipText: {
    overflow: 'hidden'
  }
}));

export default useStyles;
