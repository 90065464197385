import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { GlobalAppContext } from '../../store/AppStore';
import { colours } from '../../theme/colors';
import { DataTableFooter } from './DataTableFooter';
import useStyles, { StyledCheckbox } from './styles';

export const DataTable = ({
  rows,
  columns,
  totalCount,
  getRowId = (row) => row.pubPk,
  isCommentTab = false,
  getRowClassName = () => 'commentRow'
}) => {
  const classes = useStyles();
  const {
    pageNum: [page, setPage],
    pageSize: [size, setSize],
    pubPkSelection: [selectionModel, setSelectionModel],
    outputSort: [forSort, setForSort]
  } = useContext(GlobalAppContext);

  const handleSortModelChange = (models) => {
    if (JSON.stringify(models) !== JSON.stringify(forSort)) {
      setForSort(JSON.stringify(models));
      setPage(0);
    }
  };

  const handlePageChange = (params) => {
    setPage(params);
  };

  const handlePageSizeChange = (params) => {
    setPage(0);
    setSize(params);
  };

  return (
    <Box
      data-testid="data-grid-table"
      sx={{
        // bgColor needs to be overriden here; :hover in the styles.js
        // could be worth investigating why this occurs
        '& .actionedCommentRow': {
          bgcolor: () => colours.primaryBlueRow
        }
      }}>
      <DataGrid
        autoHeight
        pagination
        paginationMode="server"
        sortingMode="server"
        sortModel={JSON.parse(forSort)}
        onSortModelChange={(model) => handleSortModelChange(model)}
        checkboxSelection={!isCommentTab}
        selectionModel={selectionModel}
        onSelectionModelChange={(model) => setSelectionModel(model)}
        getRowId={getRowId}
        rows={rows}
        columns={columns}
        page={page}
        pageSize={size}
        rowCount={totalCount}
        className={classes.dataGrid}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        components={{
          Checkbox: StyledCheckbox,
          Footer: DataTableFooter
        }}
        componentsProps={{
          checkbox: { disableRipple: true },
          footer: { rowCount: totalCount }
        }}
        disableColumnMenu
        hideFooterSelectedRowCount
        isRowSelectable={() => (isCommentTab ? false : true)}
        getRowClassName={getRowClassName}
      />
    </Box>
  );
};

DataTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
  getRowId: PropTypes.func.isRequired,
  isCommentTab: PropTypes.bool,
  getRowClassName: PropTypes.func.isRequired
};
