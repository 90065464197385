import makeStyles from '@mui/styles/makeStyles';

import { colours } from '../../theme/colors';

const useStyles = makeStyles(() => ({
  alertError: {
    backgroundColor: colours.errorRed
  },
  alertSuccess: {
    backgroundColor: colours.successGreen
  },
  alertInfo: {
    backgroundColor: colours.primaryBlue
  },
  closeButton: {
    color: colours.white
  }
}));

export default useStyles;
