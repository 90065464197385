import { Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Logo from '../../assets/logoNevy.svg';
import { FERAButton } from '../Button/FERAButton';
import useStyles from './styles';

export const UnauthorizedComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div data-testid="not-found-component">
      <nav className={classes.topBar} data-testid="oops-top-bar">
        <img
          src={Logo}
          width="72"
          height="25"
          alt="RMIT LOGO"
          className={classes.logo}
          data-testid="oops-top-bar-rmit-logo"
        />
        <div data-testid="oops-top-bar-header" className={classes.title}>
          <h4>FoRs for ERA (FERA)</h4>
        </div>
      </nav>
      <div>
        <div className={classes.headerTitleContainer}>
          <Typography variant="h1" data-testid="page-header-title" className={classes.headerTitle}>
            Oops, seems like you dont have permission to view this page.
          </Typography>
        </div>

        <div className={classes.body}>
          <p>
            There has been an error logging into the RMIT Excellence in Research Australia tool.
          </p>
          <p>There may have been an error or you don’t have permission.</p>
          <FERAButton
            onClick={() => {
              localStorage.removeItem('AuthToken');
              history.push('/login');
            }}
            sx={{ marginRight: 2 }}
            color="red"
            variant="contained"
            aria-label="Login">
            Login
          </FERAButton>
          <FERAButton
            href="mailto:era.ei@rmit.edu.au"
            color="red"
            variant="outlined"
            aria-label="Contact Administrator">
            Contact Administrator
          </FERAButton>
        </div>
      </div>
    </div>
  );
};
