import React, { useState } from 'react';

import { adding } from '../PageHeader/constants';
import { PageHeader } from '../PageHeader/PageHeader';
import UserEditorView from './EditUser/UserEditorView';
import UserTableView from './ManageUsers/UserTableView';
import useStyles from './styles';

export const AdminPage = () => {
  const classes = useStyles();

  const [currentUserBeingEdited, setcurrentUserBeingEdited] = useState(null);
  const [currentUserBeingEditedIsNew, setcurrentUserBeingEditedIsNew] = useState(false);

  const onClickAdd = () => {
    setcurrentUserBeingEdited({});
    setcurrentUserBeingEditedIsNew(true);
  };

  const handleEdit = (user) => {
    setcurrentUserBeingEdited(user);
    setcurrentUserBeingEditedIsNew(false);
  };

  const title =
    currentUserBeingEdited === null
      ? 'User Management'
      : currentUserBeingEditedIsNew
      ? 'Add User'
      : 'Edit User';

  const location = currentUserBeingEdited === null ? 'ADMINISTRATION' : 'USER MANAGEMENT';

  return (
    <div data-testid="admin-page-component">
      <PageHeader
        location={location}
        title={title}
        data-testid="admin-page-header"
        actionType={adding}
        action={onClickAdd}
        actionDisabled={currentUserBeingEdited !== null}
      />
      <div data-testid="admin-page-content" className={classes.adminPage}>
        {currentUserBeingEdited === null ? (
          <UserTableView handleEdit={handleEdit} />
        ) : (
          <UserEditorView
            currentUserBeingEdited={currentUserBeingEdited}
            returnCallback={() => {
              setcurrentUserBeingEdited(null);
            }}
            title={title}
          />
        )}
      </div>
    </div>
  );
};
