import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import { Step, StepLabel, Stepper, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useContext, useState } from 'react';

import { useGetOutputComments } from '../../../api/outputs';
import { GlobalAppContext } from '../../../store/AppStore';
import { FERAButton } from '../../Button/FERAButton';
import { Loader } from '../../Loader/Loader';
import useStyles from './styles';

export const History = () => {
  const defaultCommentsNumber = 10;
  const [commentNum, setCommentNum] = useState(defaultCommentsNumber);
  const classes = useStyles();
  const {
    rightDrawer: [rightDrawerIsOpen]
  } = useContext(GlobalAppContext);
  const { data, isLoading, isError, isSuccess, isFetching, refetch } = useGetOutputComments(
    commentNum,
    rightDrawerIsOpen?.pubPk
  );

  const isComment = (type) => {
    return type === 'Comment';
  };

  const getCommentIcon = () => {
    return <CommentRoundedIcon />;
  };

  const getFormattedDate = (value) => {
    var date = new Date(value + '+00:00');
    return date.toLocaleString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      timeZone: 'Australia/Melbourne'
    });
  };

  const loadMore = () => {
    const size = data.totalResults;
    if (commentNum === size) {
      return;
    } else if (commentNum + defaultCommentsNumber > size) {
      setCommentNum(size);
    } else {
      setCommentNum(commentNum + defaultCommentsNumber);
    }
    refetch();
  };

  const getCommentCount = (total) => {
    if (commentNum > total) {
      return total;
    } else {
      return commentNum;
    }
  };

  return (
    <div className={classes.historyContainer}>
      <Typography className={classes.history} variant="h6" data-testid="history-header">
        History
      </Typography>
      {isLoading || isFetching ? (
        <Loader data-testid={`output-history-loader-${rightDrawerIsOpen?.pubPk}`} />
      ) : isError ? (
        <Typography
          variant="subtitle2"
          data-testid={`history-error-${rightDrawerIsOpen?.pubPk}`}
          className={classes.historyTextBox}>
          Comments for this output cannot be displayed
        </Typography>
      ) : isSuccess && data && data.data && data.totalResults > 0 ? (
        <>
          <Stepper orientation="vertical" data-testid="history-stepper">
            {data.data.map(
              (comment, index) =>
                index < commentNum && (
                  <Step
                    completed
                    key={index}
                    data-testid={`history-stepper-step-${index}`}
                    className={classes.historyStepper}>
                    <StepLabel
                      data-testid={`history-stepper-label-${index}`}
                      StepIconComponent={isComment(comment.title) ? getCommentIcon : undefined}
                      optional={
                        <Typography
                          variant="subtitle2"
                          data-testid={`history-stepper-comment-${index}`}>
                          {comment.comment}
                        </Typography>
                      }
                      className={clsx(isComment(comment.title) && classes.commentIcon)}>
                      {comment.title} |
                      {comment.title === 'Comment' &&
                        comment.reasonCode?.length > 0 &&
                        ' ' + comment.reasonCode + ' | '}
                      {getFormattedDate(comment.createdDate)} | {comment.username}
                    </StepLabel>
                  </Step>
                )
            )}
          </Stepper>
          <div className={classes.lazyLoader} data-testid="history-lazy-loader">
            <FERAButton
              data-testid="history-load-more-button"
              onClick={loadMore}
              variant="outlined"
              disabled={commentNum >= data.totalResults}
              className={classes.loadMore}
              color="purple">
              Load More
            </FERAButton>
            <Typography data-testid="history-comment-count">
              {getCommentCount(data.totalResults)} of {data.totalResults} Comments
            </Typography>
          </div>
        </>
      ) : (
        <Typography
          variant="subtitle2"
          className={classes.historyTextBox}
          data-testid={`output-history-no-results-${rightDrawerIsOpen?.pubPk}`}>
          There is currently no comments for this output
        </Typography>
      )}
    </div>
  );
};
