import { TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import useStyles from './styles';

const SearchDropDown = ({
  placeholder,
  options,
  value,
  onChange,
  onClear,
  getOptionDisabled,
  getOptionLabel,
  isDisabled
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  return (
    <Autocomplete
      disabled={isDisabled}
      filterOptions={createFilterOptions({
        matchFrom: 'start',
        trim: true,
        limit: 20
      })}
      ListboxProps={{
        style: {
          maxHeight: '220px'
        }
      }}
      disablePortal
      data-testid="searchdropdown-autocomplete"
      freeSolo
      filterSelectedOptions
      getOptionLabel={getOptionLabel}
      options={options}
      value={value}
      inputValue={inputValue}
      onChange={(_, newValue, reason) => {
        onChange(newValue);
        if (reason === 'clear' && typeof onClear === 'function') onClear();
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      clearOnBlur
      getOptionDisabled={getOptionDisabled}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
          className={classes.inputField}
          InputProps={{
            ...params.InputProps
          }}
          data-testid="searchdropdown-textfield-autocomplete"
        />
      )}
    />
  );
};

SearchDropDown.propTypes = {
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  getOptionDisabled: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool
};

export default SearchDropDown;
