import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';

import FeraAlert from '../components/Alert';
import ToastNotification from '../components/TopBar/ToastNotification';
import { AppStore } from '../store/AppStore';
import { AuthStore } from '../store/AuthStore';

export const ViewWrapper = ({ children }) => {
  return (
    <AuthStore>
      <AppStore>
        <FeraAlert />
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          maxSnack={99}
          content={(key, notification) => (
            <ToastNotification id={notification.id} notification={notification} />
          )}>
          {children}
        </SnackbarProvider>
      </AppStore>
    </AuthStore>
  );
};

ViewWrapper.propTypes = {
  children: PropTypes.node.isRequired
};
