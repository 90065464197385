import { CircularProgress } from '@mui/material';
import React from 'react';

import useStyles from './styles';

export const Loader = () => {
  const classes = useStyles();
  return (
    <div data-testid="loader-component" className={classes.center}>
      <CircularProgress data-testid="circular-loader" aria-label="Loading bar" />
    </div>
  );
};
