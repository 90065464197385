export const workspaceCategories = [
  'Title',
  'Main FoR Code',
  'FoR Code',
  'Contributors',
  'Abstract',
  'Keywords',
  'Outlet'
];

export const reasonCodes = [
  'FoR change',
  'Not eligible',
  'Output category change',
  'Missing full-text',
  'Top-tier output',
  'General comment'
];

export const getFilters = (forAction, qualityPreference, workspaceForCode) => {
  const filters = [
    {
      groupname: 'Output type',
      options: [
        'Journal Article',
        'Book',
        'Book Chapter',
        'Conference Publication',
        'Research Report for External Body',
        'Other NTRO'
      ]
    }
  ];

  if (forAction) {
    if (workspaceForCode?.length !== 4) {
      filters.push({
        groupname: 'For Action',
        options: ['To Approve', 'To Commit']
      });
    }
  }

  if (!forAction) {
    filters.push({
      groupname: 'Status',
      options: ['Open', 'Modified', 'For Review', 'Committed']
    });
  }

  switch (qualityPreference?.name) {
    case 'ABDC':
    case 'CORE':
      filters.push({
        groupname: 'Quality Indicators',
        options: ['A*', 'A', 'B', 'C', 'Other']
      });
      break;
    case 'Scimago':
      filters.push({
        groupname: 'Quality Indicators',
        options: ['Q1', 'Q2', 'Q3', 'Q4', 'Other']
      });
      break;
    default:
      break;
  }
  return filters;
};

export const getCommentFilters = () => {
  const filters = [
    {
      groupname: 'Status',
      options: ['For Action', 'Actioned']
    },
    {
      groupname: 'Comment Type',
      options: reasonCodes
    },
    {
      groupname: 'Main FoR',
      options: ['Main FoR']
    }
  ];
  return filters;
};
