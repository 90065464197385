import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/pixel-art-neutral';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import {
  Badge,
  Box,
  Button,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import clsx from 'clsx';
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import { useNotifications } from '../../api/notifications';
import Logo from '../../assets/logoNevy.svg';
import { GlobalAppContext } from '../../store/AppStore';
import { AuthContext } from '../../store/AuthStore';
import { NotificationTray } from './NotificationTray';
import useStyles from './styles';
import { UpdatePreferencesDialog } from './UpdatePreferencesDialog';

function useInterval(callback, delay) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}

const hasFocus = () => typeof document !== 'undefined' && document.hasFocus();

const useWindowFocus = () => {
  const [focused, setFocused] = useState(hasFocus); // Focus for first render

  useEffect(() => {
    setFocused(hasFocus()); // Focus for additional renders

    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return focused;
};

export const TopBar = () => {
  const classes = useStyles();
  const {
    slideBar: [, setSlideLeftBarIsOpen],
    notifications: [notifications]
  } = useContext(GlobalAppContext);
  const anchorRef = useRef(null);
  const { authUser } = useContext(AuthContext);
  const history = useHistory();

  const defaultPollTime = 1 * 60 * 1000;
  const pollTime = parseInt(process.env.REACT_APP_NOTIFICATION_POLL_TIME) || defaultPollTime;
  var { refetch } = useNotifications();

  // Will run on initial load, every {pollTime} seconds if the window is in focus, and also will run if the window is refocused
  const windowFocused = useWindowFocus();
  useInterval(() => {
    document.hasFocus() && refetch();
  }, pollTime);

  useEffect(() => {
    if (windowFocused) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowFocused]);

  const username = authUser.userInformation.fullName;

  const [openDropdown, setOpenDropdown] = useState(false);
  const [preferencesOpen, setPreferencesOpen] = useState(false);
  const [notificationsTrayOpen, setNotificationsTrayOpen] = useState(false);

  const handleClick = () => {
    setSlideLeftBarIsOpen(false);
  };

  const hanedleClickName = () => {
    setOpenDropdown(!openDropdown);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenDropdown(false);
    } else if (event.key === 'Escape') {
      setOpenDropdown(false);
    }
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenDropdown(false);
  };

  const handleLogout = () => {
    setOpenDropdown(false);
    history.push('/logout');
  };

  let avatarSVG = createAvatar(style, {
    seed: username,
    dataUri: true,
    scale: 90
  });

  return (
    <ClickAwayListener
      onClickAway={() => {
        setNotificationsTrayOpen(false);
      }}>
      <Box sx={{ position: 'relative' }}>
        <nav className={classes.topBar} onClick={handleClick} data-testid="top-bar">
          <img
            src={Logo}
            width="72"
            height="25"
            alt="RMIT LOGO"
            className={classes.logo}
            data-testid="top-bar-rmit-logo"
          />
          <div data-testid="top-bar-header" className={classes.title}>
            <h4>FoRs for ERA (FERA)</h4>
          </div>
          <div className={classes.topBarRight}>
            <div className={classes.notificationsContainer}>
              <Badge badgeContent={notifications.length} className={classes.badgeIcon}>
                <NotificationImportantIcon
                  className={clsx(classes.bellIcon, classes.customFill)}
                  data-testid="top-bar-notification-icon"
                  onClick={() => {
                    setNotificationsTrayOpen(!notificationsTrayOpen);
                  }}
                />
              </Badge>
              {notificationsTrayOpen && (
                <NotificationTray
                  hideNotificationTray={() => {
                    setNotificationsTrayOpen(false);
                  }}
                />
              )}
            </div>

            <div
              className={clsx(classes.divider, classes.customFill)}
              data-testid="top-bar-vertical-divider"
            />
            <Avatar
              sx={{ width: 40, height: 40 }}
              className={classes.avatar}
              data-testid="top-bar-initial">
              <img src={avatarSVG} alt="Avatar" />
            </Avatar>
            <Button
              ref={anchorRef}
              id="dropdown-user-menu-button"
              aria-controls={openDropdown ? 'dropdown-user-menu' : undefined}
              aria-expanded={openDropdown ? 'true' : undefined}
              aria-haspopup="true"
              aria-label="dropdown options for account management"
              onClick={hanedleClickName}
              className={classes.usernameBtn}
              data-testid="dropdown-user-menu-button"
              disableFocusRipple
              disableRipple
              endIcon={<ArrowDropDownIcon />}>
              <Typography
                variant="body2"
                className={classes.username}
                data-testid="top-bar-username">
                {username}
              </Typography>
            </Button>
            <Popper
              open={openDropdown}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-end"
              disablePortal>
              <Paper className={classes.popperList} square>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={openDropdown}
                    id="dropdown-user-menu"
                    data-testid="dropdown-user-menu"
                    aria-labelledby="dropdown-user-menu-button"
                    onKeyDown={handleListKeyDown}>
                    <MenuItem
                      aria-label="Edit Preferences"
                      className={classes.popperListItem}
                      onClick={() => {
                        setOpenDropdown(false);
                        setPreferencesOpen(true);
                      }}
                      data-testid="edit-preference-button">
                      <Typography variant="body1">Edit Preferences</Typography>
                    </MenuItem>
                    <MenuItem
                      aria-label="Logout"
                      className={classes.popperListItem}
                      onClick={() => handleLogout()}
                      data-testid="logout-button">
                      <Typography variant="body1">Logout</Typography>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Popper>
          </div>
        </nav>
        {preferencesOpen && (
          <UpdatePreferencesDialog
            onClose={() => {
              setPreferencesOpen(false);
            }}
          />
        )}
      </Box>
    </ClickAwayListener>
  );
};
