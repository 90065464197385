import React from 'react';

import { Logout } from '../components/Login/Logout';
import { DefaultLayout } from '../layouts/DefaultLayout/DefaultLayout';
import { ViewWrapper } from './ViewWrapper';

export const LogoutView = () => {
  return (
    <ViewWrapper>
      <DefaultLayout>
        <Logout />
      </DefaultLayout>
    </ViewWrapper>
  );
};
