import { useMutation, useQuery, useQueryClient } from 'react-query';

import { userEndpoint, usersEndpoint } from './endpoints';
import { request } from './request';

export const useGetUser = (userId, staleTime = 3000) => {
  return useQuery(
    ['user', userId],
    async () => {
      return request({ url: `${userEndpoint}/${userId}` }).then((response) => {
        return response.data;
      });
    },
    {
      staleTime,
      enabled: typeof userId !== 'undefined'
    }
  );
};

export const useGetCommentor = (userId, staleTime = 3000) => {
  return useQuery(
    ['user', userId],
    async () => {
      return request({ url: `${userEndpoint}/${userId}/name` }).then((response) => {
        return response.data;
      });
    },
    {
      staleTime,
      enabled: typeof userId !== 'undefined'
    }
  );
};

const updateUser = async (data) => {
  const r = {
    url: `${userEndpoint}/${data.id}`,
    method: 'PUT',
    data: data,
    headers: { 'Content-Type': 'application/json' }
  };
  const response = await request(r);
  return response;
};

export const useUpdateUser = (isCurrentUser) => {
  const queryClient = useQueryClient();

  return useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
      if (isCurrentUser) {
        queryClient.invalidateQueries('session');
      }
    }
  });
};

const createUser = async (data) => {
  const r = {
    url: usersEndpoint,
    method: 'POST',
    data: data,
    headers: { 'Content-Type': 'application/json' }
  };
  const response = await request(r);
  return response;
};

export const useCreateUser = () => {
  const queryClient = useQueryClient();

  return useMutation(createUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
    }
  });
};
