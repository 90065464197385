import makeStyles from '@mui/styles/makeStyles';

import { colours } from '../../../theme/colors';

const useStyles = makeStyles(() => ({
  workspace: {
    display: 'block',
    width: '100%',
    minHeight: 'calc(100vh - 50px)'
  },
  notFoundWorkspaceHeader: {
    backgroundColor: colours.secondaryGrey,
    minHeight: 102,
    width: '100%',
    '& .MuiTypography-h1': {
      paddingTop: 52,
      fontSize: 20,
      fontFamily: 'Museo'
    }
  }
}));

export default useStyles;
