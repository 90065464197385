import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  adminPage: {
    maxWidth: 1360,
    minWidth: 1248,
    margin: '0 auto',
    paddingTop: 31,
    paddingBottom: 18
  }
}));

export default useStyles;
