import AddIcon from '@mui/icons-material/Add';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { FERAButton } from '../../Button/FERAButton';
import FoRField, { FoRFieldShape, FoRFieldShapeOption } from './FoRField';
import useStyles from './styles';

const FoRFieldSet = ({ options, assignedCodes, setAssignedCodes, title }) => {
  const classes = useStyles();
  const getOptionDisabled = (option) => {
    const res = assignedCodes?.map((a) => a.forCode).indexOf(option.forCode) !== -1;
    return res;
  };

  options.sort((a, b) => (a.forCode < b.forCode ? -1 : 1));

  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant="h1"
          className={classes.headerTitlePrimary}
          data-testid="page-header-title">
          {title}
        </Typography>
      </Grid>

      {assignedCodes?.map((assignedCode, index) => {
        return (
          <Grid key={index} item xs={12} data-testid={`for-fieldset-${title.toLowerCase()}`}>
            <FoRField
              key={index}
              onRemove={() => {
                const list = [...assignedCodes];
                list.splice(index, 1);
                setAssignedCodes(list);
              }}
              onOwnerChange={(newValue) => {
                const list = [...assignedCodes];
                const oldAssignedCode = list.splice(index, 1)[0];
                list.splice(index, 0, { ...oldAssignedCode, isPrimaryOwner: newValue });
                setAssignedCodes(list);
              }}
              onCodeChange={(newValue) => {
                const list = [...assignedCodes];
                const oldAssignedCode = list.splice(index, 1)[0];
                list.splice(index, 0, {
                  ...oldAssignedCode,
                  forCode: newValue?.forCode,
                  forName: newValue?.forName
                });
                setAssignedCodes(list);
              }}
              options={options}
              getOptionDisabled={getOptionDisabled}
              assignedCode={assignedCode}
            />
          </Grid>
        );
      })}

      <Grid item xs={12}>
        <FERAButton
          variant="contained"
          color="red"
          aria-label="Add FoR code"
          onClick={() => {
            const list = [...assignedCodes];
            list.push({ isPrimaryOwner: false, forCode: null, forName: null });
            setAssignedCodes(list);
          }}
          startIcon={<AddIcon />}
          data-testid="for-fieldset-add-btn">
          Add FoR Code
        </FERAButton>
      </Grid>
    </>
  );
};

FoRFieldSet.propTypes = {
  options: PropTypes.arrayOf(FoRFieldShapeOption).isRequired,
  assignedCodes: PropTypes.arrayOf(FoRFieldShape).isRequired,
  setAssignedCodes: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default FoRFieldSet;
