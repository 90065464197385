import makeStyles from '@mui/styles/makeStyles';

import { colours } from '../../theme/colors';

const useStyles = makeStyles(() => ({
  alert: {
    backgroundColor: colours.errorRed
  },
  bellIcon: {
    fontSize: 82,
    color: colours.borderGrey,
    paddingBottom: 14
  },
  center: {
    textAlign: 'center',
    paddingTop: 148
  },
  closeButton: {
    color: colours.white
  }
}));

export default useStyles;
