import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Alert, Typography } from '@mui/material';
import { SnackbarContent, useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import { useDeleteNotification } from '../../api/notifications';
import { GlobalAppContext } from '../../store/AppStore';
import { colours } from '../../theme/colors';
import { FERALink } from '../Link/FERALink';
import useStyles from './styles';

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isIntersecting;
}

const ToastNotification = ({ id, notification }, ref) => {
  const classes = useStyles();
  const history = useHistory();
  const alertRef = useRef();
  const isVisible = useOnScreen(alertRef);
  const truncateString = (str, limit = 100) =>
    str.length > limit ? str.substring(0, limit) + '...' : str;
  const {
    notifications: [notifications, setNotifications]
  } = useContext(GlobalAppContext);

  const { mutate: deleteNotification } = useDeleteNotification(false);

  const { closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        closeSnackbar(id);
      }, 8000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
  let date = new Date(notification.createdAt + '+00:00');
  const formattedDate = date
    .toLocaleString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'Australia/Melbourne'
    })
    .replace(',', '');
  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Alert
        ref={alertRef}
        onClose={() => {
          deleteNotification(notification.id, {
            onSuccess: () => {
              const delIndex = notifications.map((n) => n.id).indexOf(notification.id);
              if (delIndex !== -1) {
                const list = [...notifications];
                list.splice(delIndex, 1);
                setNotifications(list);
              }
              closeSnackbar(id);
            }
          });
        }}
        icon={
          notification.title === 'Comment' ? (
            <CommentRoundedIcon fontSize="inherit" className={classes.toastNotificationIcon} />
          ) : (
            <InfoOutlinedIcon className={classes.toastNotificationIcon} />
          )
        }
        className={classes.toastNotification}>
        {notification.title === 'Comment' && truncateString(notification.format.reasonCode)}
        <br />
        {notification.title === 'Comment'
          ? `"${truncateString(notification.format.message)}"`
          : truncateString(notification.format.message)}
        <br />
        {notification.format.callToAction && (
          <FERALink
            className={classes.toastNotificationAction}
            onClick={() => {
              if (typeof notification.format.callToAction.bulkAction !== 'undefined') {
                history.push(notification.format.callToAction.bulkAction);
              } else {
                notification.format.callToAction.action();
              }
            }}>
            {notification.format.callToAction.message}
          </FERALink>
        )}
        <Typography sx={{ fontSize: 13, paddingTop: 1, color: colours.primaryGrey }}>
          {formattedDate}
        </Typography>
      </Alert>
    </SnackbarContent>
  );
};

ToastNotification.propTypes = {
  notification: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired
};

export default React.forwardRef(ToastNotification);
