import { FormControlLabel, Link, Stack, Switch, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { useGetFoRCodes } from '../../../api/forcodes';
import { useCreateUser, useGetUser, useUpdateUser } from '../../../api/user';
import { GlobalAppContext } from '../../../store/AppStore';
import { AuthContext } from '../../../store/AuthStore';
import { colours } from '../../../theme/colors';
import { FERAButton } from '../../Button/FERAButton';
import { Loader } from '../../Loader/Loader';
import DetailsInput from './DetailsInput';
import FormDialog from './Dialog';
import FoRAccessFieldSet from './FoRAccessFieldSet';
import FoRFieldSet from './FoRFieldSet';
import Preferences from './Preferences';
import useStyles from './styles';

const UserEditorView = ({ title, returnCallback, currentUserBeingEdited }) => {
  const { authUser } = useContext(AuthContext);
  const {
    globalAlert: [, setAlert]
  } = useContext(GlobalAppContext);

  const classes = useStyles();
  // Local states
  const [confirmSubmitOpen, setConfirmSubmitOpen] = useState(false);
  const [submitCount, setSubmitCount] = useState(0);
  const [allFoRCodes, setAllFoRCodes] = useState([]);
  const currentAction = typeof currentUserBeingEdited.id === 'undefined' ? 'add' : 'update';
  const isEditingCurrentUser = currentUserBeingEdited.id === authUser.userInformation.id;

  // form states that interact with the API
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [college, setCollege] = useState('');
  const [eNumber, setENumber] = useState('');
  const [comment, setComment] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [qualityIndicatorPreference, setQualityIndicatorPreference] = useState(null);
  const [citationPreference, setCitationPreference] = useState(null);
  const [roleTypes, setRoleTypes] = useState([]);
  const [assigned2DigitCodes, setAssigned2DigitCodes] = useState([]);
  const [assigned4DigitCodes, setAssigned4DigitCodes] = useState([]);

  // Grab info from the API
  var {
    data: user,
    isSuccess: UserIsLoaded,
    isLoading: userIsLoading,
    isFetching: userIsFetching
  } = useGetUser(currentUserBeingEdited.id);
  const {
    data: FoRCodes,
    isSuccess: FoRCodesAreLoaded,
    isLoading: forCodesAreLoading
  } = useGetFoRCodes([2, 4]);
  const { mutate: updateUser } = useUpdateUser(isEditingCurrentUser);
  const { mutate: createUser } = useCreateUser();

  useEffect(() => {
    if (FoRCodesAreLoaded) {
      const tmpAllForCodes = FoRCodes.data || [];
      setAllFoRCodes(tmpAllForCodes);
      if (currentAction === 'update' && UserIsLoaded) {
        // Adding the forName to the FoRCodes that the user already has
        const tmpAssigned2DigitCodes = user.userRoles?.filter(
          (c) => c.roleName === 'TWO_DIGITS_ADVISOR'
        )[0]?.userRoleForCodes;
        const tmpAssigned4DigitCodes = user.userRoles?.filter(
          (c) => c.roleName === 'FOUR_DIGITS_ADVISOR'
        )[0]?.userRoleForCodes;

        tmpAllForCodes.forEach((code) => {
          tmpAssigned2DigitCodes?.forEach((c) => {
            if (c.forCode === code.forCode) {
              c.forName = code.forName;
            }
          });
          tmpAssigned4DigitCodes?.forEach((c) => {
            if (c.forCode === code.forCode) {
              c.forName = code.forName;
            }
          });
        });

        setFirstName(user.firstName);
        setLastName(user.lastName);
        setCollege(user.college);
        setENumber(user.enumber);
        setIsActive(user.isActive);
        setQualityIndicatorPreference(user.qualityIndicator);
        setCitationPreference(user.citation);
        setComment(user.notes);
        setRoleTypes(user.userRoles.map((r) => r.roleName));
        setAssigned2DigitCodes(tmpAssigned2DigitCodes || []);
        setAssigned4DigitCodes(tmpAssigned4DigitCodes || []);
      }
    }
  }, [FoRCodes, user, FoRCodesAreLoaded, UserIsLoaded, currentAction]);

  const validate = () => {
    return (
      firstName !== '' &&
      lastName !== '' &&
      college !== '' &&
      eNumber !== '' &&
      roleTypes.length > 0
    );
  };

  const onInitialSubmit = () => {
    if (validate()) {
      setSubmitCount(0);
      setConfirmSubmitOpen(true);
    } else {
      setSubmitCount(submitCount + 1);
      window.scrollTo(0, 0);
    }
  };

  const onConfirmedSubmit = () => {
    setConfirmSubmitOpen(false);

    const ALL_ROLES = [
      { id: 1, roleName: 'ADMINISTRATOR', userRoleForCodes: [] },
      { id: 2, roleName: 'TWO_DIGITS_ADVISOR', userRoleForCodes: [] },
      { id: 3, roleName: 'FOUR_DIGITS_ADVISOR', userRoleForCodes: [] },
      { id: 4, roleName: 'UNIVERSITY_LEAD', userRoleForCodes: [] }
    ];

    const newUserRoles =
      ALL_ROLES.filter((r) => {
        return roleTypes.includes(r.roleName);
      }) || [];

    const twoDigitIndex = newUserRoles.map((c) => c.roleName).indexOf('TWO_DIGITS_ADVISOR');
    const fourDigitIndex = newUserRoles.map((c) => c.roleName).indexOf('FOUR_DIGITS_ADVISOR');

    const allAssignedForCodes = [];

    if (twoDigitIndex > -1) {
      const old2DigitCodes = newUserRoles.splice(twoDigitIndex, 1)[0];
      old2DigitCodes.userRoleForCodes = assigned2DigitCodes.filter(
        (c) => c.forCode !== '' && c.forCode !== null && typeof c.forCode !== 'undefined'
      );
      old2DigitCodes.userRoleForCodes.forEach((c) => {
        if (c.forCode !== null) {
          delete c.forName;
          c.userRoleId = old2DigitCodes.id;
          allAssignedForCodes.push(c.forCode);
        }
      });
      newUserRoles.splice(twoDigitIndex, 0, old2DigitCodes);
    }

    if (fourDigitIndex > -1) {
      const old4DigitCodes = newUserRoles.splice(fourDigitIndex, 1)[0];
      old4DigitCodes.userRoleForCodes = assigned4DigitCodes.filter(
        (c) => c.forCode !== '' && c.forCode !== null && typeof c.forCode !== 'undefined'
      );
      old4DigitCodes.userRoleForCodes.forEach((c) => {
        if (c.forCode !== null) {
          delete c.forName;
          c.userRoleId = old4DigitCodes.id;
          allAssignedForCodes.push(c.forCode);
        }
      });
      newUserRoles.splice(fourDigitIndex, 0, old4DigitCodes);
    }

    const newData = {
      ...user,
      citation: citationPreference,
      qualityIndicator: qualityIndicatorPreference,
      email: `${eNumber}@rmit.edu.au`, // Not an option?
      college: college,
      enumber: eNumber,
      firstName: firstName,
      isActive: isActive,
      lastName: lastName,
      notes: comment,
      userRoles: newUserRoles,
      allAssignedForCodes: allAssignedForCodes
    };

    const functionMapping = {
      add: { func: createUser, verbSuccess: 'added', verbError: 'add' },
      update: { func: updateUser, verbSuccess: 'updated', verbError: 'update' }
    };

    const action = functionMapping[currentAction];
    /* istanbul ignore next */ /* Mocking the function as it is using the context API */
    action.func(newData, {
      onSuccess: () => {
        returnCallback();
        setAlert({
          open: true,
          message: `Successfully ${action.verbSuccess} user ${newData.enumber}`,
          severity: 'success'
        });
      },
      onError: (error) => {
        const message =
          error?.data?.message === 'A user with provided e-number already exists.'
            ? `User already exists with ENumber ${newData.enumber}`
            : `Failed to ${action.verbError} user ${newData.enumber}`;
        setConfirmSubmitOpen(false);
        setAlert({
          open: true,
          message: message,
          severity: 'error'
        });
      }
    });
  };

  if (userIsLoading || userIsFetching || forCodesAreLoading)
    return <Loader data-testid="admin-page-edit-user-view-loader" />;

  return UserIsLoaded || currentAction === 'add' ? (
    <div data-testid="admin-page-edit-user-view">
      <Link
        component="button"
        variant="body2"
        underline="always"
        onClick={returnCallback}
        className={classes.breadCrumb}>
        &lt; back to users
      </Link>

      <Stack spacing={4}>
        <Typography variant="h1" className={classes.headerTitle} data-testid="page-header-title">
          User Details
        </Typography>
        <DetailsInput
          disabled={false}
          isError={submitCount > 0 && firstName === ''}
          errorMessage="A first name must be supplied"
          label="First Name"
          value={firstName}
          setValue={setFirstName}
        />
        <DetailsInput
          disabled={false}
          isError={submitCount > 0 && lastName === ''}
          errorMessage="A last name must be supplied"
          label="Last Name"
          value={lastName}
          setValue={setLastName}
        />
        <DetailsInput
          disabled={false}
          isError={submitCount > 0 && college === ''}
          errorMessage="A college must be supplied"
          label="College"
          value={college}
          setValue={setCollege}
        />
        <DetailsInput
          disabled={false}
          isError={submitCount > 0 && eNumber === ''}
          errorMessage="A E-Number must be supplied"
          label="E-Number"
          value={eNumber}
          setValue={setENumber}
        />
        {currentAction === 'update' && (
          <FormControlLabel
            control={
              <Switch
                checked={isActive}
                className={classes.switch}
                onChange={(event) => {
                  setIsActive(event.target.checked);
                }}
                data-testid="is-active-user"
              />
            }
            label="Active user?"
          />
        )}
        <Typography variant="h1" className={classes.headerTitle} data-testid="page-header-title">
          FoR Access<span style={{ color: colours.primaryRed }}>*</span>
        </Typography>
        <FoRAccessFieldSet
          roleTypes={roleTypes}
          setRoleTypes={setRoleTypes}
          error={submitCount > 0 && roleTypes.length === 0}
        />
        {(roleTypes.includes('TWO_DIGITS_ADVISOR') ||
          roleTypes.includes('FOUR_DIGITS_ADVISOR')) && (
          <Typography variant="h1" className={classes.headerTitle} data-testid="page-header-title">
            Applicable FoR Codes
          </Typography>
        )}
        {roleTypes.includes('TWO_DIGITS_ADVISOR') && (
          <FoRFieldSet
            assignedCodes={assigned2DigitCodes}
            setAssignedCodes={setAssigned2DigitCodes}
            title="2-Digit codes"
            options={allFoRCodes.filter((c) => c.forCode.length === 2)}
          />
        )}
        {roleTypes.includes('FOUR_DIGITS_ADVISOR') && (
          <FoRFieldSet
            assignedCodes={assigned4DigitCodes}
            setAssignedCodes={setAssigned4DigitCodes}
            title="4-Digit codes"
            options={allFoRCodes.filter((c) => c.forCode.length === 4)}
          />
        )}
        <Preferences
          qualityIndicatorPreference={qualityIndicatorPreference}
          setQualityIndicatorPreference={setQualityIndicatorPreference}
          citationPreference={citationPreference}
          setCitationPreference={setCitationPreference}
        />
        <Typography
          variant="h1"
          className={classes.headerTitlePrimary}
          data-testid="page-header-title">
          Comment
        </Typography>
        <TextField
          id="comment"
          placeholder="Add comment here"
          variant="outlined"
          value={comment}
          className={classes.inputField}
          onChange={(event) => {
            setComment(event.target.value);
          }}
        />
        <FERAButton
          variant="contained"
          color="red"
          aria-label="Submit changes to user"
          sx={{ width: 97 }}
          onClick={onInitialSubmit}>
          Submit
        </FERAButton>
      </Stack>

      {confirmSubmitOpen && (
        <FormDialog
          title={title}
          onSubmit={onConfirmedSubmit}
          onClose={() => {
            setConfirmSubmitOpen(false);
          }}
        />
      )}
    </div>
  ) : (
    <div></div>
  );
};

const currentUserBeingEditedShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
});

UserEditorView.propTypes = {
  title: PropTypes.string.isRequired,
  returnCallback: PropTypes.func.isRequired,
  currentUserBeingEdited: currentUserBeingEditedShape.isRequired
};

export default UserEditorView;
