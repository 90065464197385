const baseEndpoint = 'api/v1';
export const sessionEndpoint = `${baseEndpoint}/session`;
export const logoutEndpoint = 'logout';
export const outputsEndpoint = `${baseEndpoint}/outputs`;
export const searchOutputsEndpoint = `${baseEndpoint}/search-outputs`;
export const userEndpoint = `${baseEndpoint}/user`;
export const usersEndpoint = `${baseEndpoint}/users`;
export const preferencesEndpoint = `${baseEndpoint}/preferences`;
export const FoRCodeEndpoint = `${baseEndpoint}/for-codes`;
export const outputEndpoint = `${baseEndpoint}/output`;
export const assignedForCodesEndpoint = `${baseEndpoint}/assigned-for-codes`;
export const notificationsEndpoint = `${baseEndpoint}/notifications`;
export const notificationEndpoint = `${baseEndpoint}/notification`;
export const commentNotificationsEndpoint = `${baseEndpoint}/comment-notifications`;
export const summaryEndpoint = `${baseEndpoint}/summary`;
export const dataQualityEndpoint = `${baseEndpoint}/dq`;
