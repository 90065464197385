import makeStyles from '@mui/styles/makeStyles';

import { colours } from '../../theme/colors';

const useStyles = makeStyles(() => ({
  drawer: {
    '& .MuiDrawer-paper': {
      width: 600,
      overflowX: 'hidden'
    }
  },
  closableArea: {
    width: 577,
    paddingTop: 30,
    paddingLeft: 23,
    paddingBottom: 10,
    backgroundColor: colours.secondaryGrey
  },
  closeBtn: {
    backgroundColor: colours.secondaryGrey,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: colours.secondaryGrey
    }
  },
  closeIcon: {
    cursor: 'pointer'
  }
}));

export default useStyles;
