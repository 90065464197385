import { Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import { colours } from '../../../theme/colors';

export const StyledTooltip = withStyles({
  arrow: {
    color: colours.primaryBlue
  },
  tooltip: {
    backgroundColor: colours.primaryBlue
  }
})(Tooltip);

const useStyles = makeStyles(() => ({
  activeTab: {
    color: colours.primaryRed
  },
  allTabs: {
    maxWidth: 1513,
    minWidth: 1248,
    margin: '0 auto',
    paddingTop: 31
  },
  tabGroup: {
    width: '100%',
    '& .MuiTabs-indicator': { backgroundColor: colours.primaryRed },
    '& .Mui-selected': { color: colours.primaryRed }
  },
  tabHeader: {
    '&.MuiTab-root': {
      textTransform: 'none'
    },
    fontSize: 15,
    fontWeight: 400
  },
  singleTab: {
    width: '100%',
    backgroundColor: colours.white
  },
  badgeIcon: {
    '& .MuiBadge-badge': {
      backgroundColor: colours.primaryRed,
      color: colours.white
    },
    marginRight: 14,
    right: -12
  }
}));

export default useStyles;
