import PropTypes from 'prop-types';
import React, { useState } from 'react';

export const GlobalAppContext = React.createContext(false);

export const defaults = {
  alert: { message: '', open: false, severity: 'success' },
  pagination: { page: 0, pageSize: 25 }
};

/* istanbul ignore next */ /* Mocking the function as it is using the context API */
export const AppStore = ({ children }) => {
  const [slideLeftBarIsOpen, setSlideLeftBarIsOpen] = useState(false);
  const [forCode, setForCode] = useState({});
  const [alert, setAlert] = useState(defaults.alert);

  const [forSort, setForSort] = useState(JSON.stringify([]));

  const [page, setPage] = useState(defaults.pagination.page);
  const [size, setSize] = useState(defaults.pagination.pageSize);
  const [selection, setSelection] = useState([]);

  const [rightDrawerIsOpen, setRightDrawerIsOpen] = useState(null);

  const [notifications, setNotifications] = useState([]);
  const [serverNotifyState, setServerNotifyState] = useState({ live: false, lastId: null });

  const [actionedComments, setActionedComments] = useState({});

  return (
    <GlobalAppContext.Provider
      value={{
        slideBar: [slideLeftBarIsOpen, setSlideLeftBarIsOpen],
        forCodeSelected: [forCode, setForCode],
        globalAlert: [alert, setAlert],

        outputSort: [forSort, setForSort],

        pageNum: [page, setPage],
        pageSize: [size, setSize],
        pubPkSelection: [selection, setSelection],

        rightDrawer: [rightDrawerIsOpen, setRightDrawerIsOpen],
        notifications: [notifications, setNotifications],
        serverNotifyState: [serverNotifyState, setServerNotifyState],
        actionedComments: [actionedComments, setActionedComments]
      }}>
      {children}
    </GlobalAppContext.Provider>
  );
};

AppStore.propTypes = {
  children: PropTypes.any
};
