import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { LoadingWithNavBar } from '../../components/Loader/LoadingWithNavBar';
import { OutputDetailsDrawer } from '../../components/OutputDetails/OutputDetailsDrawer';
import { SideBar } from '../../components/SideBar/SideBar';
import { SlideSideBar } from '../../components/SideBar/SlideSideBar';
import { TopBar } from '../../components/TopBar/TopBar';
import { AuthContext } from '../../store/AuthStore';
import { UnauthorizedView } from '../../views/UnauthorizedView';
import useStyles from './styles';

export const NavigableLayout = ({ adminOnly, children }) => {
  const classes = useStyles();
  const { authUser, onLogout } = useContext(AuthContext);

  if (!authUser) {
    return <LoadingWithNavBar />;
  }

  if (!authUser.isAuthenticated) {
    onLogout();
    return <UnauthorizedView />;
  }
  if (adminOnly && !authUser.userIsAdmin) {
    return <UnauthorizedView />;
  }
  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <TopBar />
        <SideBar />
        <SlideSideBar />
        <OutputDetailsDrawer />
        <div>{children}</div>
      </main>
    </div>
  );
};

NavigableLayout.propTypes = {
  children: PropTypes.node.isRequired,
  adminOnly: PropTypes.bool
};
