import './assets/fonts/Fonts-Museo/Fonts-Museo/Museo300-Regular.otf';
import './index.css';

import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { Routes } from './routes/Routes';
import { colours } from './theme/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: colours.primaryBlue
    },
    error: {
      main: colours.errorRed
    },
    success: {
      main: colours.successGreen
    }
  }
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Routes />
        </QueryClientProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);
