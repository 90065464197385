import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  DialogActions,
  DialogContentText,
  FormControlLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { useGetFoRCodes } from '../../api/forcodes';
import { useUpdateFoRAllocation } from '../../api/outputs';
import { GlobalAppContext } from '../../store/AppStore';
import { AuthContext } from '../../store/AuthStore';
import { FERAButton } from '../Button/FERAButton';
import GenericDialog from '../Dialog';
import SearchDropDown from '../SearchDropDown';
import useStyles from './styles';
import { getAssumedRole } from './SubmitReviewModal';

export const WarningModal = ({ onClose, onBack, message }) => {
  const classes = useStyles();
  return (
    <GenericDialog
      height={750}
      title="Allocate codes to this output"
      onClose={onClose}
      data-testid="edit-for-modal-warning">
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <InfoOutlinedIcon sx={{ fontSize: 67, mt: 2.5 }} />
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ pb: 0.5 }}>
            {message}
          </Typography>
        </Grid>
        <Grid item>
          <DialogContentText sx={{ maxWidth: 265, textAlign: 'center', pb: 3, m: 0 }}>
            Please reallocate percentage
          </DialogContentText>
        </Grid>
        <Grid item>
          <DialogActions>
            <FERAButton
              onClick={onBack}
              className={classes.add}
              color="red"
              variant="contained"
              aria-label="Go back to FoR allocation dialog">
              Go back
            </FERAButton>
            <FERAButton
              variant="outlined"
              onClick={onClose}
              data-testid="modal-modal-cancel-button"
              color="red"
              aria-label="Close warning dialog">
              Cancel
            </FERAButton>
          </DialogActions>
        </Grid>
      </Grid>
    </GenericDialog>
  );
};

WarningModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired
};

export const ConfirmModal = ({ primaryFoRs, isSubmitting, onSubmit, onClose, onBack }) => {
  return (
    <GenericDialog
      height={550}
      title="Allocate codes to this output"
      onClose={onClose}
      data-testid="edit-for-modal-warning">
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <InfoOutlinedIcon sx={{ fontSize: 67, mt: 2.5 }} />
        </Grid>
        <Grid item>
          <DialogContentText sx={{ maxWidth: 450, textAlign: 'center', pb: 3, m: 0 }}>
            You have changed the Main FoRs for this output. The Main FoR codes are now:{' '}
          </DialogContentText>
        </Grid>
        <Grid item>
          <DialogContentText sx={{ maxWidth: 450, textAlign: 'center', pb: 3, m: 0 }}>
            <span
              style={{
                fontWeight: 'bold'
              }}>
              Two Digit Main FoR: {primaryFoRs.twoDigitMainFor}
            </span>{' '}
            <br />
            <span
              style={{
                fontWeight: 'bold'
              }}>
              Four Digit Main FoR: {primaryFoRs.mainFor}
            </span>{' '}
          </DialogContentText>
        </Grid>

        <Grid item>
          <DialogContentText sx={{ maxWidth: 450, textAlign: 'center', pb: 3, m: 0 }}>
            This means you will no longer be able to directly edit FoR codes for this output, unless
            you are a Primary Advisor for at least one of the new Main FoR codes.{' '}
          </DialogContentText>
        </Grid>
        <Grid item>
          <DialogActions>
            <FERAButton
              onClick={onSubmit}
              color="red"
              variant="contained"
              aria-label="Confirm and submit FoR allocation code change"
              loading={isSubmitting}
              loadingPosition="start"
              startIcon={<SaveIcon />}>
              Confirm & Submit
            </FERAButton>
            <FERAButton
              variant="outlined"
              onClick={onBack}
              data-testid="modal-modal-cancel-button"
              color="red"
              aria-label="Close warning dialog">
              Back
            </FERAButton>
          </DialogActions>
        </Grid>
      </Grid>
    </GenericDialog>
  );
};

ConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  primaryFoRs: PropTypes.shape({
    twoDigitMainFor: PropTypes.string,
    mainFor: PropTypes.string
  }).isRequired,
  isSubmitting: PropTypes.bool.isRequired
};

export const padEnd = (array, minLength, fillValue = undefined) => {
  return Object.assign(new Array(minLength).fill(fillValue), array);
};

const simplify = (keypair, length, code, percentage) => {
  if (code != null) {
    let simp_code = code.substring(0, length);

    if (keypair[simp_code] == null) {
      keypair[simp_code] = percentage;
    } else {
      keypair[simp_code] = keypair[simp_code] + percentage;
    }

    return keypair;
  }
};

export const checkFor20PercentMinimium = (codes) => {
  let simplified_codes = {};

  codes.forEach(function (value) {
    if (Object.keys(value).length !== 0) {
      simplified_codes = simplify(simplified_codes, 4, value.forCode, value.percentage);
    }
  });

  let main_for = 0;

  if (simplified_codes != null) {
    for (const [key, value] of Object.entries(simplified_codes)) {
      main_for = key;

      if (value < 20) {
        return false; // we found a 4 digit code with < 20% allocation.
      }
    }
  }

  return main_for;
};

export const getPrimaryForCodes = (codes) => {
  return { twoDigitMainFor: simplifyForCode(codes, 2), mainFor: simplifyForCode(codes, 4) };
};

export const simplifyForCode = (codes, length) => {
  let simplified_codes = {};

  codes.forEach(function (value) {
    if (Object.keys(value).length !== 0) {
      simplified_codes = simplify(simplified_codes, length, value.forCode, value.percentage);
    }
  });

  let main_for = 0;
  let highest_total_percentage = 0;

  if (simplified_codes != null) {
    for (const [key, value] of Object.entries(simplified_codes)) {
      if (value > highest_total_percentage) {
        highest_total_percentage = value;
        main_for = key;
      }
    }
  }

  return main_for;
};

export const oneCodeHasMajority = (codes) => {
  let simplified_codes = {};

  codes.forEach(function (value) {
    if (Object.keys(value).length !== 0) {
      simplified_codes = simplify(simplified_codes, 4, value.forCode, value.percentage);
    }
  });

  let percentages = Object.values(simplified_codes).sort((a, b) => b - a);
  return percentages[0] !== percentages[1];
};

export const EditFoRModal = ({ onClose }) => {
  const {
    forCodeSelected: [forCode],
    globalAlert: [, setAlert]
  } = useContext(GlobalAppContext);

  const isSubmitLoading = false;
  const [warningVisible, setWarningVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [errorDetails, setErrorDetails] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const classes = useStyles();

  const {
    rightDrawer: [rightDrawerIsOpen]
  } = useContext(GlobalAppContext);
  const initialFoRCodes = padEnd(rightDrawerIsOpen?.outputAllocations || [], 3, {});
  const initialPrimaryFoRs = {
    twoDigitMainFor: rightDrawerIsOpen?.twoDigitMainFor,
    mainFor: rightDrawerIsOpen?.mainFor
  };

  const [canNominateForTop30Percent, setCanNominateForTop30Percent] = useState(false);

  const [comment, setComment] = useState('Codes updated');
  const [isEligibleForERA, setIsEligibleForERA] = useState(
    rightDrawerIsOpen?.eligible === null || typeof rightDrawerIsOpen?.eligible === 'undefined'
      ? true
      : rightDrawerIsOpen.eligible
  );

  const [nominateFor30, setNominateFor30] = useState(
    rightDrawerIsOpen?.nominate === null || typeof rightDrawerIsOpen?.nominate === 'undefined'
      ? false
      : rightDrawerIsOpen.nominate
  );
  const [commentErrorVisible, setCommentErrorVisible] = useState(false);
  const [assignedCodes, setAssignedCodes] = useState(initialFoRCodes.map((a) => ({ ...a })));

  const { data: FoRCodes, isLoading } = useGetFoRCodes([6]); // , rightDrawerIsOpen.outputCategory);
  //const { data: FoRCodes4Digit } = useGetFoRCodes([4]);
  //console.log(JSON.stringify(FoRCodes4Digit));

  const { mutate: updateFoRAllocation } = useUpdateFoRAllocation();

  const { authUser } = useContext(AuthContext);
  const assumedRole = getAssumedRole(authUser, forCode);

  useEffect(() => {
    // Example 430321
    if (
      assignedCodes.filter(
        (code) =>
          code.peerReviewCategories instanceof Array &&
          code.peerReviewCategories
            .map((c) => c.outputCategory)
            .includes(rightDrawerIsOpen.outputCategory)
      ).length > 0
    ) {
      setCanNominateForTop30Percent(true);
    } else {
      setCanNominateForTop30Percent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedCodes]);

  useEffect(() => {
    if (!isLoading) {
      FoRCodes.data.sort((a, b) => (a.forCode < b.forCode ? -1 : 1));

      const newAssignedCodes = [{}, {}, {}];
      assignedCodes.slice(0, 3).forEach((c, index) => {
        FoRCodes.data.forEach((fc) => {
          if (fc.forCode === c.forCode) {
            newAssignedCodes[index] = { ...c, peerReviewCategories: fc.peerReviewCategories };
          }
        });
      });
      setAssignedCodes(newAssignedCodes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const totalAllocated = assignedCodes
    .filter((c) => typeof c.forCode !== 'undefined')
    .reduce((accum, obj) => accum + (obj.percentage || 0), 0);

  const getOptionDisabled = (option) => {
    const res =
      assignedCodes
        .filter((c) => typeof c.forCode !== 'undefined')
        .map((a) => a.forCode)
        .indexOf(option.forCode) !== -1;
    return res;
  };

  const actionSubmit = () => {
    setIsSubmitting(true);
    updateFoRAllocation(
      {
        roleId: assumedRole,
        id: rightDrawerIsOpen.pubPk,
        comment: comment,
        allocation: assignedCodes
          .filter((c) => typeof c.forCode !== 'undefined')
          .map((c) => {
            return { ...c, eligible: isEligibleForERA, nominate: nominateFor30 };
          })
      },
      {
        onSuccess: () => {
          setAlert({
            open: true,
            message: 'Output has been successfully updated',
            severity: 'success'
          });
          onClose();
          setIsSubmitting(false);
        },
        onError: () => {
          setAlert({
            open: true,
            message: `Failed to update FoR Allocation`,
            severity: 'error'
          });
          setIsSubmitting(false);
        }
      }
    );
  };

  const onSubmit = (action, details) => {
    switch (action) {
      case 'warning':
        setWarningVisible(true);
        setErrorDetails(details);
        break;
      case 'confirm':
        setConfirmVisible(true);
        setErrorDetails(details);
        break;
      case 'submit':
        actionSubmit();
        break;
      default:
        throw new Error('unhandled action');
    }
  };

  //const getPrimaryFoRChange = (codes, initialPrimaryFoRs) => {
  //  console.log(initialPrimaryFoRs);

  // var code = codes.filter((c) => (c.forCode || '').substring(0, 4) === initialPrimaryFoR)[0];
  //  return { twoDigitMainFor: simplifyForCode(codes, 2), mainFor: simplifyForCode(codes, 4) };

  // const percentage = code?.percentage || 0;
  // if (typeof code === 'undefined') {
  //  code = (FoRCodes4Digit?.data || []).filter(
  //    (c) => (c.forCode || '').substring(0, 4) === initialPrimaryFoR
  // )[0];
  // return { ...code, percentage: 0 };
  //}
  //return { ...code, percentage: percentage };
  //};

  if (warningVisible)
    return (
      <WarningModal
        onClose={onClose}
        onBack={() => {
          setWarningVisible(false);
        }}
        message={errorDetails.message}
      />
    );

  if (confirmVisible)
    return (
      <ConfirmModal
        primaryFoRs={errorDetails.primaryFoRs}
        onSubmit={() => {
          setConfirmVisible(false);
          actionSubmit();
        }}
        isSubmitting={isSubmitting}
        onClose={onClose}
        onBack={() => {
          setConfirmVisible(false);
        }}
      />
    );

  return (
    <GenericDialog
      height={750}
      title="Allocate codes to this output"
      onClose={onClose}
      data-testid="edit-for-modal">
      <Box className={classes.modalContent}>
        <Grid container spacing={2.5} sx={{ marginBottom: 35 / 8 }}>
          <Grid item xs={8}>
            <Typography id="modal-modal-description" data-testid="modal-modal-description">
              Select your FoR Codes
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography id="modal-modal-description" data-testid="modal-modal-description">
              Output Percentage
            </Typography>
          </Grid>

          {['Add a first code', 'Add a second code', 'Add a third code'].map(
            (placeholder, index) => {
              return (
                <>
                  <Grid
                    item
                    xs={8}
                    key={index}
                    data-testid={`edit-for-code-modal-search-dropdown-${index}`}>
                    <SearchDropDown
                      key={index}
                      placeholder={placeholder}
                      getOptionLabel={(o) =>
                        o.forCode ? `${o.forCode} - ${o.forName ? o.forName : 'Unknown'}` : ''
                      }
                      options={FoRCodes?.data || []}
                      value={assignedCodes[index]}
                      onChange={(newValue) => {
                        const newAssignedCodes = [...assignedCodes];
                        newAssignedCodes[index] =
                          { ...newValue, percentage: assignedCodes[index].percentage || 0 } || {};
                        setAssignedCodes(newAssignedCodes);
                      }}
                      onClear={() => {
                        const newAssignedCodes = [...assignedCodes];
                        newAssignedCodes[index] = {};
                        setAssignedCodes(newAssignedCodes);
                      }}
                      getOptionDisabled={getOptionDisabled}
                      isDisabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <OutlinedInput
                      data-testid={`edit-for-code-modal-percentage-${index}`}
                      sx={{ width: 84 }}
                      value={
                        typeof assignedCodes[index].percentage === 'undefined' ||
                        isNaN(assignedCodes[index].percentage)
                          ? ''
                          : assignedCodes[index].percentage
                      }
                      disabled={typeof assignedCodes[index].forCode === 'undefined'}
                      onChange={(event) => {
                        var val = event.target.value;
                        if ((isNaN(val) || val.length > 3) && val !== '0') return;
                        const newAssignedCodes = [...assignedCodes];
                        newAssignedCodes[index].percentage = parseInt(val);
                        setAssignedCodes(newAssignedCodes);
                      }}
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    />
                  </Grid>
                </>
              );
            }
          )}
        </Grid>

        <div style={{ marginBottom: 26 }}>
          <FormControlLabel
            control={
              <Switch
                color="error"
                checked={isEligibleForERA}
                className={classes.switch}
                onChange={(event) => {
                  setIsEligibleForERA(event.target.checked);
                }}
                data-testid="is-eligible-for-era-switch"
              />
            }
            label="Eligible for ERA?"
          />

          <FormControlLabel
            control={
              <Switch
                disabled={!canNominateForTop30Percent}
                color="error"
                checked={nominateFor30}
                className={classes.switch}
                onChange={(event) => {
                  setNominateFor30(event.target.checked);
                }}
                data-testid="nominate-for-30-percent-switch"
              />
            }
            label="Nominate for Top 30%"
          />
        </div>

        <Typography id="modal-modal-description" data-testid="modal-modal-description">
          Comments<span className={classes.requiredStar}>*</span>
        </Typography>
        <TextField
          className={classes.textField}
          variant="outlined"
          multiline
          rows={5}
          value={comment}
          placeholder="Add your comment here"
          onChange={(e) => {
            setComment(e.target.value);
            if (comment.length === 0) {
              setCommentErrorVisible(true);
            } else {
              setCommentErrorVisible(false);
            }
          }}
          error={commentErrorVisible}
          inputProps={{ minLength: 1, maxLength: 256 }}
          data-testid="modal-modal-comment"
          sx={{ marginBottom: 28 / 8 }}
        />

        <Box className={classes.buttons}>
          <FERAButton
            data-testid={
              isSubmitLoading ? 'modal-modal-loading-button' : 'modal-modal-submit-button'
            }
            onClick={() => {
              if (comment.length === 0) {
                setCommentErrorVisible(true);
                return;
              }

              let PrimaryFors = getPrimaryForCodes(assignedCodes);

              if (
                assignedCodes
                  .filter((c) => typeof c.percentage !== 'undefined')
                  .some((c) => c.percentage === 0)
              ) {
                onSubmit('warning', {
                  message: 'Code cannot be assigned 0%'
                });
              } else if (totalAllocated < 100) {
                onSubmit('warning', {
                  message: 'Codes do not total 100% allocation '
                });
              } else if (totalAllocated > 100) {
                onSubmit('warning', {
                  message: 'Codes have greater than 100% allocation'
                });
              } else if (checkFor20PercentMinimium(assignedCodes) === false) {
                onSubmit('warning', {
                  message: 'Four-digit FoR codes must have an allocation at least 20%.'
                });
              } else if (!oneCodeHasMajority(assignedCodes)) {
                onSubmit('warning', {
                  message: 'No 4-digit code has a majority percentage'
                });
              } else if (
                typeof initialPrimaryFoRs === 'undefined' ||
                (initialPrimaryFoRs.mainFor === PrimaryFors.mainFor &&
                  initialPrimaryFoRs.twoDigitMainFor === PrimaryFors.twoDigitMainFor)
              ) {
                onSubmit('submit');
              } else {
                onSubmit('confirm', {
                  primaryFoRs: getPrimaryForCodes(assignedCodes)
                });
              }
            }}
            disabled={comment.length === 0}
            color="red"
            variant="contained"
            aria-label="Submit a comment"
            loading={isSubmitting}
            loadingPosition="start"
            sx={{ marginRight: 2 }}
            startIcon={<SaveIcon />}>
            Save & Close
          </FERAButton>
          <FERAButton
            variant="outlined"
            onClick={onClose}
            data-testid="modal-modal-cancel-button"
            color="red">
            Cancel
          </FERAButton>
        </Box>
      </Box>
    </GenericDialog>
  );
};

EditFoRModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export const EditFoRModalButton = ({ isDisabled }) => {
  const [open, setOpen] = useState(false);

  return (
    <div data-testid="edit-for-component">
      <FERAButton
        variant="contained"
        onClick={() => setOpen(true)}
        color="purple"
        disabled={isDisabled}
        data-testid="edit-for-open-modal-button">
        Edit FoR Codes
      </FERAButton>
      {open && (
        <EditFoRModal
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
    </div>
  );
};

EditFoRModalButton.propTypes = {
  isDisabled: PropTypes.bool
};
