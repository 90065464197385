import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useGetUsers } from '../../../api/users';
import { NoResults } from '../../NoResults/NoResults';
import { getOutputTableColumns } from './DataTableConfig';
import useStyles from './styles';

const UserTableView = ({ handleEdit }) => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sortModel, setSortModel] = useState([]);

  const columns = getOutputTableColumns(handleEdit);
  const { data, isLoading, isError, isSuccess, isFetching } = useGetUsers(
    page,
    pageSize,
    sortModel
  );

  return (
    <div data-testid="admin-page-user-table-view">
      {isError ? (
        <NoResults
          message="Data cannot be displayed"
          data-testid="user-admin-error"
          isError={true}
        />
      ) : (isSuccess || isFetching || isLoading) && data ? (
        <div data-testid="user-admin-data-table">
          <DataGrid
            disableVirtualization={process.env.JEST_WORKER_ID !== undefined} // NOTE: Adds performance penalty but cannot test it otherwise
            autoHeight
            pagination
            paginationMode="server"
            sortingMode="server"
            sortModel={sortModel}
            rows={data.data}
            columns={columns}
            page={page}
            pageSize={pageSize}
            rowCount={data.totalResults}
            className={classes.dataGrid}
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => {
              setPageSize(newPageSize);
            }}
            onSortModelChange={(newModel) => {
              if (JSON.stringify(newModel) !== JSON.stringify(sortModel)) {
                setSortModel(newModel);
                setPage(0);
              }
            }}
            disableColumnMenu
            hideFooterSelectedRowCount
            getRowId={(r) => r.id}
            loading={isLoading}
          />
        </div>
      ) : (
        !(isLoading || isFetching) && (
          <NoResults
            message="Your search returned no results"
            data-testid="user-admin-no-results"
          />
        )
      )}
    </div>
  );
};

UserTableView.propTypes = {
  handleEdit: PropTypes.func.isRequired
};

export default UserTableView;
