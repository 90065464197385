import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';

import { GlobalAppContext } from '../../store/AppStore';
import useStyles from './styles';

export const NoResults = ({ message, isError }) => {
  const classes = useStyles();
  const {
    globalAlert: [, setAlert]
  } = useContext(GlobalAppContext);

  useEffect(() => {
    if (isError) {
      setAlert({
        message:
          'There has been an error retrieving data from the database. Please contact the ERA Administrator.',
        open: true,
        severity: 'error'
      });
    }
  }, [isError, setAlert]);

  return (
    <div data-testid="no-results-component" className={classes.center}>
      <NotificationImportantIcon data-testid="no-results-bell-icon" className={classes.bellIcon} />
      <Typography data-testid="no-results-message">{message}</Typography>
    </div>
  );
};

NoResults.propTypes = {
  message: PropTypes.string.isRequired,
  isError: PropTypes.bool
};
