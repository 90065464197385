import makeStyles from '@mui/styles/makeStyles';

import { colours } from '../../theme/colors';
import { navigationConstant } from '../../theme/navigationStyles';

const useStyles = makeStyles(() => ({
  customFill: {
    color: colours.primaryGrey
  },
  title: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  body: {
    marginTop: 189,
    textAlign: 'center',
    fontFamily: 'inherit',
    fontSize: 16,
    minHeight: 36
  },

  logo: {
    height: 25,
    paddingLeft: 25,
    paddingRight: 137.343
  },
  topBar: {
    width: '100%',
    height: navigationConstant.topBarHeight,
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: colours.white,
    boxShadow:
      '0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)'
  },

  headerTitleContainer: {
    width: '100%',
    minHeight: 102,
    marginTop: navigationConstant.topBarHeight,
    position: 'fixed',
    textAlign: 'center',
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: colours.secondaryGrey
  },
  headerTitle: {
    position: 'absolute',
    top: 60,
    left: '50%',
    transform: 'translate(-50%, 0)',
    fontFamily: 'inherit',
    fontSize: 22,
    minHeight: 36
  },
  contactButton: {
    textTransform: 'none',
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    backgroundColor: 'unset',
    color: colours.primaryRed,
    borderColor: colours.primaryRed,
    '&:hover': {
      borderColor: 'unset',
      color: colours.primaryRed
    },
    height: 42,
    width: 210,
    padding: '8, 22, 8, 22',
    marginTop: 39
  },
  loginButton: {
    textTransform: 'none',
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    backgroundColor: colours.primaryRed,
    color: colours.white,
    '&:hover': {
      backgroundColor: colours.primaryRed,
      color: colours.white
    },
    marginRight: 50,
    height: 42,
    width: 210,
    padding: '8, 22, 8, 22',
    marginTop: 39
  }
}));

export default useStyles;
