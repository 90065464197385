import makeStyles from '@mui/styles/makeStyles';

import { colours } from '../../theme/colors';
import { navigationConstant } from '../../theme/navigationStyles';

const useStyles = makeStyles(() => ({
  customFill: {
    color: colours.primaryGrey
  },
  title: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  body: {
    marginTop: 189,
    textAlign: 'center',
    fontFamily: 'inherit',
    fontSize: 16,
    minHeight: 36
  },

  logo: {
    height: 25,
    paddingLeft: 25,
    paddingRight: 137.343
  },
  topBar: {
    width: '100%',
    height: navigationConstant.topBarHeight,
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: colours.white,
    boxShadow:
      '0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)'
  },

  headerTitleContainer: {
    width: '100%',
    minHeight: 102,
    marginTop: navigationConstant.topBarHeight,
    position: 'fixed',
    textAlign: 'center',
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: colours.secondaryGrey
  },
  headerTitle: {
    position: 'absolute',
    top: 60,
    left: '50%',
    transform: 'translate(-50%, 0)',
    fontFamily: 'inherit',
    fontSize: 22,
    minHeight: 36
  },
  contactButton: {
    marginTop: 39
  }
}));

export default useStyles;
