import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';

import { useComments } from '../../../api/comments';
import { getSingleOutput } from '../../../api/outputs';
import { GlobalAppContext } from '../../../store/AppStore';
import { DropdownAccordion } from '../../Dropdown/DropdownAccordion/DropdownAccordion';
import { Loader } from '../../Loader/Loader';
import { NoResults } from '../../NoResults/NoResults';
import { getCommentFilters } from '../../SearchBar/OptionsConfig';
import { DataTable } from '../../Table/DataTable';
import { getCommentTableColumns } from '../../Table/DataTableConfig';
import useStyles from './styles';

export const WorkspaceComments = () => {
  const {
    rightDrawer: [, setRightDrawerIsOpen],
    forCodeSelected: [forCode],
    outputSort: [forSort],
    pageNum: [page],
    pageSize: [size],
    notifications: [notifications],
    globalAlert: [, setAlert],
    actionedComments: [actionedComments, setActionedComments]
  } = useContext(GlobalAppContext);

  //have to be kept as state due to row highlighting
  const [columns, setColumns] = useState();

  const classes = useStyles();
  const [filters, setFilters] = useState([]);

  const { data, isLoading, isError, isSuccess, isFetching, refetch } = useComments(
    page,
    size,
    forCode.FoRCode,
    JSON.parse(forSort),
    filters
  );

  const handleOutputClick = (outputId) => {
    getSingleOutput(outputId, forCode.FoRCode, setRightDrawerIsOpen, setAlert);
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, notifications, forCode.FoRCode]); // will update in-tandem with notifs

  useEffect(() => {
    setFilters([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forCode.FoRCode]);

  const UIfilters = getCommentFilters();

  useEffect(() => {
    let newActionedComments = {};
    data?.data?.forEach((e) => {
      newActionedComments[e.id] = e.actioned;
    });
    setActionedComments(newActionedComments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setColumns(getCommentTableColumns(handleOutputClick, actionedComments, setActionedComments));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionedComments]);

  const highlightActionedRows = (param) =>
    actionedComments[param.row.id] ? 'actionedCommentRow' : 'commentRow';

  return (
    <div data-testid="comments-tab-container">
      <Grid container justifyContent="flex-end">
        <DropdownAccordion
          lists={UIfilters}
          buttonName="filters"
          buttonLocation="comment"
          selected={filters}
          setSelected={setFilters}
          className={classes.accordion}
        />
      </Grid>

      {isLoading || isFetching ? (
        <Loader data-testid="comments-tab-loader" />
      ) : isError ? (
        <NoResults
          message="Data cannot be displayed"
          data-testid="comments-tab-error"
          isError={true}
        />
      ) : isSuccess && data && data.data && data.data.length > 0 ? (
        <DataTable
          data-testid="comments-tab-data-table"
          rows={data.data}
          columns={columns}
          getRowId={(row) => row.id}
          totalCount={data.totalResults}
          isCommentTab={true}
          getRowClassName={highlightActionedRows}
        />
      ) : (
        <NoResults message="You have no comments." data-testid="comments-tab-no-results" />
      )}
    </div>
  );
};
