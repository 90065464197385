import { Typography } from '@mui/material';
import React, { useContext, useState } from 'react';

import { DBErrorAlert, getWorkspaceExport, MissingFoRAlert } from '../../../api/outputs';
import { GlobalAppContext } from '../../../store/AppStore';
import { AuthContext } from '../../../store/AuthStore';
import { FERAButton } from '../../Button/FERAButton';
import { exporting } from '../../PageHeader/constants';
import { PageHeader } from '../../PageHeader/PageHeader';
import { WorkspaceTabs } from '../WorkspaceComponents/WorkspaceTabs';
import useStyles from './styles';

export const Workspace = () => {
  const classes = useStyles();
  const {
    forCodeSelected: [forCode],
    globalAlert: [, setAlert]
  } = useContext(GlobalAppContext);
  const { authUser } = useContext(AuthContext);

  const [isExtractLoading, setIsExtractLoading] = useState(false);

  const onClickExport = () => {
    if (typeof forCode.FoRCode === 'undefined') {
      setAlert(MissingFoRAlert);
      return;
    }
    setIsExtractLoading(true);
    getWorkspaceExport(forCode.FoRCode)
      .then(() => {
        setAlert({
          open: true,
          message: 'Workspace has been successfully exported',
          severity: 'success'
        });
        setIsExtractLoading(false);
      })
      .catch(() => {
        setAlert(DBErrorAlert);
        setIsExtractLoading(false);
      });
  };

  const getTitle = (code) => {
    return code.FoRCode && code.FoRCodeName ? `${forCode.FoRCode} - ${forCode.FoRCodeName}` : '';
  };

  if (authUser.userInformation.allAssignedForCodes.length === 0) {
    return (
      <div data-testid="workspace-component-not-found" className={classes.workspace}>
        <div className={classes.notFoundWorkspaceHeader}>
          <Typography variant="h1" align="center">
            Looks like you need a workspace assigned.
          </Typography>
        </div>
        <div
          data-testid="workspace-page-content-not-found"
          style={{
            maxWidth: 654,
            textAlign: 'center',
            margin: '0 auto',
            marginTop: 21
          }}>
          <Typography variant="body1">
            To access and assign FoR codes to outputs you need to be assigned a FoR code that can be
            accessed here. It seems you have no access to any code.
          </Typography>
          <br />
          <Typography variant="body1">
            You will need to contact an ERA Administrator to get a code assigned, if you are the
            responsible FoR Advisor.
          </Typography>
          <FERAButton
            href="mailto:era.ei@rmit.edu.au"
            color="red"
            variant="contained"
            sx={{ marginTop: 3 }}
            aria-label="Contact the Admin Team">
            Contact the Admin Team
          </FERAButton>
        </div>
      </div>
    );
  }
  return (
    <div data-testid="workspace-component" className={classes.workspace}>
      <PageHeader
        location="My Outputs"
        title={getTitle(forCode)}
        data-testid="workspace-page-header"
        actionType={exporting}
        actionLoading={isExtractLoading}
        action={onClickExport}
      />
      <div data-testid="workspace-page-content">
        <WorkspaceTabs data-testid="workspace-container-tabs" />
      </div>
    </div>
  );
};
