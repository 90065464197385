import React, { useContext } from 'react';

import {
  useGetWorkspaceCategory,
  useGetWorkspaceOutletResult,
  useGetWorkspaceSummaryStatus
} from '../../../api/summary';
import { GlobalAppContext } from '../../../store/AppStore';
import { SummaryContent } from '../../DataSummary/DataSummary';
import { Loader } from '../../Loader/Loader';

export const WorkspaceSummary = () => {
  const {
    forCodeSelected: [forCode]
  } = useContext(GlobalAppContext);

  // const { data, isLoading } = useGetWorkspaceSummary(forCode.FoRCode);
  const summaryStatusQuery = useGetWorkspaceSummaryStatus(forCode.FoRCode);
  const summaryCategoryQuery = useGetWorkspaceCategory(forCode.FoRCode);
  const summaryOutletResultQuery = useGetWorkspaceOutletResult(forCode.FoRCode);

  if (
    summaryStatusQuery.isLoading &&
    summaryCategoryQuery.isLoading &&
    summaryOutletResultQuery.isLoading
  )
    return <Loader data-testid="workspace-summary-loader" />;
  return (
    <div data-testid="workspace-summary-container">
      <SummaryContent
        summaryStatusQuery={summaryStatusQuery}
        summaryCategoryQuery={summaryCategoryQuery}
        summaryOutletResultQuery={summaryOutletResultQuery}
      />
    </div>
  );
};
