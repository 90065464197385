import makeStyles from '@mui/styles/makeStyles';

import { navigationConstant } from '../../theme/navigationStyles';

const useStyles = makeStyles(() => ({
  content: {
    marginTop: navigationConstant.topBarHeight,
    marginLeft: navigationConstant.sideBarWidth,
    width: '100%'
  },
  root: {
    display: 'flex'
  }
}));

export default useStyles;
