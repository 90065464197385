import React from 'react';

import { Workspace } from '../components/Workspaces/Workspace/Workspace';
import { NavigableLayout } from '../layouts/NavigableLayout/NavigableLayout';
import { ViewWrapper } from './ViewWrapper';

export const WorkspacesView = () => {
  return (
    <ViewWrapper>
      <NavigableLayout>
        <Workspace />
      </NavigableLayout>
    </ViewWrapper>
  );
};
