import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import { colours } from '../../theme/colors';
import { navigationConstant } from '../../theme/navigationStyles';
import { zIndexes } from '../../theme/zIndexes';

export const StyledTooltip = withStyles({
  arrow: {
    color: colours.primaryBlue,
    left: '-18px'
  },
  tooltip: {
    backgroundColor: colours.primaryBlue,
    left: '-18px'
  },
  popper: {
    zIndex: zIndexes.sideBar
  }
})(Tooltip);

const useStyles = makeStyles(() => ({
  badgeIcon: {
    '& .MuiBadge-badge': {
      backgroundColor: colours.primaryRed,
      color: colours.white
    }
  },
  menuActive: {
    backgroundColor: colours.primaryRed
  },
  menuHover: {
    background: colours.primaryBlue,
    '&:hover': {
      backgroundColor: colours.lightBlue
    }
  },
  menuIcon: {
    color: colours.white,
    width: navigationConstant.sideBarWidth,
    height: 65,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    border: 'none'
  },
  sideBar: {
    left: 0,
    bottom: 0,
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: zIndexes.sideBar,
    overflowWrap: 'break-word',
    color: colours.white,
    background: colours.primaryBlue,
    top: navigationConstant.topBarHeight,
    width: navigationConstant.sideBarWidth
  },
  slideSideBar: {
    '& .MuiDrawer-paper': {
      width: 260,
      marginTop: navigationConstant.topBarHeight,
      marginLeft: navigationConstant.sideBarWidth,
      backgroundColor: colours.secondaryGrey
    }
  },
  slideSideBarBtn: {
    width: '100%',
    whiteSpace: 'normal',
    textAlign: 'left',
    '& .MuiButton-label': {
      justifyContent: 'space-between'
    },
    '&.MuiButton-root': {
      textTransform: 'none',
      justifyContent: 'flex-start'
    },
    '&.MuiButton-text': {
      '&:hover': {
        backgroundColor: colours.lightGrey
      }
    }
  },
  slideSideBarBlueBtn: {
    color: colours.primaryBlue,
    padding: '3px 18px'
  },
  slideSideBarHeader: {
    color: colours.primaryBlue,
    padding: '29px 16px 13px 26px',
    backgroundColor: colours.white
  },
  slideSideBarListItem: {
    backgroundColor: colours.secondaryGrey,
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover': {
      backgroundColor: colours.lightGrey
    }
  }
}));

export default useStyles;
