import makeStyles from '@mui/styles/makeStyles';

import { colours } from '../../theme/colors';

const useStyles = makeStyles(() => ({
  allocationBadge: {
    width: 'inherit',
    display: 'inherit',
    justifyContent: 'inherit',
    marginLeft: 15,
    alignItems: 'center'
  },
  allocationHeader: {
    display: 'flex',
    flexDirection: 'row'
  },
  forStaticTable: {
    marginBottom: 18
  },
  submitReviewStaticSwitches: {
    marginBottom: 50
  },
  requiredStar: { color: colours.primaryRed },
  switch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: colours.primaryRed,
      '&:hover': {
        backgroundColor: colours.primaryRedAlpha
      }
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: colours.primaryRed
    }
  },
  textField: {
    paddingTop: 14,
    width: 351,
    height: 130
  },
  dropdown: {
    display: 'flex',
    flexDirection: 'column',
    width: 351,
    paddingTop: 27
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 27
  },
  loader: {
    '&.MuiCircularProgress-colorPrimary': {
      color: colours.primaryGrey
    }
  },
  modalContent: {
    paddingTop: 30
  },
  approveHeader: {
    fontWeight: 500,
    fontFamily: 'Museo',
    fontSize: 20,
    justifyContent: 'center',
    display: 'flex',
    marginTop: 14,
    marginBottom: 14
  },
  approveInfor: {
    fontWeight: 400,
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 47
  },
  center: {
    justifyContent: 'center',
    display: 'flex',
    margin: 'auto',
    width: '100%'
  },
  mainBlue: {
    color: colours.primaryBlue
  }
}));

export default useStyles;
