import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import useStyles from './styles';

const FoRAccessFieldSet = ({ roleTypes, setRoleTypes, error }) => {
  const roleTypesWithLabel = [
    { type: 'TWO_DIGITS_ADVISOR', label: '2-digit Advisor' },
    { type: 'FOUR_DIGITS_ADVISOR', label: '4-digit Advisor' },
    { type: 'ADMINISTRATOR', label: 'Administrator' },
    { type: 'UNIVERSITY_LEAD', label: 'University Lead' }
  ];

  const classes = useStyles();

  return (
    <FormControl component="fieldset" error={error} data-testid="for-access-field-set">
      {roleTypesWithLabel.map((role, index) => {
        return (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={roleTypes.includes(role.type)}
                className={classes.checkbox}
                data-testid={`for-access-field-set-checkbox-${role.type.toLowerCase()}`}
              />
            }
            label={role.label}
            onChange={(event) => {
              const newRoles = event.target.checked
                ? [...roleTypes, role.type]
                : roleTypes.filter((r) => r !== role.type);
              setRoleTypes(newRoles);
            }}
          />
        );
      })}
      {error && (
        <FormHelperText id="component-error-text">
          A user must have at least one role
        </FormHelperText>
      )}
    </FormControl>
  );
};

FoRAccessFieldSet.propTypes = {
  roleTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  setRoleTypes: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired
};

export default FoRAccessFieldSet;
