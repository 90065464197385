import React from 'react';

import { DataSummary } from '../components/DataSummary/DataSummary';
import { NavigableLayout } from '../layouts/NavigableLayout/NavigableLayout';
import { ViewWrapper } from './ViewWrapper';

export const DataSummaryView = () => {
  return (
    <ViewWrapper>
      <NavigableLayout>
        <DataSummary />
      </NavigableLayout>
    </ViewWrapper>
  );
};
