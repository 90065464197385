import React from 'react';

import { UnauthorizedComponent } from '../components/Unauthorized/UnauthorizedComponent';
import { DefaultLayout } from '../layouts/DefaultLayout/DefaultLayout';
import { ViewWrapper } from './ViewWrapper';

export const UnauthorizedView = () => {
  return (
    <ViewWrapper>
      <DefaultLayout>
        <UnauthorizedComponent />
      </DefaultLayout>
    </ViewWrapper>
  );
};
