import PropTypes from 'prop-types';
import React from 'react';

import TextInput from '../../TextInput';
import useStyles from './styles';

const DetailsInput = ({ isError, errorMessage, label, value, setValue, disabled }) => {
  const classes = useStyles();

  return (
    <TextInput
      data-testid="details-input"
      error={isError}
      errorMessage={errorMessage}
      label={label}
      value={value}
      className={classes.inputField}
      onChange={(event) => {
        setValue(event.target.value);
      }}
      required
      autoComplete="off"
      disabled={disabled}
    />
  );
};

DetailsInput.propTypes = {
  isError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};
export default DetailsInput;
