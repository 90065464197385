import DoneIcon from '@mui/icons-material/Done';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { useGetOutputAdvisors } from '../../../api/outputs';
import {
  getUserActionButtons,
  getUserRolePermission,
  getWarningText,
  isAllApproved,
  isApproved
} from '../../../helpers/allocationHelpers';
import { GlobalAppContext } from '../../../store/AppStore';
import { AuthContext } from '../../../store/AuthStore';
import { colours } from '../../../theme/colors';
import { StatusCell } from '../../Table/DataTableHelpers';
import useStyles from './styles';

const BetterBadge = ({
  forCode,
  forCodeLength,
  contacts,
  index,
  isApproved,
  activeAllocation,
  setActiveAllocation,
  outputTitle
}) => {
  const classes = useStyles();

  const defaultContact = {
    firstName: 'NA, please contact',
    lastName: 'Admin',
    email: 'era.ei@rmit.edu.au'
  };
  const defaultMailTo = `mailto:${
    defaultContact.email
  }?subject=AUTO: ${forCodeLength} Digit FoR code without any primary advisors assigned - ${forCode.substring(
    0,
    forCodeLength
  )}&body=Hi ${
    defaultContact.lastName
  },\r\n\r\nPlease could you let me know who owns the code ${forCode.substring(
    0,
    forCodeLength
  )} as the application shows that there is no primary owner. 
  I found this while looking at the 6 digit FoR code - ${forCode} and the output with title "${outputTitle}". 
  Would you also be able to add this person into the application for future reference?\r\n\r\n
  Thanks,`;
  const isDefault = contacts.length === 0;

  const mailTo = (contact) =>
    `mailto:${contact.email}?subject=AUTO: Information required for output within FoR code - ${forCode}&body=Hi ${contact.firstName},
    \r\n\r\nPlease could you let me know more information for the FoR code ${forCode} and the output with title "${outputTitle}"?
    \r\n\r\nThanks,`;

  const emailListVisible =
    activeAllocation?.index === index && activeAllocation?.approval === forCodeLength;

  return (
    <>
      <IconButton
        size="small"
        disableRipple
        className={classes.FoRStatus}
        style={{
          color: isApproved ? colours.successGreen : colours.primaryBlue,
          boxShadow: emailListVisible
            ? `0 0 0 2px ${isApproved ? colours.successGreen : colours.primaryBlue}`
            : 'unset'
        }}
        onClick={(event) => {
          setActiveAllocation({
            index: index,
            approval: forCodeLength,
            anchor: event.currentTarget
          });
        }}>
        {isApproved ? <DoneIcon /> : <RemoveIcon />}
      </IconButton>
      {emailListVisible && (
        <Menu
          id="basic-menu"
          style={{ zIndex: 1500 }}
          PaperProps={{
            style: {
              transform:
                forCodeLength === 2
                  ? 'translateX(-180px) translateY(5px)'
                  : 'translateX(-80px) translateY(5px)'
            }
          }}
          anchorEl={activeAllocation.anchor}
          open={true}
          onClose={() => setActiveAllocation(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}>
          {(isDefault ? [defaultContact] : contacts).map((contact) => {
            return (
              <MenuItem
                key={contact.email}
                onClick={() => {
                  setActiveAllocation(null);
                }}>
                <a
                  href={encodeURI(isDefault ? defaultMailTo : mailTo(contact))}
                  style={{ textDecoration: 'none' }}>
                  <EmailOutlinedIcon className={classes.emailIcon} />
                  <span className={classes.emailName}>
                    {contact.firstName} {contact.lastName}
                  </span>
                </a>
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </>
  );
};

BetterBadge.propTypes = {
  forCode: PropTypes.string.isRequired,
  contacts: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  isApproved: PropTypes.bool.isRequired,
  activeAllocation: PropTypes.object.isRequired,
  setActiveAllocation: PropTypes.func.isRequired,
  forCodeLength: PropTypes.number.isRequired,
  outputTitle: PropTypes.string
};

export const FoRAllocation = ({
  pubPk,
  status,
  mainFor,
  twoDigitMainFor,
  forCodes,
  outputAllocations,
  title
}) => {
  const classes = useStyles();
  const { authUser } = useContext(AuthContext);
  const roles = authUser?.userInformation?.userRoles;
  const {
    forCodeSelected: [forCode]
  } = useContext(GlobalAppContext);
  const forCodeViewing = forCode?.FoRCode;
  const [activeAllocation, setActiveAllocation] = useState(null);

  const { data } = useGetOutputAdvisors(pubPk);
  const contacts = data || [];

  const userRolePermission = getUserRolePermission(
    mainFor,
    twoDigitMainFor,
    forCodes,
    roles,
    forCodeViewing,
    pubPk,
    authUser,
    forCode
  );
  const approved = isApproved(outputAllocations, userRolePermission);
  const allApproved = isAllApproved(outputAllocations, mainFor);
  const warningText = getWarningText(status, approved, allApproved, userRolePermission);

  const emptyRowsCount = () => {
    if (outputAllocations) {
      const count = 3 - outputAllocations.length;
      if (count === 2) {
        return [0, 1];
      }
      if (count === 1) {
        return [0];
      }
      return null;
    }
    return [0, 1, 2];
  };

  const getEmptyRows = (id) => {
    return (
      <TableRow key={id}>
        <TableCell className={classes.forCodeCol}>-</TableCell>
        <TableCell>-</TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="center"></TableCell>
      </TableRow>
    );
  };

  return (
    <div data-testid="for-allocation-component" className={classes.allocationWrapper}>
      <div data-testid="for-allocation-header" className={classes.allocationHeader}>
        <Typography varaint="h6" style={{ fontWeight: 700 }}>
          FoR Allocation
        </Typography>
        <StatusCell
          status={status}
          className={classes.allocationBadge}
          data-testid="for-allocation-status"
        />
      </div>
      <div data-testid="for-allocation-percentage">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.forCodeCol}>
                <Typography>FoR Code</Typography>
              </TableCell>
              <TableCell>%</TableCell>
              <TableCell align="center" className={classes.approvalCol}>
                <Typography variant="body2">2-digit approval</Typography>
              </TableCell>
              <TableCell align="center" className={classes.approvalCol}>
                <Typography variant="body2">4-digit approval</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {outputAllocations?.map((allocation, index) => (
              <TableRow key={allocation.forCode}>
                <TableCell className={classes.forCodeCol}>
                  {allocation.forCode} - {allocation.forName}
                </TableCell>
                <TableCell>{allocation.percentage}%</TableCell>
                <TableCell align="center">
                  <BetterBadge
                    outputTitle={title}
                    forCode={allocation.forCode}
                    contacts={contacts.filter(
                      (contact) => contact.forCode === allocation.forCode.substring(0, 2)
                    )}
                    index={index}
                    isApproved={allocation.twoDigitsApproval}
                    activeAllocation={activeAllocation}
                    setActiveAllocation={setActiveAllocation}
                    forCodeLength={2}
                  />
                </TableCell>
                <TableCell align="center">
                  <BetterBadge
                    forCode={allocation.forCode}
                    contacts={contacts.filter(
                      (contact) => contact.forCode === allocation.forCode.substring(0, 4)
                    )}
                    index={index}
                    isApproved={allocation.fourDigitsApproval}
                    activeAllocation={activeAllocation}
                    setActiveAllocation={setActiveAllocation}
                    forCodeLength={4}
                  />
                </TableCell>
              </TableRow>
            ))}
            {emptyRowsCount()?.map((index) => getEmptyRows(index))}
          </TableBody>
        </Table>
        <br />
        <Typography display="inline" variant="body2">
          &nbsp;&nbsp;&nbsp;&nbsp;<strong>Main four-digit FoR:</strong>
        </Typography>
        <Typography display="inline" variant="body2">
          <span> {mainFor}</span>
        </Typography>
        <br />
        <Typography display="inline" variant="body2">
          &nbsp;&nbsp;&nbsp;&nbsp;<strong>Main two-digit FoR:</strong>
        </Typography>
        <Typography display="inline" variant="body2">
          <span>&nbsp;&nbsp;{twoDigitMainFor}</span>
        </Typography>
      </div>
      {warningText !== null && (
        <div data-testid="for-allocation-warning-text" className={classes.warningText}>
          <Typography variant="body2">{warningText}</Typography>
        </div>
      )}
      <div data-testid="for-allocation-action-buttons" className={classes.allocationBtn}>
        {getUserActionButtons(status, userRolePermission, approved, allApproved)}
      </div>
    </div>
  );
};

FoRAllocation.propTypes = {
  pubPk: PropTypes.any,
  status: PropTypes.string,
  mainFor: PropTypes.string,
  twoDigitMainFor: PropTypes.string,
  journalForCodes: PropTypes.string,
  forCodes: PropTypes.string,
  outputAllocations: PropTypes.array,
  title: PropTypes.string
};
