import { Badge, Box, Typography } from '@mui/material';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { useToggleCommentActioned } from '../../api/comments';
import { useGetCommentor } from '../../api/user';
import { FERAButton } from '../Button/FERAButton';
import { FERALink } from '../Link/FERALink';
import useStyles, { StyledTitleTooltip, StyledTooltip } from './styles';

const isExpectedStatus = (expected, actual) => {
  return expected === actual;
};

export const Top30Badge = () => {
  const classes = useStyles();
  return (
    <div style={{ margin: '0 auto' }}>
      <Badge
        aria-label="top 30 percent"
        variant="dot"
        color="primary"
        className={classes.badgePurple}
      />
    </div>
  );
};

export const StatusCell = ({ status, ...other }) => {
  const classes = useStyles();
  return (
    <div className={classes.badgeContainer} {...other}>
      <Badge
        badgeContent={status}
        aria-label="status"
        color="primary"
        className={clsx(classes.badge, {
          [classes.badgeGrey]:
            isExpectedStatus('Open', status) || isExpectedStatus('Modified', status),
          [classes.badgeGreen]: isExpectedStatus('Committed', status)
        })}
      />
    </div>
  );
};
StatusCell.propTypes = {
  status: PropTypes.string.isRequired
};

export const TitleCell = ({ title, onClick }) => {
  const classes = useStyles();
  return (
    <StyledTitleTooltip title={title} placement="right" arrow>
      <Box
        component="span"
        sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        aria-label={title}
        className={classes.tooltipText}
        onClick={onClick}>
        <FERALink sx={{ paddingTop: 18, paddingBottom: 18 }}>{title}</FERALink>
      </Box>
    </StyledTitleTooltip>
  );
};
TitleCell.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export const ContributorsCell = ({ names, onClick }) => {
  const classes = useStyles();
  return (
    <StyledTooltip title={names} placement="bottom" arrow>
      <Box
        component="span"
        sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        role="tooltip"
        aria-label="contributors are"
        className={classes.tooltipText}
        onClick={onClick}>
        {names}
      </Box>
    </StyledTooltip>
  );
};
ContributorsCell.propTypes = {
  names: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export const TooltipCell = ({ label, value, onClick }) => {
  const classes = useStyles();
  return (
    <StyledTooltip title={value} placement="bottom" arrow>
      <Box
        component="span"
        sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        role="tooltip"
        aria-label={`${label} is `}
        className={classes.tooltipText}
        onClick={onClick}>
        {value}
      </Box>
    </StyledTooltip>
  );
};
TooltipCell.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export const SingleCell = ({ value, label, customStyles = undefined }) => {
  const classes = useStyles();
  return (
    <Typography variant="body2" className={classes.singleCell} aria-label={label} sx={customStyles}>
      {value}
    </Typography>
  );
};
SingleCell.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string.isRequired,
  customStyles: PropTypes.object
};

export const ForCodesCell = ({ value, label }) => {
  const classes = useStyles();
  return (
    <Typography variant="body2" className={classes.forCodesCell} aria-label={label}>
      {value}
    </Typography>
  );
};
ForCodesCell.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string.isRequired
};

export const getPreference = (preferred) => {
  if (typeof preferred === 'undefined' || preferred === null)
    return {
      field: undefined,
      header: undefined,
      isHidden: true
    };

  const fieldName = preferred?.flatMap;
  return {
    field: fieldName,
    header: preferred.name,
    isHidden: typeof fieldName === 'undefined' || preferred?.id === 1
  };
};

getPreference.PropTypes = {
  preferred: PropTypes.shape({
    flatMap: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string.isRequired
  })
};

export const CommentorCell = ({ userId, onClick }) => {
  const classes = useStyles();
  const { data } = useGetCommentor(userId, 3000);
  const name = data?.fullName;
  return (
    <StyledTooltip title={name} placement="bottom" arrow>
      <Box
        component="span"
        sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        role="tooltip"
        aria-label="commentor is"
        className={classes.tooltipText}
        onClick={onClick}>
        {name}
      </Box>
    </StyledTooltip>
  );
};

CommentorCell.propTypes = {
  userId: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

export const ActionButtonCell = ({
  commentNotificationId,
  actionedComments,
  setActionedComments
}) => {
  const { mutate: toggleCommentActioned, isLoading } = useToggleCommentActioned();

  const handleClick = (e) => {
    e.stopPropagation();
    toggleCommentActioned(
      {
        commentNotificationId,
        actioned: actionedComments[commentNotificationId]
      },
      {
        onSuccess: (res) => {
          if (res.data.actioned !== null) {
            setActionedComments({
              ...actionedComments,
              [commentNotificationId]: res.data.actioned
            });
          }
        }
      }
    );
  };

  return (
    <FERAButton
      onClick={handleClick}
      color={actionedComments[commentNotificationId] ? 'purple' : 'red'}
      variant="outlined"
      size="small"
      sx={{ maxHeight: '30px', width: '70%' }}
      loading={isLoading}>
      {actionedComments[commentNotificationId] ? 'Actioned' : 'For Action'}
    </FERAButton>
  );
};

ActionButtonCell.propTypes = {
  commentNotificationId: PropTypes.number,
  actionedComments: PropTypes.object,
  setActionedComments: PropTypes.func.isRequired
};

export const DateAddedCell = ({ dateAdded, onClick }) => {
  const classes = useStyles();
  return (
    <StyledTooltip title={dateAdded} placement="bottom" arrow>
      <Box
        component="span"
        sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        role="tooltip"
        aria-label="Date when the comment was added"
        className={classes.tooltipText}
        onClick={onClick}>
        {dateAdded}
      </Box>
    </StyledTooltip>
  );
};

DateAddedCell.propTypes = {
  dateAdded: PropTypes.instanceOf(moment).isRequired,
  onClick: PropTypes.func.isRequired
};
