import AssessmentIcon from '@mui/icons-material/Assessment';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SearchIcon from '@mui/icons-material/Search';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { defaults, GlobalAppContext } from '../../store/AppStore';
import { AuthContext } from '../../store/AuthStore';
import useStyles, { StyledTooltip } from './styles';

export const SideBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    slideBar: [slideLeftBarIsOpen, setSlideLeftBarIsOpen],
    pageNum: [, setPage],
    pageSize: [, setSize]
  } = useContext(GlobalAppContext);

  const { authUser } = useContext(AuthContext);

  const isActiveFromUrl = (current) => {
    if (window.location && window.location.pathname) {
      const actualPathName = window.location.pathname.substr(1);
      return current === actualPathName;
    }
  };

  const handleWorkspaceClick = () => {
    if (window.location.pathname === '/workspace') {
      // ie we are toggling
      history.push(`/workspace?slideLeftBarIsOpen=${!slideLeftBarIsOpen}`);
      setSlideLeftBarIsOpen(!slideLeftBarIsOpen);
    } else {
      // moving to the workspace
      history.push('/workspace?slideLeftBarIsOpen=true');
    }
  };

  const handleClick = (pathname) => {
    history.push(`/${pathname}`);
    setPage(defaults.pagination.page);
    setSize(defaults.pagination.pageSize);
    setSlideLeftBarIsOpen(false);
  };

  return (
    <nav
      role="navigation"
      aria-label="Main-menu"
      className={classes.sideBar}
      data-testid="left-side-bar">
      <StyledTooltip title="My Outputs" placement="right" arrow>
        <button
          aria-label="View My Outputs"
          onClick={handleWorkspaceClick}
          data-testid="left-side-bar-workspace"
          className={clsx(classes.menuIcon, {
            [classes.menuActive]: isActiveFromUrl('workspace'),
            [classes.menuHover]: !isActiveFromUrl('workspace')
          })}>
          <BookmarkIcon data-testid="left-side-bar-workspace-icon" />
        </button>
      </StyledTooltip>
      <StyledTooltip title="Search all Outputs" placement="right" arrow>
        <button
          aria-label="Search all Outputs"
          onClick={() => handleClick('outputs')}
          data-testid="left-side-bar-search-outputs"
          className={clsx(classes.menuIcon, {
            [classes.menuActive]: isActiveFromUrl('outputs'),
            [classes.menuHover]: !isActiveFromUrl('outputs')
          })}>
          <SearchIcon data-testid="left-side-bar-search-outputs-icon" />
        </button>
      </StyledTooltip>
      <StyledTooltip title="View ERA Data" placement="right" arrow>
        <button
          aria-label="View ERA data Summary"
          onClick={() => handleClick('summary')}
          data-testid="left-side-bar-era-summary"
          className={clsx(classes.menuIcon, {
            [classes.menuActive]: isActiveFromUrl('summary'),
            [classes.menuHover]: !isActiveFromUrl('summary')
          })}>
          <AssessmentIcon data-testid="left-side-bar-era-summary-icon" />
        </button>
      </StyledTooltip>
      {authUser.userIsAdmin && (
        <StyledTooltip title="Administration" placement="right" arrow>
          <button
            aria-label="View administration page"
            onClick={() => handleClick('admin')}
            data-testid="left-side-bar-admin-page"
            className={clsx(classes.menuIcon, {
              [classes.menuActive]: isActiveFromUrl('admin'),
              [classes.menuHover]: !isActiveFromUrl('admin')
            })}>
            <PersonAddIcon data-testid="left-side-bar-admin-page-icon" />
          </button>
        </StyledTooltip>
      )}
    </nav>
  );
};
