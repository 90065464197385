import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  searchAllOutputsContainer: {
    maxWidth: 1490,
    minWidth: 1248,
    margin: '0 auto',
    paddingTop: 31
  }
}));

export default useStyles;
