import { Box } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { gridPaginationSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import * as React from 'react';

import { useGetDataRefresh } from '../../api/dq';
import { colours } from '../../theme/colors';

export const DataTableFooter = ({ rowCount }) => {
  const apiRef = useGridApiContext();
  const paginationState = useGridSelector(apiRef, gridPaginationSelector);
  const { data: formattedDate } = useGetDataRefresh();
  return (
    <div>
      <Box ml={1} sx={{ paddingTop: 2.5, float: 'left', color: colours.primaryGrey }}>
        <span>Last updated: {formattedDate}</span>
      </Box>
      <TablePagination
        component="div"
        count={rowCount}
        page={paginationState.page}
        rowsPerPage={paginationState.pageSize}
        onPageChange={(event, value) => apiRef.current.setPage(value)}
        onRowsPerPageChange={(event) => {
          apiRef.current.setPageSize(parseInt(event.target.value, 10));
        }}
      />
    </div>
  );
};

DataTableFooter.propTypes = {
  rowCount: PropTypes.number.isRequired
};
