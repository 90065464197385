import CloseIcon from '@mui/icons-material/Close';
import { Button, Drawer } from '@mui/material';
import React, { useContext } from 'react';

import { GlobalAppContext } from '../../store/AppStore';
import { zIndexes } from '../../theme/zIndexes';
import { FoRAllocation } from './OutputDetailsComponents/FoRAllocation';
import { History } from './OutputDetailsComponents/History';
import { TopInformation } from './OutputDetailsComponents/TopInformation';
import useStyles from './styles';

export const OutputDetailsDrawer = () => {
  const {
    rightDrawer: [rightDrawerIsOpen, setRightDrawerIsOpen]
  } = useContext(GlobalAppContext);

  const classes = useStyles();
  const handleClose = () => {
    setRightDrawerIsOpen(null);
  };

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      style={{
        zIndex: zIndexes.outputDetailsDrawer
      }}
      open={rightDrawerIsOpen !== null}
      className={classes.drawer}
      data-testid="output-details-drawer"
      onClose={handleClose}>
      <div className={classes.closableArea}>
        <Button
          aria-label="click here to close this output details panel."
          className={classes.closeBtn}
          onClick={handleClose}
          sx={{ ml: -3 }}
          data-testid="close-button"
          disableRipple
          disableFocusRipple>
          <CloseIcon className={classes.closeIcon} data-testid="close-button-icon" />
        </Button>
      </div>
      <TopInformation
        tabIndex={-1}
        data-testid="output-details-drawer-top-information"
        {...rightDrawerIsOpen}
      />
      <FoRAllocation data-testid="output-details-drawer-for-allocation" {...rightDrawerIsOpen} />
      <History data-testid="output-details-drawer-history" />
    </Drawer>
  );
};
