import { Badge, Button, Drawer, Grid, MenuItem, MenuList, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';

import { useGetAssignedForCodes } from '../../api/forcodes';
import { useGetOutputs } from '../../api/outputs';
import { defaults, GlobalAppContext } from '../../store/AppStore';
import { Loader } from '../Loader/Loader';
import { NoResults } from '../NoResults/NoResults';
import useStyles from './styles';

const ForActionBadge = ({ forCode }) => {
  const classes = useStyles();

  const { data } = useGetOutputs(0, 1, forCode, { field: 'pubPk', sort: 'desc' }, [], '', [], true);
  return (
    <Badge
      badgeContent={data && data.totalResults}
      className={classes.badgeIcon}
      data-testid={`left-slide-side-bar-${forCode}-badge`}
    />
  );
};

ForActionBadge.propTypes = {
  forCode: PropTypes.String
};

export const SlideSideBar = () => {
  const classes = useStyles();
  const {
    slideBar: [slideLeftBarIsOpen, setSlideLeftBarIsOpen],
    forCodeSelected: [forCode, setForCode],
    pageNum: [, setPage],
    pageSize: [, setSize]
  } = useContext(GlobalAppContext);

  const { data, isLoading, isError, isSuccess } = useGetAssignedForCodes();

  const closeSlideDrawer = () => {
    setSlideLeftBarIsOpen(false);
  };

  const handleSelectCode = (forCode, codeName) => {
    setForCode({ FoRCode: forCode, FoRCodeName: codeName });
    setPage(defaults.pagination.page);
    setSize(defaults.pagination.pageSize);
    closeSlideDrawer();
  };

  // This is to set default ForCode as 1st ForCode in the list
  useEffect(() => {
    if (forCode.FoRCode === undefined && data && data.length > 0) {
      setForCode({ FoRCode: data[0].forCode, FoRCodeName: data[0].forName });
    }
  }, [data, forCode.FoRCode, setForCode]);

  return (
    <Drawer
      anchor={'left'}
      open={slideLeftBarIsOpen}
      BackdropProps={{ invisible: true }}
      className={classes.slideSideBar}
      data-testid="left-slide-side-bar"
      variant="temporary"
      onClose={closeSlideDrawer}>
      <div
        className={classes.slideSideBarHeader}
        data-testid="left-slide-side-bar-header"
        role="list"
        aria-label="My output lists">
        <h2 className="remove-whitespaces">My Outputs</h2>
      </div>
      {isLoading ? (
        <Loader data-testid="slide-side-bar-loader" />
      ) : isError ? (
        <NoResults
          message="Assigned ForCodes cannot be displayed. Please contact the ERA Administrator."
          data-testid="slide-side-bar-error"
        />
      ) : isSuccess && data && data.length > 0 ? (
        <MenuList
          aria-label="F O R code lists clickable menu buttons"
          disablePadding
          data-testid="left-slide-side-bar-lists">
          {data.map((listOfCode) => (
            <MenuItem
              disableGutters
              key={listOfCode.forCode}
              className={classes.slideSideBarListItem}>
              <Button
                onClick={() => handleSelectCode(listOfCode.forCode, listOfCode.forName)}
                className={classes.slideSideBarBtn}
                data-testid={`left-slide-side-bar-btn-${listOfCode.forCode}`}
                aria-label={`F O R code ${listOfCode.forCode} - ${listOfCode.forName}`}
                disableRipple
                disableFocusRipple>
                <Grid container spacing={1}>
                  <Grid item xs={11}>
                    <Typography
                      variant="body2"
                      className={classes.slideSideBarBlueBtn}
                      data-testid={`left-slide-side-bar-${listOfCode.forCode}-code-name`}>
                      {listOfCode.forCode} - {listOfCode.forName}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <ForActionBadge forCode={listOfCode.forCode} />
                  </Grid>
                </Grid>
              </Button>
            </MenuItem>
          ))}
        </MenuList>
      ) : (
        <NoResults
          message="There is no ForCode assigned to you"
          data-testid="slide-side-bar-no-result"
        />
      )}
    </Drawer>
  );
};
