import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import React from 'react';

import { colours } from '../../theme/colors';

export const FERAButton = ({
  variant = 'contained',
  color = 'red',
  disabled = false,
  children,
  ...rest
}) => {
  var styles = {};
  var bgColor,
    fgColor,
    hoverBgColor,
    hoverFgColor = '',
    disabledBgColor,
    disabledFgColor = '';

  switch (color) {
    case 'red':
      bgColor = colours.primaryRed;
      fgColor = colours.white;
      hoverBgColor = colours.primaryRedDark;
      hoverFgColor = colours.white;
      disabledBgColor = colours.loadingBgGrey;
      disabledFgColor = colours.loadingTextGrey;

      break;
    case 'purple':
      bgColor = colours.primaryBlue;
      fgColor = colours.white;
      hoverBgColor = colours.primaryBlueDark;
      hoverFgColor = colours.white;
      disabledBgColor = colours.loadingBgGrey;
      disabledFgColor = colours.loadingTextGrey;
      break;
    default:
      throw new Error('Undefined color');
  }

  const commonStyles = {
    padding: '8, 22, 8, 22',
    height: 42,
    fontWeight: 500,
    fontSize: 15,
    textTransform: 'none',
    fontFamily: 'Museo',
    borderRadius: '4px',
    '&.Mui-disabled': {
      backgroundColor: disabledBgColor,
      borderColor: disabledBgColor,
      color: disabledFgColor,
      '&:hover': {
        backgroundColor: disabledBgColor,
        borderColor: disabledBgColor,
        color: disabledFgColor
      }
    }
  };

  switch (variant) {
    case 'contained':
      styles = {
        ...commonStyles,
        color: fgColor,
        backgroundColor: bgColor,
        borderColor: bgColor,
        boxShadow:
          '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
        '&:hover': {
          color: hoverFgColor,
          backgroundColor: hoverBgColor,
          borderColor: hoverBgColor,
          boxShadow:
            '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)'
        }
      };
      break;
    case 'outlined':
      styles = {
        ...commonStyles,
        color: bgColor,
        backgroundColor: 'unset',
        border: `1px solid ${bgColor}`,
        boxShadow: 'unset',
        '&:hover': {
          color: hoverBgColor,
          backgroundColor: 'unset',
          border: `1px solid ${hoverBgColor}`,
          textDecorationLine: 'underline'
        }
      };
      break;

    default:
      throw new Error('Undefined variant');
  }
  // const StyledButton = styled(LoadingButton)(styles);
  return (
    <LoadingButton
      variant={variant}
      disabled={disabled}
      {...rest}
      disableRipple
      disableFocusRipple
      sx={{ ...styles, ...rest.sx }}>
      {rest.loading ? 'Loading...' : children}
    </LoadingButton>
  );
};

FERAButton.propTypes = {
  variant: PropTypes.oneOf(['contained', 'outlined']),
  color: PropTypes.oneOf(['red', 'purple']),
  disabled: PropTypes.bool,
  children: PropTypes.node
};
