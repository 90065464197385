import { useMutation, useQuery } from 'react-query';

import { getCommentFilters } from '../components/SearchBar/OptionsConfig';
import { commentNotificationsEndpoint } from './endpoints';
import { request } from './request';

export const useComments = (page = 0, size = 25, workspaceForCode, sorts, filters) => {
  const UIfilters = getCommentFilters();
  const newFilters = {
    actionStatus: filters.filter((f) =>
      (UIfilters.filter((w) => w.groupname === 'Status')[0]?.options || []).includes(f)
    ),
    reasonCode: filters.filter((f) =>
      (UIfilters.filter((w) => w.groupname === 'Comment Type')[0]?.options || []).includes(f)
    ),
    MainFor: filters.filter((f) =>
      (UIfilters.filter((w) => w.groupname === 'Main FoR')[0]?.options || []).includes(f)
    )
  };
  const params = {
    page,
    size,
    workspaceForCode,
    actionStatus: newFilters.actionStatus?.join(', '),
    reasonCodes: newFilters.reasonCode?.join(', '),
    mainFor: newFilters.MainFor?.join(', ')
  };
  const sort = sorts[0];
  if (sort) {
    params.sort = `${sort.field},${sort.sort}`;
  } else {
    params.sort = 'createdAt,desc';
  }

  return useQuery(
    ['comments', page, size, JSON.stringify(sorts), filters],
    async () => {
      return request({
        url: commentNotificationsEndpoint,
        params: params
      }).then((response) => {
        return response.data || [];
      });
    },
    {
      onError: (err) => console.error(err)
    }
  );
};

const toggleCommentActioned = async ({ commentNotificationId, actioned }) => {
  const r = {
    url: `${commentNotificationsEndpoint}/${commentNotificationId}/action`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    params: { actioned }
  };
  const response = await request(r);
  return response;
};

export const useToggleCommentActioned = () => useMutation(toggleCommentActioned);

export const useCountUnactionedComments = (workspaceForCode) => {
  const params = {
    workspaceForCode
  };

  return useQuery(
    ['comments-unactioned-count', workspaceForCode],
    async () => {
      return request({
        url: `${commentNotificationsEndpoint}/count-unactioned`,
        params: params
      }).then((response) => {
        return response.data || [];
      });
    },
    {
      enabled: workspaceForCode !== undefined,
      onError: (err) => console.error(err)
    }
  );
};
