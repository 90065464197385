import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { FERAButton } from '../Button/FERAButton';
import GenericDialog from '../Dialog';
import useStyles from './styles';

export const ClearAllNotificationsWarningModal = ({ onClose, onSubmit }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const handleReset = () => {
    setOpen(false);
    onClose();
  };

  return (
    <div data-testid="bulk-action-component">
      {open && (
        <GenericDialog title="Delete notifications" onClose={handleReset}>
          <Box className={classes.modalContent} data-testid="bulk-action-modal">
            <InfoOutlinedIcon
              sx={{ fontSize: 67 }}
              className={clsx(classes.center, classes.mainBlue)}
            />
            <Typography variant="h1" className={classes.approveHeader}>
              Delete all notifications?
            </Typography>
            <Typography
              className={classes.approveInfor}
              sx={{ width: 378, margin: '0 auto' }}
              data-testid="modal-modal-description">
              Please note this will remove all notifications across all your FoR codes and cannot be
              recovered.
            </Typography>
            <Box className={classes.center}>
              <FERAButton
                onClick={onSubmit}
                data-testid="modal-modal-delete-all-button"
                variant="contained"
                color="red"
                sx={{ marginRight: 2 }}
                aria-label="Delete All">
                Delete All
              </FERAButton>
              <FERAButton
                variant="outlined"
                color="red"
                onClick={handleReset}
                data-testid="modal-modal-cancel-button"
                aria-label="Close dialog">
                Cancel
              </FERAButton>
            </Box>
          </Box>
        </GenericDialog>
      )}
    </div>
  );
};

ClearAllNotificationsWarningModal.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};
