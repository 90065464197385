import makeStyles from '@mui/styles/makeStyles';

import { colours } from '../../theme/colors';
import { navigationConstant } from '../../theme/navigationStyles';

const useStyles = makeStyles(() => ({
  center: {
    textAlign: 'center',
    paddingTop: 155
  },
  topBar: {
    width: '100%',
    height: navigationConstant.topBarHeight,
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: colours.white,
    boxShadow:
      '0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)'
  },
  logo: {
    height: 25,
    paddingLeft: 25,
    paddingRight: 137.343
  },
  title: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));

export default useStyles;
