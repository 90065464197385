import React from 'react';

import { Login } from '../components/Login/Login';
import { DefaultLayout } from '../layouts/DefaultLayout/DefaultLayout';
import { ViewWrapper } from './ViewWrapper';

export const LoginView = () => {
  return (
    <ViewWrapper>
      <DefaultLayout>
        <Login />
      </DefaultLayout>
    </ViewWrapper>
  );
};
