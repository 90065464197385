import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { FERAButton } from '../Button/FERAButton';
import GenericDialog from '../Dialog';
import useStyles from './styles';

export const GenericBulkConfirmModal = ({ onSubmit, reset, details, title }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const handleReset = () => {
    setOpen(false);
    reset();
  };

  return (
    <div data-testid="bulk-action-component">
      {open && (
        <GenericDialog title={title} onClose={handleReset}>
          <Box className={classes.modalContent} data-testid="bulk-action-modal">
            <InfoOutlinedIcon
              sx={{ fontSize: 67 }}
              className={clsx(classes.center, classes.mainBlue)}
            />
            <Typography variant="h1" className={classes.approveHeader}>
              Please confirm
            </Typography>
            <Typography className={classes.approveInfor} data-testid="modal-modal-description">
              {details.split('\n').map((str, index) => (
                <span key={index}>
                  {str}
                  <br />
                </span>
              ))}
            </Typography>
            <Box className={classes.center}>
              <FERAButton
                onClick={onSubmit}
                data-testid="modal-modal-submit-button"
                variant="contained"
                color="red"
                aria-label="Confirm and Submit"
                loadingPosition="start"
                sx={{ marginRight: 2 }}
                startIcon={<SaveIcon />}>
                Confirm &amp; Submit
              </FERAButton>
              <FERAButton
                variant="outlined"
                color="red"
                onClick={handleReset}
                data-testid="modal-modal-cancel-button"
                aria-label="Close dialog">
                Cancel
              </FERAButton>
            </Box>
          </Box>
        </GenericDialog>
      )}
    </div>
  );
};
GenericBulkConfirmModal.propTypes = {
  onSubmit: PropTypes.func,
  reset: PropTypes.func,
  details: PropTypes.any,
  title: PropTypes.string
};
