import React from 'react';

import { AdminPage } from '../components/AdminPage/AdminPage';
import { NavigableLayout } from '../layouts/NavigableLayout/NavigableLayout';
import { ViewWrapper } from './ViewWrapper';

export const AdminView = () => {
  return (
    <ViewWrapper>
      <NavigableLayout adminOnly={true}>
        <AdminPage />
      </NavigableLayout>
    </ViewWrapper>
  );
};
