import moment from 'moment';
import React from 'react';

import {
  ActionButtonCell,
  CommentorCell,
  ContributorsCell,
  DateAddedCell,
  ForCodesCell,
  getPreference,
  SingleCell,
  StatusCell,
  TitleCell,
  TooltipCell,
  Top30Badge
} from './DataTableHelpers';

const getFormattedDate = (value) => {
  if (typeof value === 'undefined') return;
  var date = new Date(value + '+00:00');
  return date.toLocaleString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    timeZone: 'Australia/Melbourne'
  });
};

export const getOutputTableColumns = (handleClick, citation, qualityIndicator, forAction) => {
  const preferredCitation = getPreference(citation);
  const preferredQualityIndicator = getPreference(qualityIndicator);

  const fullWidth = 1198;
  const preferredQualityIndicatorWidth = 110;
  const preferredSubmittedWidth = 111;
  const preferredCitationWidth = 110;

  const remainingWidthAfterPreferences =
    fullWidth -
    preferredQualityIndicatorWidth * preferredQualityIndicator.isHidden -
    preferredSubmittedWidth * forAction -
    preferredCitationWidth * preferredCitation.isHidden;
  const getWidth = (designWidth) => (designWidth / remainingWidthAfterPreferences) * fullWidth;

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      width: getWidth(forAction ? 210 : 360),
      sortable: true,
      renderCell: (param) => {
        const onClick = (e) => {
          e.stopPropagation();
          handleClick(param.row.pubPk);
        };
        return <TitleCell title={param.row.title} onClick={(e) => onClick(e)} />;
      }
    },
    {
      field: 'outlet',
      headerName: 'Outlet',
      sortable: true,
      width: getWidth(forAction ? 120 : 150),
      renderCell: (param) => {
        const onClick = (e) => {
          e.stopPropagation();
        };
        return <TooltipCell label="Outlet" value={param.row.outlet} onClick={(e) => onClick(e)} />;
      }
    },
    {
      field: 'outputCategory',
      headerName: 'Category',
      width: getWidth(125),
      sortable: true,
      renderCell: (param) => {
        const onClick = (e) => {
          e.stopPropagation();
        };
        return (
          <TooltipCell
            label="Category"
            data-testid="outputCategory"
            value={param.row.outputCategory}
            onClick={(e) => onClick(e)}
          />
        );
      }
    },
    {
      field: 'contributors',
      headerName: 'Contributors',
      width: getWidth(168),
      sortable: false,
      renderCell: (param) => {
        const onClick = (e) => {
          e.stopPropagation();
        };
        return <ContributorsCell names={param.row.contributors} onClick={(e) => onClick(e)} />;
      }
    },

    {
      field: 'mainFor',
      headerName: 'Main FoR',
      sortable: true,
      width: getWidth(110),
      headerAlign: 'center',
      renderCell: (param) => {
        return <ForCodesCell value={param.row.mainFor} label="main F-O-R" />;
      }
    },
    {
      field: 'forCodes',
      headerName: 'FoR Codes',
      width: getWidth(94),
      headerAlign: 'center',
      sortable: false,
      renderCell: (param) => {
        return (
          <ForCodesCell value={param.row.forCodes} aria-label="F-O-R Codes for this output are" />
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: getWidth(109),
      headerAlign: 'center',
      sortable: true,
      renderCell: (param) => {
        return <StatusCell status={param.row.status} />;
      }
    },
    {
      field: 'nominate',
      headerName: '30%',
      width: getWidth(90),
      headerAlign: 'center',
      sortable: true,
      renderCell: (param) => {
        return param.row.nominate ? <Top30Badge /> : <div></div>;
      }
    }
  ];

  if (forAction) {
    columns.splice(6, 0, {
      field: 'lastUpdatedAt',
      headerName: '\u00A0\u00A0\u00A0\u00A0\u00A0Submitted',
      width: preferredSubmittedWidth,
      sortable: true,
      headerAlign: 'center',
      renderCell: (param) => {
        return <SingleCell value={getFormattedDate(param.row.lastUpdatedAt)} />;
      }
    });
  }
  if (!preferredQualityIndicator.isHidden) {
    columns.splice(4, 0, {
      field: `${preferredQualityIndicator.field}`,
      headerName: `${preferredQualityIndicator.header}`,
      width: preferredQualityIndicator.isHidden ? 0 : preferredQualityIndicatorWidth,
      sortable: true,
      hide: preferredQualityIndicator.isHidden,
      renderCell: (param) => {
        const val = param.row[preferredQualityIndicator.field];
        return (
          <SingleCell
            value={val === -1 ? '-' : val}
            label={`${preferredQualityIndicator.field}:`}
          />
        );
      }
    });
  }

  if (!preferredCitation.isHidden) {
    columns.splice(4, 0, {
      field: `${preferredCitation.field}`,
      headerName: `${preferredCitation.header}`,
      width: preferredCitation.isHidden ? 0 : preferredCitationWidth,
      sortable: true,
      hide: preferredCitation.isHidden,
      renderCell: (param) => {
        const val = param.row[preferredCitation.field];
        return <SingleCell value={val === -1 ? '-' : val} label={`${preferredCitation.field}:`} />;
      }
    });
  }

  return columns;
};

export const getCommentTableColumns = (
  handleOutputClick,
  actionedComments,
  setActionedComments
) => {
  const widthPctPrefs = {
    outputTitle: 0.3,
    message: 0.3,
    reasonCode: 0.1,
    commentor: 0.1,
    dateAdded: 0.08,
    actionBtns: 0.12
  };

  const columns = [
    {
      field: 'title',
      headerName: 'Output Title',
      flex: widthPctPrefs.outputTitle,
      sortable: true,
      renderCell: (param) => {
        const onClick = (e) => {
          e.stopPropagation();
          handleOutputClick(param.row.outputId);
        };
        return <TitleCell title={param.row.title} onClick={(e) => onClick(e)} />;
      }
    },
    {
      field: 'comment',
      headerName: 'Comment',
      flex: widthPctPrefs.message,
      sortable: true,
      renderCell: (param) => {
        const onClick = (e) => {
          e.stopPropagation();
        };
        const cellVal = param.row.comment.replace(/\n/, '');
        return (
          <TooltipCell label={cellVal.toString()} value={cellVal} onClick={(e) => onClick(e)} />
        );
      }
    },
    {
      field: 'reasonCode',
      headerName: 'Comment Type',
      flex: widthPctPrefs.reasonCode,
      sortable: true,
      renderCell: (param) => {
        const onClick = (e) => {
          e.stopPropagation();
        };
        return (
          <TooltipCell
            label={param.row.reasonCode}
            value={param.row.reasonCode}
            onClick={(e) => onClick(e)}
          />
        );
      }
    },
    {
      field: 'commentor',
      headerName: 'Commenter',
      flex: widthPctPrefs.commentor,
      sortable: false,
      renderCell: (param) => {
        const onClick = (e) => {
          e.stopPropagation();
        };
        return <CommentorCell userId={param.row.createdById} onClick={(e) => onClick(e)} />;
      }
    },
    {
      field: 'createdAt',
      headerName: 'Date Added',
      flex: widthPctPrefs.dateAdded,
      sortable: true,
      renderCell: (param) => {
        const onClick = (e) => {
          e.stopPropagation();
        };
        const formattedDate = moment(param.row.createdAt).format('D/MM/YYYY');
        return <DateAddedCell dateAdded={formattedDate} onClick={(e) => onClick(e)} />;
      }
    },
    {
      field: 'actioned',
      headerName: '',
      flex: widthPctPrefs.actionBtns,
      sortable: true,
      renderCell: (param) => {
        return (
          <ActionButtonCell
            label="action-button"
            commentNotificationId={param.row.id}
            actionedComments={actionedComments}
            setActionedComments={setActionedComments}
          />
        );
      }
    }
  ];

  return columns;
};
