import { useQuery } from 'react-query';

import { preferencesEndpoint } from './endpoints';
import { request } from './request';

export const useGetCitations = () => {
  return useQuery(['citations'], async () => {
    return request({ url: `${preferencesEndpoint}/citations` }).then((response) => {
      return response.data;
    });
  });
};

export const useGetQualityIndicators = () => {
  return useQuery(['qualityIndicators'], async () => {
    return request({ url: `${preferencesEndpoint}/quality-indicators` }).then((response) => {
      return response.data;
    });
  });
};
