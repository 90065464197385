import {
  Alert,
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Skeleton,
  TableContainer,
  Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useGetFoRCodes } from '../../api/forcodes';
import {
  useGetWorkspaceCategory,
  useGetWorkspaceOutletResult,
  useGetWorkspaceSummaryStatus
} from '../../api/summary';
import { FERALink } from '../Link/FERALink';
import { PageHeader } from '../PageHeader/PageHeader';
import SearchDropDown from '../SearchDropDown';
import { DataTableFooter } from '../Table/DataTableFooter';
import { KVTable } from '../Table/KVTable';
import useStyles from './styles';

const categoryOutputHeaders = [
  'Output Type',
  'Total count',
  'Apportioned (%)',
  'Top 30% - Expected',
  'Top 30% - Actual',
  'Top 30% - Remaining'
];

const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
const formatRound = (params, dp) => Number(params.value).toFixed(dp);
const formatPercentage = (v) => `${v} %`;
export const precision = 4;

export const SummaryContent = ({
  summaryStatusQuery,
  summaryCategoryQuery,
  summaryOutletResultQuery
}) => {
  const [outletType, setOutlet] = React.useState('Journal');
  const handleChange = (event) => {
    setOutlet(event.target.value);
  };
  const classes = useStyles();

  const columns = [
    {
      field: 'outletTitle',
      headerName: <strong>{capitalize(outletType)} Title</strong>,
      sortable: false,
      width: 400
    },
    {
      field: 'eraId',
      headerName: <strong>ERA ID</strong>,
      flex: 1,
      sortable: false,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'apportioned',
      headerName: <strong>Apportioned Outputs</strong>,
      flex: 1,
      sortable: false,
      valueFormatter: (params) => formatRound(params, 2),
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'poutputs',
      headerName: <strong>%outputs</strong>,
      flex: 1,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      valueFormatter: (params) => formatPercentage(formatRound(params, precision))
    },
    {
      field: 'cumpOutputs',
      headerName: <strong>Cumulative % outputs</strong>,
      flex: 1,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      valueFormatter: (params) => formatPercentage(formatRound(params, precision))
    },
    {
      field: 'noOfOutputs',
      headerName: <strong>Number of Outputs</strong>,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false
    }
  ];
  const dataGridRows = summaryOutletResultQuery?.data?.[outletType.toLowerCase()] || [];
  const [dataGridPageSize, setDataGridPageSize] = useState(10);
  return (
    <Grid container spacing={2} sx={{ mt: 4, mb: 6 }}>
      <Grid item xs={4}>
        {summaryStatusQuery?.isLoading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={300}
            data-testid="summary-status-loader"
            sx={{ marginTop: 3, marginBottom: 3 }}
          />
        ) : (
          <KVTable data={summaryStatusQuery?.data || {}} title="Output status for ERA" />
        )}
      </Grid>
      <Grid item xs={8}>
        {summaryCategoryQuery?.isLoading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={300}
            data-testid="summary-category-loader"
            sx={{ marginTop: 3, marginBottom: 3 }}
          />
        ) : (
          <KVTable
            headers={categoryOutputHeaders}
            data={summaryCategoryQuery?.data || {}}
            title={'Outputs by Category'}
          />
        )}
      </Grid>

      <Grid item xs={12}>
        {summaryOutletResultQuery?.isLoading ? (
          // <Loader data-testid="summary-category-loader" />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={300}
            data-testid="summary-outlet-result-loader"
            sx={{ marginTop: 3, marginBottom: 3 }}
          />
        ) : (
          <TableContainer>
            <Typography
              sx={{
                marginBottom: 6 / 8,
                fontWeight: 500,
                fontSize: 18,
                fontFamily: 'Museo',
                lineHeight: '120%'
              }}>
              Outputs by Output type
            </Typography>
            <FormControl sx={{ width: 260, marginBottom: 2, marginTop: 2 }}>
              <Select
                id="simple-select"
                value={outletType}
                onChange={handleChange}
                className={classes.dropdown}>
                {Object.keys(summaryOutletResultQuery?.data || {}).map((k) => (
                  <MenuItem key={k} value={capitalize(k)}>
                    {capitalize(k)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box ml={1.5}>
              <DataGrid
                rows={dataGridRows}
                columns={columns}
                getRowId={(row) => row.outletTitle}
                className={classes.dataGrid}
                disableColumnMenu
                density="compact"
                components={{
                  Footer: DataTableFooter
                }}
                autoHeight
                pageSize={dataGridPageSize}
                onPageSizeChange={setDataGridPageSize}
                componentsProps={{
                  footer: { rowCount: dataGridRows.length }
                }}
              />
            </Box>
          </TableContainer>
        )}
        <Alert severity="info" color="primary">
          <span className={classes.mailtoText}>
            This table may contain duplicated titles, if you spot any data quality issues, please{' '}
          </span>
          <FERALink
            onClick={() => {
              window.open('mailto:era.ei@rmit.edu.au');
            }}>
            contact an admin
          </FERALink>
        </Alert>
      </Grid>
    </Grid>
  );
};

SummaryContent.propTypes = {
  summaryStatusQuery: PropTypes.object.isRequired,
  summaryCategoryQuery: PropTypes.object.isRequired,
  summaryOutletResultQuery: PropTypes.object.isRequired
};

export const DataSummary = () => {
  const { data: forCodes } = useGetFoRCodes([2, 4]);

  const defaultOption = { forCode: 'All of ERA', forName: null };
  const [selectedOption, setSelectedOption] = useState(defaultOption);
  const allOptions = [defaultOption].concat(forCodes?.data || []);

  const getForCode = () => {
    if (selectedOption === null) return null;
    if (selectedOption.forCode === defaultOption.forCode) {
      return '';
    }
    return selectedOption.forCode;
  };
  const [currentForCode, setCurrentForCode] = useState(getForCode());

  useEffect(() => {
    const newForCode = getForCode();
    if (newForCode !== null) setCurrentForCode(newForCode);
  }, [selectedOption]);

  const classes = useStyles();

  const summaryStatusQuery = useGetWorkspaceSummaryStatus(currentForCode);
  const summaryCategoryQuery = useGetWorkspaceCategory(currentForCode);
  const summaryOutletResultQuery = useGetWorkspaceOutletResult(currentForCode);

  return (
    <div data-testid="era-data-page-content">
      <PageHeader location="ERA DATA" title="Overall Summary" data-testid="era-data-page-header" />
      <div className={classes.container}>
        <SearchDropDown
          isDisabled={
            summaryStatusQuery.isLoading &&
            summaryCategoryQuery.isLoading &&
            summaryOutletResultQuery.isLoading
          }
          placeholder="Select a code"
          getOptionLabel={(o) => {
            if (o === null) {
              return '';
            }
            return o.forCode
              ? `${o.forCode}${o.forName !== null ? ` - ${o.forName ? o.forName : 'Unknown'}` : ''}`
              : '';
          }}
          options={allOptions}
          value={selectedOption}
          onChange={(newValue) => {
            setSelectedOption(newValue);
          }}
          data-testid="for-summary-row-dropdown"
        />
        <SummaryContent
          summaryStatusQuery={summaryStatusQuery}
          summaryCategoryQuery={summaryCategoryQuery}
          summaryOutletResultQuery={summaryOutletResultQuery}
        />
      </div>
    </div>
  );
};
