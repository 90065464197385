import React from 'react';

import { EditCell, StatusCell, TextCell } from './DataTableHelpers';

const neatRoleNames = {
  ADMINISTRATOR: { name: 'Administrator', priority: 1 },
  TWO_DIGITS_ADVISOR: { name: '2-digit Advisor', priority: 4 },
  FOUR_DIGITS_ADVISOR: { name: '4-digit Advisor', priority: 3 },
  UNIVERSITY_LEAD: { name: 'University Lead', priority: 2 }
};

export const getOutputTableColumns = (handleEdit) => {
  return [
    {
      field: 'first_name',
      headerName: 'Advisor Name',
      minWidth: 242,
      sortable: true,
      renderCell: (param) => {
        return <TextCell title={param.row.fullName} />;
      }
    },
    {
      field: 'enumber',
      headerName: 'e-Number',
      flex: 100 / 1006,
      sortable: true,
      renderCell: (param) => {
        return <TextCell title={param.row.enumber} />;
      }
    },
    {
      field: 'college',
      headerName: 'College',
      flex: 142 / 1006,
      sortable: true,
      renderCell: (param) => {
        return <TextCell title={param.row.college} />;
      }
    },
    {
      field: 'userRoles',
      headerName: 'Role Type',
      flex: 146 / 1006,
      sortable: false,
      renderCell: (param) => {
        var info = param.row.userRoles.map((r) => neatRoleNames[r.roleName]);
        info.sort((a, b) => (a.priority < b.priority ? -1 : 1));
        info = info.map((r) => r.name).join(', ');

        return <TextCell title={info} tooltip={`Roles: ${info}`} />;
      }
    },
    {
      field: 'assignedCodes',
      headerName: 'Assigned Codes',
      flex: 260 / 1006,
      sortable: false,
      renderCell: (param) => {
        const info = param.row.allAssignedForCodes.join(', ');
        return <TextCell title={info} tooltip={`Assigned Codes: ${info}`} />;
      }
    },
    {
      field: 'email',
      headerName: 'Email Address',
      sortable: false,
      flex: 200 / 1006,
      renderCell: (param) => {
        return <TextCell title={param.row.email} />;
      }
    },
    {
      field: 'deactivated_at',
      headerName: 'Status',
      sortable: true,
      flex: 105 / 1006,
      renderCell: (param) => {
        return <StatusCell status={param.row.isActive ? 'Active' : 'Inactive'} />;
      }
    },
    {
      field: 'edit',
      headerName: 'Action',
      flex: 80 / 1006,
      sortable: false,
      align: 'center',
      renderCell: (param) => {
        const onClick = (e) => {
          e.stopPropagation();
          handleEdit(param.row);
        };
        return <EditCell onClick={onClick} />;
      }
    }
  ];
};
