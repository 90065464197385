import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Button,
  ClickAwayListener,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { FERALink } from '../../Link/FERALink';
import useStyles, { StyledCheckbox } from '../styles';

export const DropdownAccordion = ({ buttonName, buttonLocation, lists, selected, setSelected }) => {
  const [openDropdown, setOpenDropdown] = useState(false);

  const anchorRef = React.useRef(null);
  const classes = useStyles();

  const viewDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Escape') {
      setOpenDropdown(false);
    }
  }

  const handleClose = () => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenDropdown(false);
  };

  const handleChange = (value) => {
    if (selected.indexOf(value) > -1) {
      let newArray = selected.filter((select) => select !== value);
      setSelected(newArray);
    } else {
      setSelected(selected.concat(value));
    }
  };

  const handleSelectChange = (event, option) => {
    if (event.key === 'Enter') {
      handleChange(option);
    }
  };

  const getCheckboxList = (option) => {
    return (
      <MenuItem
        key={option}
        value={option}
        className={classes.accordionItem}
        onClick={() => handleChange(option)}
        onKeyDown={(e) => handleSelectChange(e, option)}
        data-testid={`filter-by-${option}`}
        id={`filter-by-${option}`}
        disableRipple
        button>
        <StyledCheckbox checked={selected.indexOf(option) > -1} disableRipple />
        <ListItemText className={classes.styledText}>{option}</ListItemText>
      </MenuItem>
    );
  };

  return (
    <div className={classes.dropdownWrapper}>
      <Button
        ref={anchorRef}
        id={`${buttonLocation}-${buttonName}-accordion-button`}
        aria-controls={openDropdown ? `${buttonLocation}-${buttonName}-menu` : undefined}
        aria-expanded={openDropdown ? 'true' : undefined}
        aria-haspopup="true"
        aria-label={`dropdown options for ${buttonLocation} filter results by`}
        onClick={viewDropdown}
        data-testid={`${buttonLocation}-${buttonName}-accordion-button`}
        className={classes.buttonText}
        disableFocusRipple
        disableRipple
        endIcon={<ArrowDropDownIcon />}>
        <Typography
          variant="body1"
          className={classes.buttonTitle}
          data-testid={`${buttonLocation}-${buttonName}-button-title`}>
          Filter results by
        </Typography>
        <Badge
          aria-hidden={selected.length < 1 ? true : undefined}
          badgeContent={selected.length}
          data-testid={`${buttonLocation}-${buttonName}-badge`}
          className={classes.badgeIcon}
        />
      </Button>
      <Popper
        open={openDropdown}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        disablePortal>
        <Paper
          className={classes.popperList}
          data-testid={`${buttonLocation}-${buttonName}-popup`}
          onKeyDown={handleListKeyDown}
          square>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList
              autoFocusItem={openDropdown}
              id={`${buttonLocation}-${buttonName}-menu`}
              data-testid={`${buttonLocation}-${buttonName}-menu`}
              aria-labelledby={`${buttonLocation}-${buttonName}-accordion-button`}>
              {lists.map((list) => (
                <Accordion key={list.groupname} tabIndex={-1} className={classes.accordion}>
                  <AccordionSummary
                    id={`accordion-summary-${list.groupname}`}
                    data-testid={`accordion-summary-${list.groupname}`}
                    aria-controls={`accordion-summary-details-${list.groupname}`}
                    aria-labelledby={`accordion-summary-${list.groupname}`}
                    className={classes.accordionHeader}
                    expandIcon={<ExpandMoreIcon className={classes.styledText} />}>
                    <Typography>{list.groupname}</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    {list.options.map((option) => getCheckboxList(option))}
                  </AccordionDetails>
                </Accordion>
              ))}
              <MenuItem
                tabIndex={-1}
                className={classes.accordionItem}
                onClick={() => setSelected([])}
                data-testid={`${buttonLocation}-${buttonName}-clear-all`}
                disableRipple>
                <FERALink sx={{ width: '100%' }}>Clear All</FERALink>
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  );
};

DropdownAccordion.propTypes = {
  lists: PropTypes.array.isRequired,
  buttonName: PropTypes.string.isRequired,
  buttonLocation: PropTypes.string.isRequired,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired
};
