import React from 'react';

import { OutputLists } from '../components/Outputs/Outputs/OutputLists';
import { NavigableLayout } from '../layouts/NavigableLayout/NavigableLayout';
import { ViewWrapper } from './ViewWrapper';

export const OutputsView = () => {
  return (
    <ViewWrapper>
      <NavigableLayout>
        <OutputLists />
      </NavigableLayout>
    </ViewWrapper>
  );
};
