import BlockIcon from '@mui/icons-material/Block';
import Create from '@mui/icons-material/Create';
import { Badge, Typography } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import useStyles, { StyledTooltip } from './styles';

const isExpectedStatus = (expected, actual) => {
  return expected === actual;
};

export const EditCell = ({ onClick }) => {
  const classes = useStyles();
  return (
    <div className="region">
      <Create
        aria-label="Edit User"
        onClick={onClick}
        color="disabled"
        className={classes.editIcon}
      />
    </div>
  );
};
EditCell.propTypes = {
  onClick: PropTypes.func.isRequired
};

export const StatusCell = ({ status }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.badgeContainer}>
        {status === 'Active' ? (
          <Badge
            aria-label="status"
            role="status"
            color="primary"
            className={clsx(classes.badge, {
              [classes.badgeGrey]: isExpectedStatus('Inactive', status),
              [classes.badgeGreen]: isExpectedStatus('Active', status)
            })}
          />
        ) : (
          <BlockIcon style={{ marginRight: -2, marginLeft: -2 }} />
        )}
      </div>
      <p style={{ marginRight: 2, marginLeft: 2 }}>{status}</p>
    </>
  );
};
StatusCell.propTypes = {
  status: PropTypes.string
};

export const TextCell = ({ title, tooltip }) => {
  const classes = useStyles();

  return (
    <StyledTooltip title={tooltip || `Value: ${title}`} placement="bottom" arrow>
      <Typography noWrap variant="body2" className={classes.singleCell} aria-label={title}>
        {title}
      </Typography>
    </StyledTooltip>
  );
};
TextCell.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string
};
