import PropTypes from 'prop-types';
import React from 'react';

import { AuthStore } from '../../store/AuthStore';

export const DefaultLayout = ({ children }) => {
  return <AuthStore>{children}</AuthStore>;
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired
};
