import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Button,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { colours } from '../../../theme/colors';
import useStyles from '../styles';

export const DropdownActionButton = ({ buttonName, buttonLocation, lists }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const anchorRef = React.useRef(null);
  const classes = useStyles();

  const isAllDisabled = lists.filter((list) => !list.disabled).length === 0;

  const viewDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenDropdown(false);
    } else if (event.key === 'Escape') {
      setOpenDropdown(false);
    }
  }

  const handleClose = () => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenDropdown(false);
  };

  return (
    <div className={classes.dropdownWrapper}>
      <Button
        ref={anchorRef}
        id={`${buttonLocation}-${buttonName}-button`}
        aria-controls={openDropdown ? `${buttonLocation}-${buttonName}-menu` : undefined}
        aria-expanded={openDropdown ? 'true' : undefined}
        aria-haspopup="true"
        aria-label={`dropdown options for ${buttonLocation} ${buttonName}`}
        onClick={viewDropdown}
        data-testid={`${buttonLocation}-${buttonName}-button`}
        className={classes.buttonText}
        disableFocusRipple
        disableRipple
        endIcon={
          <ArrowDropDownIcon style={{ color: isAllDisabled ? colours.disabledGrey : 'inherit' }} />
        }>
        <Typography
          variant="body1"
          data-testid={`${buttonLocation}-${buttonName}-button-title`}
          style={{ color: isAllDisabled ? colours.disabledGrey : 'inherit' }}>
          {buttonName}
        </Typography>
      </Button>
      <Popper
        open={openDropdown}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        disablePortal>
        <Paper className={classes.popperList} square>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList
              autoFocusItem={openDropdown}
              id={`${buttonLocation}-${buttonName}-menu`}
              data-testid={`${buttonLocation}-${buttonName}-menu`}
              aria-labelledby={`${buttonLocation}-${buttonName}-button`}
              onKeyDown={handleListKeyDown}>
              {lists
                .filter((list) => !list.disabled)
                .map((list) => (
                  <MenuItem
                    key={list.name}
                    value={list.name}
                    className={classes.popperListItem}
                    aria-label={`Action to ${list.name}`}
                    onClick={list.onClick}
                    data-testid={`action-to-${list.name}`}>
                    <Typography variant="body1" className={classes.styledText}>
                      {list.name}
                    </Typography>
                  </MenuItem>
                ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  );
};

DropdownActionButton.propTypes = {
  lists: PropTypes.array.isRequired,
  buttonName: PropTypes.string.isRequired,
  buttonLocation: PropTypes.string.isRequired
};
