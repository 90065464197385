import AddIcon from '@mui/icons-material/Add';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { FERAButton } from '../Button/FERAButton';
import { adding } from './constants';
import useStyles from './styles';

export const PageHeader = ({
  location,
  title,
  actionType,
  action,
  actionLoading,
  actionDisabled,
  ...other
}) => {
  const classes = useStyles();
  return (
    <div className={classes.header} data-testid="page-header" {...other}>
      <div className={classes.headerContent}>
        <div className={classes.headerText}>
          <Typography
            variant="overline"
            className={classes.headerLocation}
            data-testid="page-header-location">
            {location}
          </Typography>
          <Typography
            variant="h1"
            className={classes.headerTitle}
            data-testid={`page-header-${title}`}>
            {title}
          </Typography>
        </div>
        {actionType &&
          (actionType === adding ? (
            <FERAButton
              variant="contained"
              onClick={action}
              startIcon={<AddIcon />}
              data-testid="page-header-add-btn"
              disabled={actionDisabled}
              className={classes.actionBtn}
              color="purple">
              Add User
            </FERAButton>
          ) : (
            <FERAButton
              onClick={action}
              color="purple"
              className={classes.actionBtn}
              variant="contained"
              aria-label="Export workspace"
              loading={actionLoading}
              loadingPosition="start"
              disabled={actionDisabled}
              data-testid="page-header-export-btn"
              startIcon={<ArrowDownwardIcon />}>
              Export
            </FERAButton>
          ))}
      </div>
    </div>
  );
};

PageHeader.propTypes = {
  location: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  actionType: PropTypes.string,
  action: PropTypes.func,
  actionDisabled: PropTypes.bool,
  actionLoading: PropTypes.bool
};

PageHeader.defaultProps = {
  actionDisabled: false
};
