import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colours } from '../../theme/colors';

export const FERALink = styled(Typography)({
  color: colours.primaryBlue,
  cursor: 'pointer',
  textDecoration: 'underline',
  letterSpacing: 0.15,
  fontSize: 14,
  fontFamily: 'Arial',
  fontStyle: 'normal',
  fontWeight: 'normal',
  display: 'inline',
  '&:hover': {
    color: colours.primaryRed
  }
});
