import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { useDeleteUserSession } from '../../api/session';

export const Logout = () => {
  const { isLoading, isSuccess, isError } = useDeleteUserSession(localStorage.getItem('AuthToken'));
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isSuccess) {
      localStorage.clear();
      queryClient.clear();
      window.location.href = `${process.env.REACT_APP_PUBLIC_COGNITO_LOGOUT_URL}?client_id=${process.env.REACT_APP_PUBLIC_COGNITO_CLIENT_ID}&logout_uri=${process.env.REACT_APP_API_ENDPOINT_LOGOUT_REDIRECT}`;
    }
  }, [isSuccess, queryClient]);

  if (isLoading) {
    return <div>loading....</div>;
  }

  if (isError) {
    return <div>Error cannot logout</div>;
  }

  return <div> Successfully logout</div>;
};
