import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { FERALink } from '../../Link/FERALink';
import useStyles from './styles';

export const TopInformation = ({
  title,
  harvardTitle,
  publicationReference,
  doi,
  description,
  publisher,
  issn,
  conferenceName,
  scimagoSjrRankings,
  abdcRating,
  wosCitation,
  scopusCitation,
  altmetricCitation,
  keywords,
  outlet,
  confidential,
  //eraId,
  journalForCodes,
  creativeMedium,
  subCategory,
  category,
  sizeDuration,
  editors,
  comments,
  washingtonLeeJournalRanking,
  internationalCoAuthorship,
  coreRanking,
  googleScholarCitation
}) => {
  const classes = useStyles();
  const [referenceIsOpen, setReferenceIsOpen] = useState(true);

  const getLocation = () => {
    const baseLocation =
      'https://rmiteduau.sharepoint.com/sites/NationalAssessmentandReporting-Pubs/Shared Documents/Forms/AllItems.aspx?id=';
    let basePubRef = publicationReference.toString().substr(0, 7);
    const location =
      baseLocation +
      encodeURI(
        `/sites/NationalAssessmentandReporting-Pubs/Shared Documents/Research Outputs/2006080000 on/${basePubRef}000_to_${basePubRef}999/${publicationReference}`
      );
    return location;
  };

  const referenceInfo = [
    { key: 'Publication reference', value: publicationReference },
    { key: 'Confidential Flag', value: confidential },
    { key: 'Outlet', value: outlet },
    { key: 'Publisher', value: publisher },
    { key: 'Category', value: category },
    { key: 'Sub Category', value: subCategory },
    { key: 'Creative Medium', value: creativeMedium },
    { key: 'Size/Duration', value: sizeDuration },
    { key: 'Editors', value: editors },
    //{ key: 'ERA ID', value: eraId },
    { key: 'ERA Journal FoR', value: journalForCodes },
    { key: 'ISSN', value: issn },
    { key: 'Conference Name', value: conferenceName },
    { key: 'ERA Notes', value: (comments || '').substring(0, 256) }
  ].filter((i) => {
    return ![-1, null, '', '-', undefined].includes(i.value);
  });

  const indicators = [
    { key: 'International Co-author', value: internationalCoAuthorship },
    { key: 'CORE', value: coreRanking },
    { key: 'Washington & Lee Journal Ranking', value: washingtonLeeJournalRanking },
    { key: 'Scimago Quartile/Ranking', value: scimagoSjrRankings },
    { key: 'ABDC quartile/Rating', value: abdcRating },
    { key: 'Citation (WoS)', value: wosCitation },
    { key: 'Citation (Scopus)', value: scopusCitation },
    { key: 'Citation (Altmetric)', value: altmetricCitation },
    { key: 'Citation (Google)', value: googleScholarCitation }
  ].filter((i) => {
    return ![-1, null, '', '-', undefined].includes(i.value);
  });
  return (
    <div data-testid="output-details-top-information">
      <Typography variant="h6" data-testid="output-details-output-title" className={classes.title}>
        {title}
      </Typography>
      <Typography
        variant="body2"
        data-testid="output-details-output-citation-details"
        className={classes.citationDetails}>
        {harvardTitle}
      </Typography>
      <div className={classes.linksWrapper}>
        {publicationReference && (
          <FERALink
            data-testid="output-details-view-current-output-link"
            onClick={() => window.open(getLocation(), '_blank')}>
            View Output in Sharepoint
          </FERALink>
        )}
        {doi && (
          <FERALink
            data-testid="output-details-view-online-information-link"
            onClick={() => window.open(`${doi}`, '_blank')}>
            View available online information
          </FERALink>
        )}
      </div>
      <Accordion
        data-testid="output-details-reference-collapsible"
        className={classes.accordion}
        expanded={referenceIsOpen}
        onChange={(_, expanded) => {
          setReferenceIsOpen(expanded);
        }}>
        <AccordionSummary
          id="output-details-reference-summary"
          aria-controls="output-details-reference-details"
          expandIcon={<ExpandMoreIcon />}
          data-testid="output-details-reference-summary">
          Reference information
        </AccordionSummary>
        <AccordionDetails
          className={classes.accordionDetails}
          data-testid="output-details-reference-details">
          {referenceInfo.map((r, index) => {
            return (
              <Typography
                key={index}
                variant="body2"
                data-testid={`output-details-reference-details-${r.value}`}>
                <strong>{r.key}:</strong> {r.value}
              </Typography>
            );
          })}
          <hr />
          {indicators.map((r, index) => {
            return (
              <Typography
                key={referenceInfo.length + index}
                variant="body2"
                data-testid={`output-details-reference-details-${r.value}`}>
                <strong>{r.key}:</strong> {r.value}
              </Typography>
            );
          })}
        </AccordionDetails>
      </Accordion>
      <Accordion data-testid="output-details-abstract-collapsible" className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="output-details-abstract-summary"
          aria-controls="output-details-abstract-details"
          data-testid="output-details-abstract-summary">
          Abstract
        </AccordionSummary>
        <AccordionDetails data-testid="output-details-abstract-details">
          <Typography variant="body2">{description}</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion data-testid="output-details-keywords-collapsible" className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="output-details-keywords-summary"
          aria-controls="output-details-keywords-details"
          data-testid="output-details-keywords-summary">
          Keywords
        </AccordionSummary>
        <AccordionDetails data-testid="output-details-keywords-details">
          <Typography variant="body2">{keywords}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

TopInformation.propTypes = {
  title: PropTypes.string,
  harvardTitle: PropTypes.string,
  publicationReference: PropTypes.string,
  doi: PropTypes.string,
  description: PropTypes.string,
  publisher: PropTypes.string,
  issn: PropTypes.any,
  conferenceName: PropTypes.string,
  scimagoSjrRankings: PropTypes.any,
  abdcRating: PropTypes.any,
  wosCitation: PropTypes.any,
  scopusCitation: PropTypes.any,
  altmetricCitation: PropTypes.any,
  keywords: PropTypes.string,
  outlet: PropTypes.string,
  confidential: PropTypes.string,
  eraId: PropTypes.string,
  journalForCodes: PropTypes.string,
  creativeMedium: PropTypes.string,
  subCategory: PropTypes.string,
  category: PropTypes.string,
  sizeDuration: PropTypes.string,
  editors: PropTypes.string,
  comments: PropTypes.string,
  washingtonLeeJournalRanking: PropTypes.string,
  internationalCoAuthorship: PropTypes.string,
  coreRanking: PropTypes.string,
  googleScholarCitation: PropTypes.string
};
