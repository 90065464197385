import { useQuery } from 'react-query';

import { sessionEndpoint } from './endpoints';
import { request } from './request';

export const useGetUserSession = (token) => {
  return useQuery(
    'session',
    async () => {
      return request({ url: sessionEndpoint }).then((response) => {
        return response.data;
      });
    },
    { enabled: !!token }
  );
};

export const useDeleteUserSession = (token) => {
  return useQuery(
    'session',
    async () => {
      return request({ url: sessionEndpoint, method: 'DELETE' }).then((response) => {
        return response.data;
      });
    },
    { enabled: !!token }
  );
};
