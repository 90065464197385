import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { usePostCommit } from '../../api/outputs';
import { GlobalAppContext } from '../../store/AppStore';
import { FERAButton } from '../Button/FERAButton';
import GenericDialog from '../Dialog';
import useStyles from './styles';

export const CommitModal = ({ isDisabled, assumeRole, outputIds }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { mutate: commitAllocation } = usePostCommit();

  const {
    globalAlert: [, setAlert]
  } = useContext(GlobalAppContext);

  const handleOpen = () => setOpen(true);

  const handleReset = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    commitAllocation(
      {
        assumeRole: assumeRole,
        outputIds: outputIds
      },
      {
        onSuccess: (data) => {
          if (data.data.failedIds.length !== 0) {
            setAlert({
              open: true,
              message: 'There has been an error occurred',
              severity: 'error'
            });
          } else {
            setAlert({
              open: true,
              message: 'Output has been successfully updated',
              severity: 'success'
            });
          }
          handleReset();
          setIsSubmitting(false);
        },
        onError: () => {
          handleReset();
          setIsSubmitting(false);
          setAlert({
            open: true,
            message: 'There has been an error occurred',
            severity: 'error'
          });
        }
      }
    );
  };

  return (
    <div data-testid="commit-component">
      <FERAButton
        variant="contained"
        color="purple"
        onClick={handleOpen}
        disabled={isDisabled}
        data-testid="commit-open-modal-button">
        Commit this Output
      </FERAButton>
      {open && (
        <GenericDialog title="Commit this Output" onClose={handleReset} data-testid="commit-modal">
          <Box className={classes.modalContent} data-testid="approve-allocation-modal">
            <InfoOutlinedIcon
              sx={{ fontSize: 67 }}
              className={clsx(classes.center, classes.mainBlue)}
            />
            <Typography variant="h1" className={classes.approveHeader}>
              Please confirm
            </Typography>
            <Typography className={classes.approveInfor} data-testid="modal-modal-description">
              You are submitting this outputs to ERA.
              <br /> The submission certifies that you approve the current <br /> FoR Allocation for
              this output.
            </Typography>
            <Box className={classes.center}>
              <FERAButton
                onClick={onSubmit}
                data-testid="modal-modal-commit-button"
                color="red"
                variant="contained"
                aria-label="Confirm and commit FoR allocation code change"
                loading={isSubmitting}
                sx={{ marginRight: 2 }}
                loadingPosition="start"
                startIcon={<SaveIcon />}>
                Confirm & Submit
              </FERAButton>
              <FERAButton
                variant="outlined"
                onClick={handleReset}
                data-testid="modal-modal-cancel-button"
                aria-label="Close commit dialog"
                color="red">
                Cancel
              </FERAButton>
            </Box>
          </Box>
        </GenericDialog>
      )}
    </div>
  );
};

CommitModal.propTypes = {
  isDisabled: PropTypes.bool,
  assumeRole: PropTypes.number,
  outputIds: PropTypes.array
};
