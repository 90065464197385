import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import { colours } from '../../theme/colors';

export const StyledTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colours.borderGrey
      },
      '&:hover fieldset': {
        borderColor: colours.primaryBlue
      },
      '&.Mui-focused fieldset': {
        borderColor: colours.primaryBlue,
        borderWidth: 2
      },
      color: colours.primaryBlue,
      height: 54
    }
  }
})(TextField);

const useStyles = makeStyles(() => ({
  searchArea: {
    width: '100%',
    height: 115,
    backgroundColor: colours.white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  searchAreaLabel: {
    display: 'none'
  },
  searchBar: {
    maxWidth: 641,
    backgroundColor: colours.white,
    flexGrow: 6,
    paddingLeft: 14,
    paddingRight: 138
  },
  searchIcon: {
    cursor: 'pointer',
    color: colours.primaryBlue,
    height: 18,
    width: 18
  },
  multiSelect: {
    flexGrow: 2
  },
  accordion: {
    flexGrow: 1.5
  },
  dropdownBtn: {
    flexGrow: 1
  }
}));

export default useStyles;
