import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export const KVTable = ({ title, data, headers, totals_row }) => {
  return (
    <TableContainer>
      <Typography
        sx={{
          marginBottom: 6 / 8,
          fontWeight: 500,
          fontSize: 18,
          fontFamily: 'Museo',
          lineHeight: '120%'
        }}>
        {title}
      </Typography>
      <Table aria-label={title} size="small">
        {headers && (
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  key={index}
                  align={index === 0 ? 'left' : 'center'}
                  component="th"
                  scope="row">
                  <strong>{header}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {Object.keys(data).map((key, rowindex) => (
            <TableRow key={rowindex}>
              <TableCell component="td" scope="row">
                {totals_row == rowindex ? <strong>{key}</strong> : key}
              </TableCell>
              {data[key].map((val, index) => (
                <TableCell key={index} align="center" component="td" scope="row">
                  {totals_row == rowindex ? <strong>{val}</strong> : val}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

KVTable.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  headers: PropTypes.arrayOf(PropTypes.string),
  totals_row: PropTypes.number
};
