import { useQuery } from 'react-query';

import { summaryEndpoint } from './endpoints';
import { request } from './request';

const restructureStatusResponse = (data) => {
  return data.reduce((acc, item) => {
    acc[item.status] = [item.count];
    return acc;
  }, {});
};

const restructureCategoryResponse = (data) => {
  return data.reduce((acc, item) => {
    acc[item.category] = [
      item.totalCount,
      Number(item.apportioned).toFixed(2),
      item.topThirty,
      item.noNominated,
      item.noToBeSelected
    ];

    return acc;
  }, {});
};

const restructureResponse = (data) => {
  const status = restructureStatusResponse(data.status);
  const category = restructureCategoryResponse(data.category);

  return {
    status,
    category,
    outlet: data.outlet
  };
};

export const useGetSummary = () => {
  return useQuery(['summary'], async () => {
    return request({ url: summaryEndpoint }).then((response) => {
      return restructureResponse(response.data);
    });
  });
};

export const useGetWorkspaceSummary = (forCode) => {
  return useQuery(
    ['summary', forCode],
    async () => {
      return request({ url: `${summaryEndpoint}/${forCode}` }).then((response) => {
        return restructureResponse(response.data);
      });
    },
    { enabled: forCode !== null }
  );
};

export const useGetWorkspaceSummaryStatus = (forCode) => {
  return useQuery(
    ['summary-status', forCode],
    async () => {
      return request({ url: `${summaryEndpoint}-status/${forCode}` }).then((response) => {
        return restructureStatusResponse(response.data);
      });
    },
    { enabled: forCode !== null }
  );
};

export const useGetWorkspaceCategory = (forCode) => {
  return useQuery(
    ['summary-category', forCode],
    async () => {
      return request({ url: `${summaryEndpoint}-category/${forCode}` }).then((response) => {
        return restructureCategoryResponse(response.data);
      });
    },
    { enabled: forCode !== null }
  );
};

export const useGetWorkspaceOutletResult = (forCode) => {
  return useQuery(
    ['summary-outlet-result', forCode],
    async () => {
      return request({ url: `${summaryEndpoint}-outlet-result/${forCode}` }).then((response) => {
        console.log(response.data);
        return response.data;
      });
    },
    { enabled: forCode !== null }
  );
};
