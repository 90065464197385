import CloseIcon from '@mui/icons-material/Close';
import { Alert, IconButton, Snackbar } from '@mui/material';
import React, { useContext } from 'react';

import { defaults, GlobalAppContext } from '../../store/AppStore';
import useStyles from './styles';

const FeraAlert = () => {
  const classes = useStyles();
  const { globalAlert } = useContext(GlobalAppContext);
  const [alert, setAlert] = globalAlert;
  const { message, open, severity } = alert;

  var severityClass;
  switch (severity) {
    case 'success':
      severityClass = classes.alertSuccess;
      break;
    case 'info':
      severityClass = classes.alertInfo;
      break;
    case 'error':
      severityClass = classes.alertError;
      break;
    default:
      severityClass = null;
  }

  return (
    <Snackbar
      data-testid="snack-bar"
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={10000}
      onClose={() => {
        setAlert(defaults.alert);
      }}>
      <Alert
        data-testid="alert-message"
        severity={severity}
        variant="filled"
        className={severityClass}
        action={
          <IconButton
            data-testid="alert-icon-button"
            size="small"
            aria-label="csnack-bar"
            onClick={() => {
              setAlert(defaults.alert);
            }}>
            <CloseIcon
              className={classes.closeButton}
              data-testid="alert-close-icon"
              fontSize="small"
            />
          </IconButton>
        }>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default FeraAlert;
